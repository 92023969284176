// src/components/AuthModal/index.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../hooks/useStores';
import Button from '../../components/Button';
import { CloseIcon } from '../../styles/icons'; 
import { Backdrop, ButtonClose, LoginFormContainer, InputField } from './styles';
import { motion } from 'framer-motion';

const AuthModal = ({ onClose, isOpen }) => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const handleLogin = async (event) => {
    console.log("Attempting to log in..."); // Add this line
    event.preventDefault();
    if (username !== "" && password !== "") {
      try {
        await userStore.handleConnect(username, password);
        console.log("Login was successful. Navigating to private landing page.");
        console.log("Data for user is: ", userStore.user);
        onClose();
        navigate('/'); // Navigate to the home page or another page
      } catch (err) {
        console.error("Login failed:", err.message);
        // Optionally handle login errors (e.g., displaying an error message)
      }
    } else {
      console.log("Username or password field is empty.");
    }
  };
  
  // Animation variants
  const backdropVariants = {
    hidden: { 
      backdropFilter: 'blur(0px)' 
    },
    visible: { 
      backdropFilter: 'blur(5px)', 
      transition: { duration: 1 }
    },
    exit: { 
      backdropFilter: 'blur(0px)', 
      transition: { duration: 1 } }
  };
  
  const formVariants = {
    hidden: {
      scale: 1.1, // Start slightly larger
      filter: 'blur(4px)', // Start with a blur
      opacity: 0, // Start fully transparent
    },
    visible: {
      scale: 1, // Animate to normal size
      filter: 'blur(0px)', // Clear the blur
      opacity: 1, // Animate to fully opaque
      transition: {
        duration: 1,
        ease: "easeInOut"
      }
    },
    exit: { 
      scale: 1.1, 
      filter: 'blur(4px)', 
      opacity: 0, 
      transition: { 
        duration: 1 
      } 
    }
  };
  
  return ReactDOM.createPortal(
    isOpen && (
      <Backdrop 
        initial="hidden" 
        animate="visible" 
        exit="exit" 
        variants={backdropVariants}
        onClick={() => onClose(false)} // Modified to use a callback that sets isOpen to false
        >
        <motion.div
          initial="hidden" 
          animate="visible" 
          exit="exit"
          variants={formVariants}
          onClick={(e) => e.stopPropagation()} // Prevent click from propagating to the backdrop>
        >
          <LoginFormContainer >
            <ButtonClose onClick={() => onClose(false)}> {/* Ensure onClose modifies isOpen */}
              <CloseIcon />
            </ButtonClose>
            <form id="loginForm" onSubmit={handleLogin}>
              <InputField
                type="text"
                placeholder="Alias"
                value={username}
                onChange={e => setUsername(e.target.value)}
                autoComplete="username"
              />
              <InputField
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                autoComplete="current-password"
              />
              <Button 
                form="loginForm" 
                type="submit"
              >
                LOGIN
              </Button>
            </form>
          </LoginFormContainer>
        </motion.div>
      </Backdrop>
    ),
    document.body // Renders the modal as a child of 'body', not as a child of your header
  );
};

export default AuthModal;