import styled from 'styled-components';

export const OperationContainer = styled.div`
  padding: 2%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledHeading = styled.h2`
  margin-bottom: 20px; // Set bottom margin to 10px
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px; // Adjust the gap between items as needed
  width: 100%;
  margin-bottom: 20px; // Set bottom margin to 10px
`;