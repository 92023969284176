// src/components/SeatAndPlayer/styles.js

import styled, { css, keyframes } from 'styled-components';

// Keyframes for animations
const heartbeatAnimation = keyframes`
  // 0% { transform: translate(-50%, -50%) scale(var(--scale-factor)) }
  0% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1)) }
  // 10% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.005)) }
  10% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.01)) }
  // 20% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.04)) }
  20% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.08)) }
  // 30% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.05)) }
  30% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.1)) }
  // 40% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.04)) }
  40% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.08)) }
  // 50% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.03)) }
  50% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.06)) }
  // 60% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.02)) }
  60% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.04)) }
  // 70% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.01)) }
  70% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.02)) }
  // 80% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.005)) }
  80% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.01)) }
  // 90% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1)) }
  90% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1.005)) }
  // 100% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1)) }
  100% { transform: translate(-50%, -50%) scale(calc(var(--scale-factor) * 1)) }
`;

// Styled Components

export const PlayerBackgroundCircle = styled.div`
  position: absolute;
  top: ${({ top }) => top || '0px'};
  left: ${({ left }) => left || '0px'};
  z-index: 1;
  width: ${({ isOccupied }) => isOccupied ? '95px' : '0px'};
  height: ${({ isOccupied }) => isOccupied ? '95px' : '0px'};
  background-color: ${({ theme }) => theme.textColor};
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  transform-origin: center;
  --scale-factor: ${({ scaleFactor }) => scaleFactor || 1};
  
  ${({ isActive }) =>
    isActive &&
    css`
      animation: ${heartbeatAnimation} 0.9s linear infinite;
    `}
`;