// src/stores/userStore.ts

//import LogRocket from 'logrocket';
//import createPlugin from 'logrocket-mobx';
import { makeAutoObservable, autorun, action } from 'mobx';
import SocketService from '../services/SocketService';
import toastStore from './toastStore';

// Initialize LogRocket-MobX plugin
//const { logObservable } = createPlugin(LogRocket);

// Define the structure of the user object
interface User {
  username: string;
  nativeTokens: string[]; // Adjust this type based on what nativeTokens contain
  token: string;
  chips: Number;
}

// Define the structure of the clientInfo object
interface ClientInfo {
  ip: string;
  country: string;
}

class UserStore {
  clientInfo: ClientInfo = { ip: '', country: '' };
  userAcknowledged: boolean = false; // To track if user acknowledged the message
  user: User = { username: '', nativeTokens: [], token: '', chips: 0 };
  authToken: string | null = null;
  isLoadingToken: boolean = true; // Observable for the loading state. Used to ensure the token has had time to become verified.
  username: string = '';
  password: string = '';
  debug: boolean = false; // Toggle this based on your environment (development/production)
  
  constructor() {
    makeAutoObservable(this);
    
    // Optionally log the entire store
    //logObservable(this);
    
    // Check if there's a token in sessionStorage upon instantiation
    this.initializeStore();
  }
  
  initializeStore = () => {
    const storedToken = sessionStorage.getItem('authToken');
    if (storedToken) {
      this.setAuthToken(storedToken);
    }
  }
  
  log(...args: any[]) { // Logging method
    if (this.debug) {
      console.log(...args);
    }
  }
  
  error(...args: any[]) { // Error logging method
    if (this.debug) {
      console.error(...args);
    }
  }
  
  ssetClientInfo = action((data: ClientInfo) => {
    this.log(`Received client info - IP: ${data.ip}, Country: ${data.country}`);
    this.clientInfo = { ip: data.ip, country: data.country }; // Store both IP and country
  });
  
  setUserAcknowledgement = action((value: boolean) => {
    this.userAcknowledged = value;
  });
  
  verifyToken() {
    this.log("verifyToken called. Current isLoadingToken:", this.isLoadingToken);
    this.log("Checking for token in sessionStorage...");
    const token = sessionStorage.getItem('authToken');
    this.log("Current token:", token);
    
    // If a token is found
    if (token) {
      this.log("Token found. Sending it over socket for verification...");
      this.setIsLoadingToken(true);  // Set loading state to true
      
      // Send the token over the socket for verification
      SocketService.emit('verifyToken', { token: token }, (error: any, response: any) => {
        if (error) {
          this.error("Error verifying token:", error.message);
          toastStore.displayToast(error.message);
          this.log("Token invalid. Removing from sessionStorage...");
          sessionStorage.removeItem('authToken'); // Remove invalid token
          this.setIsLoadingToken(false); // Set loading state to false
        } else if (response && response.isValid) {
          this.log("Token verified successfully. Response received:", response);
          this.setAuthToken(token); // Token is still valid, so we keep it
          this.log("Setting users");
          this.user = response.user;
          toastStore.displayToast("Token verification successful!");
          this.setIsLoadingToken(false); // Set loading state to false
        } else {
          this.log("Token verification response:", response);
          this.setIsLoadingToken(false); // Ensure to set loading state to false even if unexpected response
        }
      });
    } else {
      this.log("No token found in sessionStorage.");
      this.setIsLoadingToken(false); // Set loading state to false if no token is found
    }
  }
  
  // Function to handle and store the authentication token:
  // The user._id from the server side MongoDB is a unique identifier generated for each document in MongoDB. 
  // This is signed into a JWT token when a user logs in or signs up, which is then sent back from the server and stored here.
  setAuthToken = action((token: string) => {
    this.log("Setting auth token");
    this.authToken = token;
    SocketService.setToken(token);  // Use the setToken function to set the token in your SocketService hook
    sessionStorage.setItem('authToken', token);    // Save token to sessionStorage
  });
  
  setIsLoadingToken = action((value: boolean) => {
    this.log("Setting isLoadingToken to", value);
    this.isLoadingToken = value;
  });
  
  // Computed property to get the user ID directly from the authToken:
  get userId(): string | null {
    if (!this.authToken) {
      this.error("Auth token is null!");
      return null;
    }
    
    try {
      const base64Url = this.authToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      
      const payload = JSON.parse(jsonPayload);
      return payload.id; // Assuming the payload contains an 'id' field
    } catch (error) {
      this.error("Error decoding token:", error);
      return null;
    }
  }
  
  handleConnect = (username: string, password: string): Promise<any> => {
    this.log("Handling user connect for ", username);
    // Store username in observable
    this.log("Setting username");
    this.username = username;
    // Store password in observable
    this.log("Setting password");
    this.password = password;
    return new Promise((resolve, reject) => {
      SocketService.emit('userConnect', { username, password }, (error: any, response: any) => {
        if (error) {
          // Handle different types of errors
          switch (error.code) { // Assuming error is an object with a code property
            case 'INVALID_CREDENTIALS':
              toastStore.displayToast('Invalid username or password.');
              break;
            case 'USER_BANNED':
              toastStore.displayToast('Your account has been banned.');
              break;
            default:
              toastStore.displayToast(error.message || 'An error occurred during connection.'); // Using a generic message if the error doesn't match any specific case
              break;
          }
          this.error(error);
          reject(error); // reject the Promise
        } else if (response && typeof response === 'object') {
          this.log("[Debug] Login response:", response); // Log the entire response 
          this.log("Setting users");
          // Successful login
          this.user = response.user;
          this.setAuthToken(response.token);
          
          // Here's where you can add the suggested code:
          if (response.token) {
            sessionStorage.setItem('authToken', response.token);
            // Identify the user in LogRocket
            //LogRocket.identify(response.user.id, {
            //  name: response.user.name,
            //  email: response.user.email,
              // Add more custom user variables here
            //  subscriptionType: response.user.subscriptionType // Example
            //});
            //this.log("User identified in LogRocket.");
          } else {
            this.error("Error during login: Token is missing in response");
          }
          
          toastStore.displayToast('Connected successfully'); // Show a toast message for successful connection
          resolve(response); // resolve the Promise
        } else {
          // Handle unexpected response
          const genericError = new Error("Unexpected response during login");
          toastStore.displayToast(genericError.message);
          reject(genericError); // reject the Promise
        }
      });
    });
  };
  
  fetchUserData = action(() => {
    if (this.authToken) {
      SocketService.emit('verifyToken', { token: this.authToken }, (error: any, response: any) => {
        if (error) {
          toastStore.displayToast(error.message);
        } else if (response && response.isValid) {
          this.user = response.user;
        }
      });
    }
  });
  
  handleUserUpdate = (user: User) => {
    this.log("Handling user update");
    // Assuming the server sends updated user details as an object with properties: username, token, and chips
    if (user) {
      this.log("Setting users");
      this.user = user;
    }
    if (user.token) {
      this.setAuthToken(user.token);
    }
    toastStore.displayToast('User details updated.');
  };
  
  logout() {
    this.log("Logging out");
    sessionStorage.removeItem('authToken');  // Remove token from sessionStorage
    this.user = {username: '', nativeTokens: [], token: '', chips: 0};  // Reset the user object
    this.authToken = null;  // Clear the auth token
    SocketService.emit('logout');  // Inform the server to cleanup
    SocketService.close();  // Optionally close the socket connection
  }
}

const userStore = new UserStore();
//this.log("userStore in userStore.js", userStore);
export default userStore;