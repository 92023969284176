// src/stores/toastStore.js
import { makeAutoObservable, action } from 'mobx';

class ToastStore {
  toastMessage: string = "";
  showToast: boolean = false;
  isErrorMessage: boolean = false;
  debug: boolean = false;
  
  constructor() {
    makeAutoObservable(this);
  }
  
  // Debug logging method
  log(...args: any[]) { 
    if (this.debug) {
      console.log(...args);
    }
  }

  // Debug error logging method
  error(...args: any[]) { 
    if (this.debug) {
      console.error(...args);
    }
  }
  
  // Actions
  displayToast = action((message: string, isError: boolean = false) => {
    this.log(`Displaying message - "${message}"`);
    this.toastMessage = message;
    this.showToast = true;
    this.isErrorMessage = isError;
  });
  
  hideToast = action(() => {
    this.log("Hiding message");
    this.showToast = false;
  });
  
  // Methods
  handleSocketMessage = (data: string | { message: string }) => {
    if (typeof data === 'string') {
      this.log(data);
      this.displayToast(data);
    } else if (data && typeof data === 'object' && data.message) {
      this.log(data.message);
      this.displayToast(data.message);
    }
  };
  
  handleSocketError = (data: string | { message: string }) => {
    if (typeof data === 'string') {
      this.error(data);
      this.displayToast(data);
    } else if (data && typeof data === 'object' && data.message) {
      this.error(data.message);
      this.displayToast(data.message, true);
    }
  };
}

const toastStore = new ToastStore();

export default toastStore;