// themes/lightTheme.js

export const lightTheme = {
  name: 'light',
  
  // Base colors
  base:'rgb(255,255,255)', // Canvas base
  
  background: 'rgb(225,225,225)', 
  overlayBackground:'rgba(225,225,225, 0.3)',
  
  neutral: 'rgb(128,128,128)', // Darker text for readability
  textColor: 'rgb(128,128,128)', // Darker text for readability
  
  highlightedTextColor: 'rgb(30,30,30)',
  highlight: 'rgb(30,30,30)',
  
  baseInverted: 'rgb(0,0,0)', // Inverted base for canvas 
  
  // Primary action colors
  primary: '#6200EE', // Indigo, for primary buttons and highlights
  primaryVariant: '#3700B3', // Darker indigo, for hovered or active states
  
  // Secondary action colors
  secondary: '#03DAC6', // Teal, for secondary buttons and elements
  secondaryVariant: '#018786', // Darker teal
  
  // Additional colors
  error: '#B00020', // Red, for error states or messages
  warning: '#FFC107', // Amber, for warning messages
  info: '#2196F3', // Blue, for informational messages
  success: '#4CAF50', // Green, for success messages
  
  // Borders and lines
  border: '#DDDDDD', // Light grey, for subtle borders
  
  // Others
  buttonColor: '#FFFFFF', // For text on buttons
  tableHeaderBackground: '#EEEEEE', // For table headers or similar elements
};