// contexts/ThemeContext.jsx
import React, { createContext, useState, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from '../styles/themes'; // Import your themes

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(lightTheme); // Start with light theme as default
  
  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme.name === 'light' ? darkTheme : lightTheme);
  };
  
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <StyledThemeProvider theme={theme}> {/* Use Styled Components ThemeProvider here */}
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};