// BetChip.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Bet } from './styles';

const BetChip = ({ from, to, onAnimationComplete, scaleFactor }) => (
  <motion.div
    initial={from}
    animate={to}
    transition={{ type: "spring", stiffness: 100, damping: 10 }}
    onAnimationComplete={onAnimationComplete}
    style={{
      position: 'absolute',
    }}
  >
    <Bet scaleFactor={scaleFactor}/>
  </motion.div>
);

export default BetChip;