// src/views/PlaygroundSandbox/components/GetBaseTokenProperties.tsx
import React, { useState } from 'react';
import { ReadOnlyContractProps, ISCTokenProperties } from '@isc/types';
import { publicClient } from '@isc/client/clients';
import Button from '@components/ButtonWithMargins';
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `getBaseTokenProperties` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const GetBaseTokenProperties: React.FC<ReadOnlyContractProps> = ({ contract }) => {
  const [output, setOutput] = useState<ISCTokenProperties | null>(null);

  /**
   * Calls the `getBaseTokenProperties` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details to retrieve the properties of the base token.
   */
  const handleGetBaseTokenProperties = async () => {
    try {
      
      const result = await publicClient.readContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'getBaseTokenProperties',
        args: [],
      });
      
      setOutput(result as ISCTokenProperties);
      console.log(`getBaseTokenProperties result:`, result);
    } catch (error) {
      console.error(`Failed to call getBaseTokenProperties`, error);
    }
  };

  return (
    <OperationContainer>
      <StyledHeading>Get Base Token Properties</StyledHeading>
      <Button onClick={handleGetBaseTokenProperties}>Call getBaseTokenProperties</Button>
      {output && (
      <div>
        <h3>Output:</h3>
        <pre>{JSON.stringify({
          ...output,
          totalSupply: output.totalSupply.toString(), // Convert BigInt to string
        }, null, 2)}</pre>
      </div>
      )}
    </OperationContainer>
  );
};

export default GetBaseTokenProperties;
