// src/views/Playground/ERC20NativeTokensABI.ts
export const L1AssetsABI = [
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "bytes",
						"name": "data",
						"type": "bytes"
					}
				],
				"internalType": "struct L1Address",
				"name": "to",
				"type": "tuple"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
] as const;