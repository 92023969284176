// src/components/OutlinedTextField/styles.ts
import styled from 'styled-components';

// Container for the Outlined Text Field
export const OutlinedTextFieldContainer = styled.div<{ theme: any }>`
  position: relative;
  width: 100%;
  padding-top: 4px;
  //padding-right: 24px;
  //padding-bottom: 16px;
  //padding-left: 24px;
  box-sizing: border-box;
`;

// Wrapper to handle the input and label together
export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

// Input element with an outlined style
export const OutlinedInput = styled.input<{ theme: any; hasError: boolean }>`
  width: 100%;
  padding: 16px 16px 16px 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.textColor};
  //background: ${({ theme }) => theme.background};
  background: transparent;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.error : theme.neutral)};
  border-radius: 6px;
  outline: none;
  
  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.baseInverted}; // Change border to inverted color on hover
  }

  &:focus {
    cursor: text;
    border-color: ${({ theme, hasError }) => (hasError ? theme.error : theme.neutral)}; // Change the border color on focus based on error state
    border-width: 2px;  // Increase the border width to 2px on focus
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: 0px;
    left: 12px;
    background-color: ${({ theme }) => theme.background};
    padding: 0 4px;
    font-size: 12px;
    color: ${({ theme, hasError }) => (hasError ? theme.error : theme.neutral)};
  }
`;

// Label that floats above the input field
export const OutlinedLabel = styled.label<{ theme: any }>`
  position: absolute;
  left: 16px;
  top: 50%;
  //top: 28px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  font-size: 16px;
  color: ${({ theme }) => theme.neutral};
  pointer-events: none;
  background-color: ${({ theme }) => theme.background};

  ${OutlinedInput}:focus + &,
  ${OutlinedInput}:not(:placeholder-shown) + & {
    top: 0%;
    font-size: 12px;
    color: ${({ theme }) => theme.neutral};
  }
`;

// Supporting text that appears below the input field
export const SupportingText = styled.span<{ theme: any }>`
  display: block;
  font-size: 12px;
  color: ${({ theme }) => theme.neutral};
  margin-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
`;

// Error text that appears below the input field
export const ErrorText = styled.span<{ theme: any }>`
  display: block;
  font-size: 12px;
  color: ${({ theme }) => theme.error};
  margin-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
`;