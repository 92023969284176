import styled from 'styled-components';

export const CloseIcon = styled.span`
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
  font-size: 2.5rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.textColor}; 
  transition: all 300ms ease-in-out;
  cursor: pointer; // Makes it clear it's clickable
  &::before {
    content: 'close';
  }
  &:hover {
    color: ${({ theme }) => theme.highlightedTextColor || 'red'};
    font-variation-settings:
      'FILL' 0,
      'wght' 500,
      'GRAD' 0,
      'opsz' 24;
  }
`;