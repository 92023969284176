// src/views/PlaygroundSandbox/components/Call.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { WritableContractProps, ISCHname, ISCDict, ISCAssets } from '@isc/types';
import { chips } from '@isc/client/chipsChain';
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `call` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const CallContract: React.FC<WritableContractProps> = ({ contract, account, walletClient }) => {
  const [output, setOutput] = useState<any>(null);
  const [contractHname, setContractHname] = useState<string>('');
  const [entryPoint, setEntryPoint] = useState<string>('');

  /**
   * Calls the `call` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details. It allows calling a specific entry point of a contract
   * with specified parameters and allowances.
   */
  const handleCallContract = async () => {
    try {
      const emptyParams: ISCDict = { items: [] };
      const emptyAssets: ISCAssets = {
        baseTokens: BigInt(0),
        nativeTokens: [],
        nfts: [],
      };
      
      // Check if account and walletClient are defined before using them
      if (!account || !walletClient) {
        throw new Error("Account or wallet client is missing.");
      }
      
      const result = await walletClient.writeContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'call',
        args: [contractHname, entryPoint, emptyParams, emptyAssets] as const,
        account,
        chain: chips, // Add the chain parameter
      });

      setOutput(result);
      console.log(`call result:`, result);
    } catch (error) {
      console.error(`Failed to call contract`, error);
    }
  };

  return (
    <OperationContainer>
      <StyledHeading>Call Contract</StyledHeading>
      <FlexContainer>
        <OutlinedTextField
          label="Contract Hname (ISCHname)"
          value={contractHname}
          onChange={(e) => setContractHname(e.target.value)}
          supportingText="* Required"
        />
        <OutlinedTextField
          label="Entry Point (ISCHname)"
          value={entryPoint}
          onChange={(e) => setEntryPoint(e.target.value)}
          supportingText="* Required"
        />
      </FlexContainer>
      <Button onClick={handleCallContract}>Call Contract</Button>
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
    </OperationContainer>
  );
};

export default CallContract;