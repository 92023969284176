// src/hooks/useStores.js
import { useContext } from "react";
import { UserStoreContext } from "../contexts/UserStoreContext";
import { ToastStoreContext } from "../contexts/ToastStoreContext";
import { GameStoreContext } from "../contexts/GameStoreContext";
import { TableStoreContext } from "../contexts/TableStoreContext"; 
import { ScaleContext } from "../contexts/ScaleContext"; 

// A custom hook to simplify the usage of multiple contexts within components.

export const useUserStore = (): UserStore => {
  return useContext(UserStoreContext);
};

export const useToastStore = () => useContext(ToastStoreContext);
export const useGameStore = () => useContext(GameStoreContext);

export const useTableStore = (): TableStore => {
  return useContext(TableStoreContext);
}; 

export const useScale = () => useContext(ScaleContext);