// src/components/Toast/index.jsx
import React, { useEffect, useState } from 'react';
import { ToastContainer } from './styles';

const Toast = ({ message = "", duration = 3000, onHide, isError = false }) => {
  const [visible, setVisible] = useState(true);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onHide();  // Call the onHide function
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onHide]);  // Add onHide to dependencies
  
  return visible ? (
    <ToastContainer isError={isError}>
      {message}
    </ToastContainer>
  ) : null;
};

export default Toast;