// src/views/Table/styles.js
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GameContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width:100%;
  transition: margin 0.3s ease-in-out;
  max-height: 100%;

  /* Position content in the middle */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
`;

export const TableContainer = styled.div`
  // position isn't absulute so that it respected the positioning set in the parent container
  //position: relative; // Set to relative so the child containers can have absolute positioning 
  position: absolute;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  transition: margin 0.3s ease-in-out;
  
  // Default to centering in the middle for non-landscape or unspecified orientations
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  // Adjust for landscape orientation
  ${({ orientation }) => orientation === 'landscape' && `
    top: 0%;
    left: 40%; // Move the center to 10% left of the parent's center
    transform: translateX(-50%); // Center based on its own width, no vertical adjustment needed if top is not changed
  `}
`;

export const CenteringContainer = styled.div` 
  position: absolute;
  height: 100%; 
  width: 100%;
  
  /* Position content in the middle */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */

  overflow: hidden;
  transition: margin-left 0.3s ease-in-out;
  z-index: 10;
`;

export const GooContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  filter: url('#goo');
  z-index: 5;
`;

export const BottomRight = styled.div`
  /* positioning */
  position: absolute;
  left: ${({ $gameStore, $windowScaleFactor }) => $gameStore.orientation === 'landscape' ? `${600 * $windowScaleFactor}px` : `${20 * $windowScaleFactor}px`};
  right: ${({ $windowScaleFactor }) => `${20 * $windowScaleFactor}px`};
  bottom: ${({ $windowScaleFactor }) => `${10 * $windowScaleFactor}px`};
  z-index: 100;
  
  /* content */
  display: flex; // Enable Flexbox
  flex-direction: row; // Align children horizontally
  align-items: center; // Align items vertically to the center, if you have items of different heights
  justify-content: flex-end; // Align items to the end (right side) of the container
  
  /* animation */
  transition: all 0.3s ease-in-out;
`;

export const PlayerCardsWrapper = styled.div`
  position: absolute;
  z-index: 15;
  transform: translateY(-50%) translateX(-50%);
  ${(props) => props.orientation === 'landscape' 
  ? `
    left: 12%;
    top: 50%;
    ` 
  : `
    left: 20%;
    bottom: 10%;
    `}
`;

export const TableCardsWrapper = styled.div`
    position: absolute;
    z-index: 15;
    transform: translateY(-50%) translateX(-50%);
    ${(props) => props.orientation === 'landscape' 
    ? `
      left: 80%;
      top: 50%;
      ` 
    : `
      left: 50%;
      top: 15%;
      `}
`;