import React, { useState, useEffect } from 'react';
import { createWalletClient, createPublicClient, custom, http } from 'viem';
//import { chips } from '@isc/client/chipsChain'; // Ensure you point to the correct path
import { chips, iota, shimmer } from 'viem/chains';
import Button from '../../components/ButtonWithMargins';
import PlaygroundContainer from '../../components/PlaygroundContainer';
import { SelectableText } from './styles';

//const providers: EIP6963ProviderDetail[] = [];
const providers: any[] = [];

// Define the array of imported chains
const chains = [chips, iota, shimmer];

const handleAnnounceProvider = (event: EIP6963AnnounceProviderEvent) => {
  providers.push(event.detail);
  console.log('Announced provider:', event.detail.info.name);
};

const requestProvider = () => {
  const requestEvent = new Event('eip6963:requestProvider');
  window.dispatchEvent(requestEvent);
};

const NetworkSwitcherApp: React.FC = () => {
  const [account, setAccount] = useState<string | null>(null);
  const [publicClient, setPublicClient] = useState<any>(null);
  const [walletClient, setWalletClient] = useState<any>(null);
  //const [networkId, setNetworkId] = useState<number | null>(null);
  const [networkName, setNetworkName] = useState<string | null>(null);
  const [chainId, setChainId] = useState<number | null>(null);
  //const [contract, setContract] = useState<any>(null);
  
  useEffect(() => {
    window.addEventListener('eip6963:announceProvider', handleAnnounceProvider as EventListener);
    requestProvider();
    
    return () => {
      window.removeEventListener('eip6963:announceProvider', handleAnnounceProvider as EventListener);
    };
  }, []);
  
  useEffect(() => {
    if (providers.length > 0) {
      const provider = providers[0].provider; // Use the first provider for simplicity
      if (provider) {
        setProvider(provider);
      }
    }
  }, [providers]);
  
  //const setProvider = (provider: EthereumProvider) => {
  const setProvider = (provider: any) => {
    if (provider) {
      // Use viem's `createWalletClient` for wallet interaction
      const walletClientInstance = createWalletClient({
        chain: chips, // Custom Chips network chain
        transport: custom(provider),
      });
      
      setWalletClient(walletClientInstance);
      
      // Use viem's `createPublicClient` for public interactions
      const publicClientInstance = createPublicClient({
        chain: chips,
        transport: http(),
      });
      
      setPublicClient(publicClientInstance);
      
      provider.on('chainChanged', () => {
        window.location.reload();
      });
      
      provider.on('accountsChanged', (accounts: string[]) => {
        setAccount(accounts[0]);
        getNetworkDetails(walletClientInstance);
      });
      
      provider.on('connect', (info: { chainId: string }) => {
        console.log(`Connected to chain ${info.chainId}`);
      });
      
      provider.on('disconnect', (error: { code: number; message: string }) => {
        console.error(`Disconnected: ${error.message}`);
      });
    }
  };
  
  const connectMetaMask = async () => {
    if (walletClient) {
      try {
        const accounts = await walletClient.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        getNetworkDetails(walletClient);
      } catch (error) {
        console.error('User denied account access', error);
      }
    } else {
      console.error('Ethereum provider not found');
    }
  };
  
  // Function to get the network name directly from the chain definitions
  const getNetworkName = (chainId: number) => {
    const chain = chains.find((chain) => chain.id === chainId);
    return chain ? chain.name : "Unknown Network";
  };
  
  const getNetworkDetails = async (client: any) => {
    if (client) {
      const network = await client.getChainId();
      const chainId = Number(network);
      
      setChainId(chainId);
      
      const networkName = getNetworkName(chainId);
      setNetworkName(networkName);
      
      console.log(`Connected to chain ID: ${chainId}`);
      console.log(`Network name set to: ${networkName}`);
    }
  };
  
  const switchNetwork = async (chain: any) => {
    if (walletClient) {
      try {
        console.log(`Trying to switch to chain: ${chain.name}`);
        await walletClient.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chain.id.toString(16)}` }],
        });
        getNetworkDetails(walletClient);
      } catch (switchError) {
        console.error(`Error switching network: ${(switchError as any).message}`);
        
      // If the network has not been added, add it using wallet_addEthereumChain
      if ((switchError as any).code === 4902) {
        console.log(`Chain ID ${chain.id} not recognized. Attempting to add the network...`);
        const addNetworkParams = {
          chainId: `0x${chain.id.toString(16)}`, // Chain ID in hexadecimal
          chainName: chain.name,
          rpcUrls: chain.rpcUrls.default.http, // Use the correct RPC URLs
          nativeCurrency: {
            name: chain.nativeCurrency.name,
            symbol: chain.nativeCurrency.symbol,
            decimals: chain.nativeCurrency.decimals,
          },
          blockExplorerUrls: chain.blockExplorers ? [chain.blockExplorers.default.url] : undefined, // Optional: Block explorer
        };
        console.log('Adding network with params:', addNetworkParams);
        
        try {
          await walletClient.request({
            method: 'wallet_addEthereumChain',
            params: [addNetworkParams],
          });
          console.log('Successfully added the network:', chain.name);
          getNetworkDetails(walletClient);
          } catch (addError) {
            console.error(`Failed to add network: ${(addError as any).message}`);
          }
        } else {
          console.error(`Failed to switch network: ${(switchError as any).message}`);
        }
      }
    }
  };
  
  return (
    <SelectableText>
      <h1>MetaMask Network Switcher</h1>
      <Button onClick={connectMetaMask}>Connect to MetaMask</Button>
      {account && (
        <>
          <p>Connected account: {account}</p>
          <p>Current Network Name: {networkName !== null ? networkName : 'Loading...'}</p>
          <p>Chain ID: {chainId !== null ? chainId : 'Loading...'}</p>
          
          {/* Generate buttons dynamically for each chain */}
          {[chips, iota, shimmer].map((chain) => (
            <Button key={chain.id} onClick={() => switchNetwork(chain)} style={{ display: 'block', marginTop: '10px' }}>
              Switch to {chain.name}
            </Button>
          ))}
        </>
      )}
      <PlaygroundContainer />
    </SelectableText>
  );
};

export default NetworkSwitcherApp;