// src/hooks/useAnimatedNumber.jsx
import { useState, useEffect } from 'react';

export const useAnimatedNumber = (currentValue, duration = 500) => {
  const [displayValue, setDisplayValue] = useState(currentValue);
  
  useEffect(() => {
    let animationFrameId;
    
    const animateValue = (start, end, startTime) => {
      const currentTime = Date.now();
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(1, timeElapsed / duration);
      
      const nextValue = start + (end - start) * progress;
      setDisplayValue(Math.round(nextValue));
      
      if (progress < 1) {
        animationFrameId = requestAnimationFrame(() => animateValue(start, end, startTime));
      }
    };
    
    const startAnimation = () => {
      const startTime = Date.now();
      animateValue(displayValue, currentValue, startTime);
    };
    
    startAnimation();
    
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [currentValue, duration]);
  
  return displayValue;
};