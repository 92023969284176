// src/views/PlaygroundSandbox/components/Allow.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { WritableContractProps, ISCAssets, NativeToken, NativeTokenID, NFTID,  } from '@isc/types';
import { chips } from '@isc/client/chipsChain'; // Assuming you exported `chips` from your chains file
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from '../styles';
import { useTransaction } from 'wagmi'; // WAGMI with Viem

/**
 * Component that handles calling the `allow` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const Allow: React.FC<WritableContractProps> = ({ contract, account, walletClient }) => {
  const [output, setOutput] = useState<any>(null);
  const [target, setTarget] = useState<string>('');
  const [baseTokens, setBaseTokens] = useState<string>('');
  const [nativeTokens, setNativeTokens] = useState<NativeToken[]>([]);
  const [nfts, setNfts] = useState<NFTID[]>([]);

  // Monitor transaction status using WAGMI
  const { data, status, error } = useTransaction({
    hash: output, // Track the transaction hash
    chainId: chips.id, // Specify the chain to monitor
  });

  /**
   * Calls the `allow` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details. It allows the specified target to manage a certain
   * amount of tokens (base tokens, native tokens, and NFTs).
   */
  const handleAllow = async () => {
    try {
      const iscAssets: ISCAssets = {
        baseTokens: BigInt(baseTokens || '0'), // Ensure baseTokens is a valid bigint
        nativeTokens, // Use the state for native tokens
        nfts, // Use the state for NFTs
      };
      
      // Check if account and walletClient are defined before using them
      if (!account || !walletClient) {
        throw new Error("Account or wallet client is missing.");
      }
      
      const result = await walletClient.writeContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'allow',
        args: [target as string, iscAssets] as const,
        account,
        chain: chips, // Add the chain parameter
      });
      
      setOutput(result);
      console.log(`allow result:`, result);
    } catch (error) {
      console.error(`Failed to call allow`, error);
    }
  };
  
  const handleAddNft = () => {
    setNfts([...nfts, new Uint8Array(/* insert your byte data */)]);
  };
  
  const handleAddNativeToken = () => {
    setNativeTokens([
      ...nativeTokens,
      {
        ID: { data: new Uint8Array(/* insert your byte data */) },
        amount: BigInt(0),
      },
    ]);
  };

  return (
    <OperationContainer>
      <StyledHeading>Allow</StyledHeading>
      <FlexContainer>
        <OutlinedTextField
          label="Target (address)"
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          supportingText="* Required (0xD5A5505A18d914166063E29f98C9a4594245a61b)"
        />
        <OutlinedTextField
          label="Base Tokens (uint64)"
          value={baseTokens}
          onChange={(e) => setBaseTokens(e.target.value)}
          supportingText="Optional (1 IOTA is 1,000,000 Base Tokens)"
        />
      </FlexContainer>
      <div>
        <h3>Native Tokens</h3>
        {nativeTokens.map((token, index) => (
          <FlexContainer key={index}>
            <OutlinedTextField
              label="Native Token ID"
              value={Array.from(token.ID.data).map(byte => byte.toString(16).padStart(2, '0')).join('')}
              onChange={(e) =>
                setNativeTokens(
                  nativeTokens.map((t, i) =>
                    i === index ? { ...t, ID: { data: new Uint8Array(Buffer.from(e.target.value, 'hex')) } } : t
                  )
                )
              }
              supportingText="Optional"
            />
            <OutlinedTextField
              label="Amount"
              value={token.amount.toString()}
              onChange={(e) =>
                setNativeTokens(
                  nativeTokens.map((t, i) => (i === index ? { ...t, amount: BigInt(e.target.value) } : t))
                )
              }
              supportingText="Optional"
            />
          </FlexContainer>
        ))}
        <Button onClick={handleAddNativeToken}>Add Native Token</Button>
      </div>
      
      <div>
        <h3>NFTs</h3>
        {nfts.map((nft, index) => (
          <FlexContainer key={index}>
            <OutlinedTextField
              label="NFT ID"
              value={Array.from(nft).map(byte => byte.toString(16).padStart(2, '0')).join('')}
              onChange={(e) =>
                setNfts(
                  nfts.map((n, i) => (i === index ? new Uint8Array(Buffer.from(e.target.value, 'hex')) : n))
                )
              }
              supportingText="Optional"
            />
          </FlexContainer>
        ))}
        <Button onClick={handleAddNft}>Add NFT</Button>
      </div>
      
      <Button onClick={handleAllow}>Call Allow</Button>
      {output && (
        <div>
          {status === 'pending' && <p>Transaction is being processed...</p>}
          {status === 'error' && <p>Transaction failed: {error?.message}</p>}
          {status === 'success' && (
            <div>
              <h3>Transaction successful, check on-chain:</h3>
              <a href={`https://explorer.chips.ooo/tx/${output}`} target="_blank" rel="noopener noreferrer">
                https://explorer.chips.ooo/tx/{output}
              </a>
            </div>
          )}
        </div>
      )}
    </OperationContainer>
  );
};

export default Allow;
