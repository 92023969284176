// src/hooks/useWindowHeight.js
import { useState, useEffect } from 'react';

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
  useEffect(() => {
    function debounce(func, wait) {
      let timeout;
      return function() {
        const context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    }
    
    const handleResize = debounce(() => {
      setWindowHeight(window.innerHeight);
    }, 50); 
    
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);
  
  return windowHeight;
};

export default useWindowHeight;



// // src/hooks/useWindowHeight.js
// import { useState, useEffect } from 'react';

// const useWindowHeight = () => {
//   // Initialize the state with document.documentElement.clientHeight for consistency
//   const [windowHeight, setWindowHeight] = useState(document.documentElement.clientHeight);
  
//   useEffect(() => {
//     const handleResize = () => {
//       // Update state using document.documentElement.clientHeight
//       setWindowHeight(document.documentElement.clientHeight);
//     };
    
//     window.addEventListener('resize', handleResize);
//     // Also consider adding a listener for the 'orientationchange' event if supporting mobile devices that can change orientation
//     window.addEventListener('orientationchange', handleResize);
    
//     return () => {
//       window.removeEventListener('resize', handleResize);
//       window.removeEventListener('orientationchange', handleResize);
//     };
//   }, []);
  
//   return windowHeight;
// };

// export default useWindowHeight;