// src/views/PlaygroundSandbox/components/GetEntropy.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { ReadOnlyContractProps } from '@isc/types';
import { publicClient } from '@isc/client/clients';
import { OperationContainer, StyledHeading } from '../styles';

/**
 * Calls the `getEntropy` function from the ISC contract.
 * 
 * This function interacts with the contract using the provided wallet client,
 * account, and contract details. It retrieves a random 32-bit value derived from
 * the hash of the current ISC state transaction.
 */
const GetEntropy: React.FC<ReadOnlyContractProps> = ({ contract }) => {
  const [output, setOutput] = useState<any>(null);

  const handleGetEntropy = async () => {
    try {
      // Check if publicClient is defined before using it
      if (!publicClient) {
        throw new Error("PublicClient is not provided.");
      }
      
      const result = await publicClient.readContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'getEntropy',
        args: []
      });
      
      setOutput(result);
      console.log(`getEntropy result:`, result);
    } catch (error) {
      console.error(`Failed to call getEntropy`, error);
    }
  };

  return (
    <OperationContainer>
      <StyledHeading>Get Entropy</StyledHeading>
      <Button onClick={handleGetEntropy}>Call getEntropy</Button>
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
    </OperationContainer>
  );
};

export default GetEntropy;
