import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const XAuthFailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const error = searchParams.get('error') || 'Unknown error';

  useEffect(() => {
    console.error('Authentication failed:', error);
  }, [error]);

  return (
    <div>
      <h1>X Authentication Failed</h1>
      <p>Sorry, we couldn't authenticate you with X. Error: {error}</p>
      <p>Please try again or use another method to log in.</p>
      <Link to="/login">Go Back to Login</Link>
    </div>
  );
};

export default XAuthFailPage;