// hooks/useDeviceClass.js
import { useMediaQuery } from 'react-responsive';

export const useDeviceClass = (): 'compact' | 'medium' | 'expanded' | 'large' | 'extraLarge' => {
  const isCompact = useMediaQuery({ maxWidth: 599 });
  const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 839 });
  const isExpanded = useMediaQuery({ minWidth: 840, maxWidth: 1199 });
  const isLarge = useMediaQuery({ minWidth: 1200, maxWidth: 1599 });
  const isExtraLarge = useMediaQuery({ minWidth: 1600 });

  if (isCompact) return 'compact';
  if (isMedium) return 'medium';
  if (isExpanded) return 'expanded';
  if (isLarge) return 'large';
  return 'extraLarge';
};