import React from 'react';
import { SwitchContainer, SwitchLabel, SwitchInput, SwitchText } from './styles';

interface SwitchProps {
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switch: React.FC<SwitchProps> = ({ label, checked, onChange }) => {
  return (
    <SwitchContainer>
      <SwitchLabel>
        <SwitchInput checked={checked} onChange={onChange} />
        <SwitchText>{label}</SwitchText>
      </SwitchLabel>
    </SwitchContainer>
  );
};

export default Switch;