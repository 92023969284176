// src/components/BetBackground/index.jsx
import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { motion } from 'framer-motion';
import BetChip from '../BetChip'; 
import { CurrentBetContainer, CurrentBetFlex, CurrentBetAmount } from './styles';

const BetBackground = observer(({  
    seatIndex, 
    playerPosition,
    user, 
    player, 
    scaleFactor,
    tableSize
  }) => {
  
  // Game area's dimensions
  const centerPosition = {
    x: tableSize / 2,
    y: tableSize / 2,
  };
  
  // Use containerCenter prop directly to calculate betPosition
  const betPosition = {
    left: `${parseInt(playerPosition.x, 10) / 2}px`,
    top: `${parseInt(playerPosition.y, 10) / 2}px`,
  };
  
  if (!player) {
    return (
      <div>
      {/* //<div className={`player-area player-${seatId}`}> */}
        <span>Issue rendering player</span>
      </div>
    )
  }
  
  // Adjust the BetChip's `from` prop calculation
  const betChipStartPosition = {
    x: playerPosition.x, // Direct use without adjustments
    y: playerPosition.y  // Direct use without adjustments
  };
  
  // Convert betPosition for BetChip's `to` prop to a format compatible with framer-motion
  const betChipEndPosition = {
    x: parseInt(playerPosition.x, 10) / 2, // Half way point between Player and center
    y: parseInt(playerPosition.y, 10) / 2, // Half way point between Player and center
  };
  
  // Check if seatIndex is valid, if not, return null or a placeholder
  if (seatIndex < 0 || seatIndex > 5 || seatIndex === undefined) {
    console.error(`Invalid seatIndex: ${seatIndex}`);
    return null; // or a placeholder component
  }
  
  // Determine if a new bet has been placed
  const [betAnimationKey, setBetAnimationKey] = useState(null);
  
  useEffect(() => {
    // First, check if the player object exists and has a roundBets property
    if (player && Array.isArray(player.roundBets)) {
      // Next, use the length of the roundBets array as a unique key to trigger the animation
      const newBetAnimationKey = player.roundBets.length;
      // Only update the key if it's different from the current key
      if (betAnimationKey !== newBetAnimationKey) {
        setBetAnimationKey(newBetAnimationKey);
      }
    }
    // Adding dependency on player.roundBets itself since we're now checking its existence and array nature
  }, [player?.roundBets]); // Depend on player.roundBets to trigger useEffect
  
  return (
    <>
      {user && player.totalRoundBet > 0 && (
        <>
          <motion.div
            initial={{ x: 0, y: 0 }} 
            animate={{ 
              x: 0,
              y: 0,
            }}
            transition={{ type: "spring", stiffness: 100, damping: 10 }}
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%)`,
              zIndex: 1, 
            }}
          >
            <div style={{ zIndex: '30', display: 'contents' }}>
              <CurrentBetContainer left={betPosition.left} top={betPosition.top} scaleFactor={scaleFactor}>
                <CurrentBetFlex>
                  {/* <CoinSymbol>$</CoinSymbol> */}
                  <CurrentBetAmount>{player.totalRoundBet ? player.totalRoundBet : ''}</CurrentBetAmount>
                </CurrentBetFlex>
              </CurrentBetContainer>
            </div>
          </motion.div>
          {betAnimationKey !== null && (
            <BetChip
              key={betAnimationKey} // Key used here to trigger re-animation
              from={{
                x: betChipStartPosition.x, // Assuming this is a central point of PlayerBackgroundCircle
                y: betChipStartPosition.y  // and considering the transform adjustments
              }}
              to={{
                x: betChipEndPosition.x, // Use adjusted end position
                y: betChipEndPosition.y  // Use adjusted end position
              }}
              onAnimationComplete={() => {}}
              scaleFactor={scaleFactor}
            />
          )}
        </>
      )}
    </>
  );
});

export default BetBackground;