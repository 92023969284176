import { makeAutoObservable, action } from 'mobx';
import SocketService from '../services/SocketService';
import userStore from './userStore'; // import directly as outside React's context.
import { ITable } from '../types/table'

export class TableStore {
  tableIndex: ITable[] = []; // Array of all tables
  seatedAtTable: number | null = null; // The table number the user is seated at
  tableData: ITable | null = null;  // Data for a specific table
  dataFetched: boolean = false;   
  scaleFactor: number = 1;
  debug: boolean = true; 
  
  constructor() {
    makeAutoObservable(this);
  }
  
  log(...args: unknown[]) {
    if (this.debug) {
      console.log(...args);
    }
  }
  
  error(...args: unknown[]) { // Error logging method
    if (this.debug) {
      console.error(...args);
    }
  }
  
  // Data recieved from server
  handleTableIndex = action((tables: unknown) => {
    this.log("Received table index", tables);
    if (Array.isArray(tables)) {
      const sortedData = tables.sort((a,b) => a.tableNumber - b.tableNumber); // Sort by table number
      this.tableIndex = sortedData;
      //this.log("tableIndex updated due to response from server after FetchAllTables emit.");
      //this.log("Data fetched from all tables.");
      this.dataFetched = true;
    } else {
      this.error('Expected tables to be an array but received:', tables);
    }
    //this.log('Received tables:', tables);
  });
  
  // Data recieved from server
  handleTableData = action((table: ITable) => {
    this.log("Received table data: ", table);
    this.tableData = table;
    //this.log('Received table data:', table);
  });
  
  // New Table recieved from server (Used in TableList)
  handleNewTable = action((table: ITable) => {
    this.log("New table created with this data: ", table);
    this.tableData = table;
    this.seatedAtTable = table.tableNumber
  });
  
  // Used in TableList
  startNewTable = action(() => {
    //this.log('New table request sent to the server from ', source);
    SocketService.emit('newTable', { userId: userStore.userId });
    //this.flipCount = 0;  REMOVE
    //this.tableCards = []; REMOVE
  });
  
  // Used in gameStore in handleGameEnd
  fetchAllTables = () => {
    this.log("Fetching all tables...");
    //this.log('Request to fetch all tables sent to server');
    SocketService.emit('fetchTableIndex', {});
    //this.log("Fetch All Tables emit sent to server.");
  };
  
  // Used in Table
  joinTable = (tableNumber: number) => {
    this.log(userStore.user.username, " attempting to join table: ", tableNumber);
    //this.selectedTableId = tableId;
    
    // Emitting 'joinTable' event to the server
    SocketService.emit('joinTable', { tableNumber: tableNumber.toString(), userId: userStore.userId });
  };
  
  // Used in Game & Draggable Circles
  getTableByNumber = (tableNumber: number): ITable | undefined => {
    this.log("Table number derived from URL: ", tableNumber); // Log the table number from URL
    this.log(typeof tableNumber); // Log the type of tableNumber
    
    const foundTable = this.tableIndex.find(table => {
      // Uncomment below if you want to see each table's number and type during the search
      // this.log("Checking table: ", table.tableNumber, " with type: ", typeof table.tableNumber);
      return table.tableNumber === Number(tableNumber);
    });
    
    this.log("Found table: ", foundTable); // Log the found table object
    return foundTable;
  };
  
  setScaleFactor = action((factor: number) => {
    this.scaleFactor = factor;
  });
}

const tableStore = new TableStore();
export default tableStore;