// src/utils/layoutHelpers.js

export const calculatePlayerPositions = ({ tableRadius, playerCount, scaleFactor }) => {
  const positions = [];
  const angleStep = (2 * Math.PI) / playerCount; // The step in radians for each player
  const startAngle = Math.PI / 2; // Starting from the bottom (90 degrees or PI/2 radians)
  
  for (let i = 0; i < playerCount; i++) {
    const angle = startAngle + angleStep * i; // Calculate the current angle
    
    // Adjust x and y to center the circle in the GameContainer
    const x = (tableRadius * Math.cos(angle) * scaleFactor);
    const y = (tableRadius * Math.sin(angle) * scaleFactor);
    
    // Store the calculated position and the current angle
    positions.push({
      x: x,
      y: y,
      angle: angle, // Store the angle in radians
    });
  }
  
  return positions;
};