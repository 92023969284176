// ErrorBoundary.jsx
import React, { Component } from 'react';
import LogRocket from 'logrocket';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }
  
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    console.error("Uncaught error:", error, errorInfo);
    
    // Log the error to an external logging service
    LogRocket.error(error, { extra: errorInfo });
  }
  
  render() {
    if (this.state.hasError) {
      // Custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          {/* Only display error details in development mode */}
          {process.env.NODE_ENV === 'development' && (
            <div>
              <p><strong>Error:</strong> {this.state.error.toString()}</p>
              <p><strong>Stack Trace:</strong></p>
              <pre>{this.state.errorInfo.componentStack}</pre>
            </div>
          )}
        </div>
      );
    }
    
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;