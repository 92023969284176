import React, { useState, useEffect } from 'react';
import { Address, encodeAbiParameters, encodeFunctionData, encodePacked, getContract, Hex, bytesToHex, toHex, RpcUserOperation, parseEther } from 'viem';
import { PackedUserOperation } from '@isc/types/userOps.type';
import { publicClient, bundlerClient } from '@isc/client/clients';
import CounterABI from './CounterABI.json';
import EntryPointABI from './EntryPointABI.json';
import CoinbaseSmartWalletABI from './CoinbaseSmartWalletABI.json';
import CoinbaseSmartWalletFactoryABI from './CoinbaseSmartWalletFactoryABI.json';
import Button from '../../components/ButtonWithMargins';
import PlaygroundContainer from '../../components/PlaygroundContainer';
import { PublicKeyCredential, AuthenticatorAssertionResponse } from '@simplewebauthn/types';
import { getUserOperationHash, packedToRpcUserOp } from '@isc/utils/userOpUtils';
import { useUserStore } from '../../hooks/useStores';
import SocketService from '../../services/SocketService';

// Contract addresses
const CounterContractAddress = '0x175f5921f63fc8eac7362a38ea046aecf095f7ff';
const CoinbaseSmartWalletFactory = '0xE3dEd795Ae0AE2cF459edEFf85f570C626AbCE5b';
const EntryPointAddress = '0x68eecFCF1f802BF251D0c7689B4041aD7709cda6';
const PaymasterAddress = '0x46f096138C9ebfd3d3980Aa4ce78DC8BC2eC3cD9';

export async function verifyEntryPointAddress(implementationAddress: string, expectedEntryPoint: string) {
  try {
    const entryPoint = await publicClient.readContract({
      address: implementationAddress as `0x${string}`,
      abi: CoinbaseSmartWalletABI,
      functionName: 'entryPoint',
    });

    console.log('Wallet EntryPoint:', entryPoint);
    console.log('Expected EntryPoint:', expectedEntryPoint);

    if (typeof entryPoint === 'string' && entryPoint.toLowerCase() !== expectedEntryPoint.toLowerCase()) {
      console.error('EntryPoint mismatch!');
      return false;
    }

    return true;
  } catch (error) {
    console.error('Failed to verify EntryPoint:', error);
    return false;
  }
}

const CounterApp: React.FC = () => {
  const [account, setAccount] = useState<`0x${string}` | null>(null);
  const [contract, setContract] = useState<any>(null);
  const [count, setCount] = useState<string | null>(null);
  const [isWebAuthnSupported, setIsWebAuthnSupported] = useState<boolean>(true);
  const [smartWalletAddress, setSmartWalletAddress] = useState<string | null>(null);
  const userStore = useUserStore();
  
  // const initiateXAuth = () => {
  //   const clientId = process.env.REACT_APP_X_CLIENT_ID;
  //   const redirectUri = encodeURIComponent(process.env.REACT_APP_X_REDIRECT_URI);
  //   const scope = encodeURIComponent('tweet.read users.read');
  //   const state = encodeURIComponent(crypto.randomUUID());
  //   const codeChallenge = generateCodeChallenge();
  //   const authUrl = `https://x.com/i/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    
  //   // Store state and code verifier in localStorage
  //   localStorage.setItem('x_auth_state', state);
  //   localStorage.setItem('x_auth_code_verifier', codeChallenge);
    
  //   window.location.href = authUrl;
  // };

  // useEffect(() => {
    
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const code = urlParams.get('code');
  //   const state = urlParams.get('state');
    
  //   if (code && state) {
  //     // Verify state
  //     const storedState = localStorage.getItem('x_auth_state');
  //     if (state !== storedState) {
  //       console.error('Invalid state parameter');
  //       return;
  //     }
      
  //     // Exchange code for access token
  //     exchangeCodeForToken(code).then(createSmartWallet);
  //   }
    
  //   // Check for WebAuthn support
  //   if (!window.PublicKeyCredential) {
  //     console.error('WebAuthn is not supported in this browser.');
  //     setIsWebAuthnSupported(false);
  //     // You might want to show an error message to the user here
  //   }
  //   if (account) {
  //     const contractInstance = {
  //       address: CounterContractAddress,
  //       abi: CounterABI,
  //     };
  //     setContract(contractInstance);
  //     getCount(contractInstance);
  //   }
  // }, [account]);
  
  // const exchangeCodeForToken = async (code: string) => {
  //   const clientId = process.env.REACT_APP_X_CLIENT_ID;
  //   const redirectUri = process.env.REACT_APP_X_REDIRECT_URI;
  //   const codeVerifier = localStorage.getItem('x_auth_code_verifier');
    
  //   const response = await fetch('https://api.x.com/2/oauth2/token', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body: new URLSearchParams({
  //       code,
  //       grant_type: 'authorization_code',
  //       client_id: clientId,
  //       redirect_uri: redirectUri,
  //       code_verifier: codeVerifier,
  //     }),
  //   });
    
  //   const data = await response.json();
  //   return data.access_token;
  // };
  
  // const createSmartWallet = async (accessToken: string) => {
  //   // Fetch user data from X
  //   const response = await fetch('https://api.x.com/2/users/me', {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   });
  //   const userData = await response.json();
    
  //   // Use X ID to create a deterministic salt
  //   const salt = ethers.utils.id(userData.data.id);
    
  //   // Create smart wallet
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   const signer = provider.getSigner();
    
  //   const factory = new ethers.Contract(
  //     CoinbaseSmartWalletFactory.address,
  //     CoinbaseSmartWalletFactory.abi,
  //     signer
  //   );
    
  //   const tx = await factory.createAccount([await signer.getAddress()], salt);
  //   const receipt = await tx.wait();
    
  //   // Extract the new wallet address from the event logs
  //   const event = receipt.events.find(e => e.event === 'AccountCreated');
  //   setSmartWalletAddress(event.args.account);
  // };
  
  // const generateCodeChallenge = () => {
  //   const verifier = crypto.randomUUID() + crypto.randomUUID() + crypto.randomUUID();
  //   const encoder = new TextEncoder();
  //   const data = encoder.encode(verifier);
  //   return window.btoa(String.fromCharCode.apply(null, new Uint8Array(data)))
  //     .replace(/\+/g, '-')
  //     .replace(/\//g, '_')
  //     .replace(/=/g, '');
  // };
  const estimateGas = async (owners: string[], salt: bigint) => {
    try {
      const gasEstimate = await publicClient.estimateContractGas({
        address: CoinbaseSmartWalletFactory as `0x${string}`,
        abi: CoinbaseSmartWalletFactoryABI,
        functionName: 'createAccount',
        args: [owners, salt],
      });
      
      console.log('Estimated gas:', gasEstimate);
      return gasEstimate;
    } catch (error) {
      console.error('Failed to estimate gas:', error);
      // Return a default value or throw an error
      return BigInt(1_000_000); // Default to 1 million gas as a safe upper limit
    }
  };

  const simulateCreateAccount = async () => {
    try {
      const webauthnCredentialPublicKeyBase64 = localStorage.getItem('webauthnCredentialPublicKey');
      if (!webauthnCredentialPublicKeyBase64) {
        console.error('WebAuthn credential not found.');
        return;
      }
      
      const webauthnCredentialPublicKey = new Uint8Array(atob(webauthnCredentialPublicKeyBase64).split('').map(c => c.charCodeAt(0)));
    
      // Remove the 0x04 prefix if it exists
      const publicKeyWithoutPrefix = webauthnCredentialPublicKey.slice(-64);
      
      console.log('WebAuthn public key length:', publicKeyWithoutPrefix.length);
      console.log('WebAuthn public key:', bytesToHex(publicKeyWithoutPrefix));
  
      // Encode the public key as bytes
      const encodedPublicKey = bytesToHex(publicKeyWithoutPrefix);
  
      const owners = [encodedPublicKey];
      const salt = BigInt(userStore.user.xId); // Use Twitter ID as salt

      const estimatedGas = await estimateGas(owners, salt);
      console.log('estimatedGas:', estimatedGas);
  
      const result = await publicClient.simulateContract({
        address: CoinbaseSmartWalletFactory as `0x${string}`,
        abi: CoinbaseSmartWalletFactoryABI,
        functionName: 'createAccount',
        args: [owners, salt],
      });
  
      console.log('Simulated createAccount call:');
      console.log('Gas used:', result.request.gas);
      console.log('To address:', result.request.address);
  
      // You can also log the full result object for more details
      console.log('Full simulation result:', result);
  
    } catch (error) {
      console.error('Failed to simulate createAccount:', error);
    }
  };

  const sendUserOpToBundler = async (userOp: RpcUserOperation, entryPoint: Address) => {
    console.log('Sending RpcUserOperation:', userOp);
    
    return await bundlerClient.request({
      method: 'eth_sendUserOperation',
      params: [userOp, entryPoint],
    });
  };
  
  const generateRandomChallenge = () => {
    const array = new Uint8Array(32);
    crypto.getRandomValues(array);
    return array;
  };
  
  // WebAuthn credential creation and storage
  // It uses the WebAuthn API to create a credential, which 
  // ensures that the private key is generated and stored securely on the device, not in the browse
  const createWebAuthnCredential = async () => {
    try {
      const options: CredentialCreationOptions  = {
        publicKey: {
          challenge: generateRandomChallenge(),
          rp: { name: 'CHIPS', id: 'www.chips.ooo' },
          user: {
            id: new TextEncoder().encode(userStore.user.xId), // Twitter ID as user ID
            name: userStore.user.username, // Twitter username
            displayName: userStore.user.displayName || userStore.user.username,
          },
          pubKeyCredParams: [
            { type: "public-key", alg: -7 },  // ES256
            { type: "public-key", alg: -257 }, // RS256
          ],
          authenticatorSelection: {
            userVerification: 'required',
            authenticatorAttachment: 'platform',
          },
          timeout: 60000,
        },
      };
      // Trigger the device's secure element or Trusted Platform Module (TPM) to generate and store the private key. 
      // The browser only receives and stores the public key and credential ID.
      const credential = await navigator.credentials.create(options);
      const { id, rawId, response } = credential as PublicKeyCredential;
      
      if (response instanceof AuthenticatorAttestationResponse) {
        const publicKey = response.getPublicKey();
        if (publicKey) {
          const publicKeyBase64 = btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(publicKey))));
          // Store the credential ID as base64 URL-safe string
          const credentialIdBase64 = btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(rawId))))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
          localStorage.setItem('webauthnCredentialId', credentialIdBase64);
          localStorage.setItem('webauthnCredentialPublicKey', publicKeyBase64);
          console.log('WebAuthn credential created and stored on the device.');
        } else {
          console.error('Failed to get public key from WebAuthn response');
        }
      } else {
        console.error('Unexpected response type from WebAuthn');
      }
    } catch (error) {
      console.error('Error creating WebAuthn credential:', error);
    }
  };
  
  // Add this helper function
  function hexToUint8Array(hex: string): Uint8Array {
    hex = hex.startsWith('0x') ? hex.slice(2) : hex;
    return new Uint8Array(hex.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
  }
  const safeAtob = (str: string): string => {
    try {
      return atob(str);
    } catch (e) {
      // If regular base64 decoding fails, try URL-safe base64 decoding
      return atob(str.replace(/-/g, '+').replace(/_/g, '/'));
    }
  };
  // Use WebAuthn credential to sign UserOp
  // This` triggers the device to use the securely stored private key without exposing it to the browser
  const signUserOpWithWebAuthn = async (userOpHash: Hex) => {
    try {
      const webauthnCredentialId = localStorage.getItem('webauthnCredentialId');
      if (!webauthnCredentialId) {
        console.error('WebAuthn credential not found.');
        return;
      }
      
      const challenge = hexToUint8Array(userOpHash);
      
      // Safely decode the credential ID
      const decodedCredentialId = safeAtob(webauthnCredentialId);
      const credentialIdUint8Array = new Uint8Array(decodedCredentialId.split('').map(c => c.charCodeAt(0)));
      
      const assertion = await navigator.credentials.get({
        publicKey: {
          challenge,
          allowCredentials: [
            {
              id: credentialIdUint8Array,
              type: 'public-key',
            },
          ],
          userVerification: 'required',
        },
      }) as PublicKeyCredential;
      
      if (!assertion || !assertion.response) {
        console.error('WebAuthn assertion failed.');
        return null;
      }
      
      const response = assertion.response as AuthenticatorAssertionResponse;
      const authData = new Uint8Array(response.authenticatorData);
      const signature = new Uint8Array(response.signature);
      
      if (!authData || !signature) {
        console.error('WebAuthn assertion response is incomplete.');
        return null;
      }
      
      return { authData, signature };
      
    } catch (error) {
      console.error('WebAuthn signing failed:', error);
      return null;
    }
  };
  
  const setupWebAuthnSession = async () => {
    try {
      await createWebAuthnCredential();
      console.log('WebAuthn session set up.');
    } catch (error) {
      console.error('Failed to set up WebAuthn session:', error);
    }
  };
  
  // Create a new wallet with the WebAuthn public key 
  // const initializeWallet = async () => {
  //   const webauthnCredentialPublicKey = localStorage.getItem('webauthnCredentialPublicKey');
  //   if (!webauthnCredentialPublicKey) {
  //     console.error('WebAuthn credential not found.');
  //     return;
  //   }
    
  //   try {
  //     const factoryAddress = CoinbaseSmartWalletFactory as Address;
  //     const owners = [webauthnCredentialPublicKey];
  //     const salt = BigInt(0); // or generate a random salt
      
  //     // Encode the createAccount function call
  //     const initCode = encodeFunctionData({
  //       abi: CoinbaseSmartWalletFactoryABI,
  //       functionName: 'createAccount',
  //       args: [owners, salt],
  //     });
      
  //     const packedUserOp: PackedUserOperation = {
  //       sender: '0x0000000000000000000000000000000000000000' as Address, // This will be replaced by the created account
  //       nonce: BigInt(0),
  //       initCode: encodePacked(['address', 'bytes'], [factoryAddress, initCode]) as Hex,
  //       callData: '0x' as Hex,
  //       accountGasLimits: encodePacked(['uint128', 'uint128'], [BigInt(50_000), BigInt(500_000)]) as Hex,
  //       preVerificationGas: BigInt(750_000),
  //       gasFees: encodePacked(['uint128', 'uint128'], [BigInt(10_000_000_000), BigInt(10_000_000_000)]) as Hex,
  //       paymasterAndData: '0x' as Hex,
  //       signature: '0x' as Hex,
  //     };
      
  //     // Get the UserOpHash from the bundler
  //     const chainId = await publicClient.getChainId();
  //     const userOpHash = getUserOperationHash(packedUserOp, EntryPointAddress, BigInt(chainId));
      
  //     if (typeof userOpHash !== 'string') {
  //       console.error('Invalid userOpHash received from bundler');
  //       return;
  //     }
      
  //     // Sign the UserOpHash with WebAuthn
  //     const signResult = await signUserOpWithWebAuthn(userOpHash);
  //     if (!signResult) {
  //       console.error('Failed to sign UserOp with WebAuthn');
  //       return;
  //     }
  //     const { authData, signature } = signResult;
      
  //     // Encode the WebAuthn signature
  //     packedUserOp.signature = encodeAbiParameters(
  //       [{ type: 'bytes', name: 'authData' }, { type: 'bytes', name: 'signature' }],
  //       [bytesToHex(authData), bytesToHex(signature)]
  //     ) as Hex;
      
  //     // Convert PackedUserOperation to RpcUserOperation
  //     const rpcUserOp = packedToRpcUserOp(packedUserOp);
      
  //     // Send the UserOp to the bundler
  //     const sentUserOpHash = await bundlerClient.request({
  //       method: 'eth_sendUserOperation',
  //       params: [rpcUserOp, EntryPointAddress],
  //     });
      
  //     console.log('UserOperation hash:', sentUserOpHash);
      
  //     // Wait for the UserOperation to be included in a transaction
  //     const txHash = await waitForUserOperationReceipt(sentUserOpHash);
  //     console.log('Transaction hash:', txHash);
      
  //     // Get the created wallet address from the transaction receipt
  //     const receipt = await publicClient.getTransactionReceipt({ hash: txHash as `0x${string}` });
  //     const createAccountEvent = receipt.logs.find(
  //       log => log.address.toLowerCase() === factoryAddress.toLowerCase()
  //     );
      
  //     if (createAccountEvent && createAccountEvent.topics[1]) {
  //       const newWalletAddress = `0x${createAccountEvent.topics[1].slice(-40)}`;
  //       console.log('New wallet address:', newWalletAddress);
  //       localStorage.setItem('walletAddress', newWalletAddress);
  //     } else {
  //       console.error('Failed to extract new wallet address from transaction logs');
  //     }
  //   } catch (error) {
  //     console.error('Failed to initialize wallet:', error);
  //   }
  // };
  
  const initializeWallet = async () => {
    const user = userStore.user; // Assuming you have access to the userStore
    if (!user.xId) {
      console.error('User not authenticated with Twitter');
      return;
    }
    
    try {

      const implementationAddress = '0xc42538266d99DAac2fdC266F176eC314744A6fe8';
      console.log('Implementation address:', implementationAddress);

      // Verify EntryPoint address
      const isEntryPointCorrect = await verifyEntryPointAddress(implementationAddress, EntryPointAddress);
      if (!isEntryPointCorrect) {
        console.error('EntryPoint address mismatch. Aborting wallet initialization.');
        return;
      }
      // // Create a minimal RpcUserOperation directly
      // const minimalUserOp: RpcUserOperation = {
      //   sender: '0x0000000000000000000000000000000000000000',
      //   nonce: '0x0',
      //   factory: CoinbaseSmartWalletFactory,
      //   factoryData: '0x',
      //   callData: '0x',
      //   callGasLimit: '0x100000', // Increased to 1,048,576 gas // '0x5208', -// 21000 in hex
      //   verificationGasLimit: '0x100000', // Increased to 1,048,576 gas '0x5208', // 21000 in hex
      //   preVerificationGas: '0x20000', // Increased to 131,072 gas '0xa604', // 42500 in hex
      //   maxFeePerGas: toHex(parseEther('0.000000001')), // 1 Gwei
      //   maxPriorityFeePerGas: toHex(parseEther('0.000000001')),// 1 Gwei
      //   paymaster: PaymasterAddress,
      //   paymasterVerificationGasLimit: '0x100000', // Increased to 1,048,576 gas '0x5208', // 21000 in hex
      //   paymasterPostOpGasLimit: '0x100000', // Increased to 1,048,576 gas '0x5208', // 21000 in hex
      //   paymasterData: '0x',
      //   signature: '0x'
      // };

      // console.log('Sending UserOp to bundler:', JSON.stringify(minimalUserOp, null, 2));

      // const testResponse = await bundlerClient.request({
      //   method: 'eth_sendUserOperation',
      //   params: [minimalUserOp, EntryPointAddress],
      // });

      // console.log('Test response for minimal UserOp:', testResponse);

      // Create a minimal PackedUserOperation
      // const minimalPackedUserOp: PackedUserOperation = {
      //   sender: '0x0000000000000000000000000000000000000000' as Address,
      //   nonce: 0n,
      //   initCode: encodePacked(['address', 'bytes'], [CoinbaseSmartWalletFactory, '0x']) as Hex,
      //   callData: '0x' as Hex,
      //   accountGasLimits: '0x0000000000000000000000000000520800000000000000000000000000005208' as Hex, // 21000 for both verification and call
      //   preVerificationGas: 42500n, // Increased from 41696 to 42500
      //   gasFees: '0x000000000000000000000000003b9aca00000000000000000000000003b9aca00' as Hex, // 1 Gwei for both max and priority
      //   paymasterAndData: encodePacked(['address', 'bytes'], [PaymasterAddress, '0x']) as Hex,
      //   signature: '0x' as Hex
      // };
  
      // // Convert PackedUserOperation to RpcUserOperation
      // const minimalUserOp2 = packedToRpcUserOp(minimalPackedUserOp);
  
      // // Ensure the factory field is set correctly
      // minimalUserOp2.factory = CoinbaseSmartWalletFactory;
  
      // console.log('Sending minimal UserOp to bundler for testing:', minimalUserOp2);
  
      // const testResponse2 = await bundlerClient.request({
      //   method: 'eth_sendUserOperation',
      //   params: [minimalUserOp2, EntryPointAddress],
      // });
  
      // console.log('Test response for minimal UserOp:', testResponse2);

      // Proceed with the actual wallet initialization logic
      const webauthnCredentialPublicKeyBase64 = localStorage.getItem('webauthnCredentialPublicKey');
      if (!webauthnCredentialPublicKeyBase64) {
        console.error('WebAuthn credential not found.');
        return;
      }
      
      const webauthnCredentialPublicKey = new Uint8Array(atob(webauthnCredentialPublicKeyBase64).split('').map(c => c.charCodeAt(0)));
      console.log('WebAuthn public key:', bytesToHex(webauthnCredentialPublicKey));
      
      // Remove the 0x04 prefix if it exists
      const publicKeyWithoutPrefix = webauthnCredentialPublicKey.slice(-64);
      
      console.log('WebAuthn public key length:', publicKeyWithoutPrefix.length);
      console.log('WebAuthn public key:', bytesToHex(publicKeyWithoutPrefix));
  
      // Encode the public key as bytes
      const encodedPublicKey = bytesToHex(publicKeyWithoutPrefix);
      const owners = [encodedPublicKey];
      const salt = BigInt(user.xId); // Use Twitter ID as salt
      
      const factoryAddress = CoinbaseSmartWalletFactory as Address;
      
      console.log('Factory address:', factoryAddress);
      console.log('Owners:', owners);
      console.log('Salt:', salt.toString());
      
      // Encode the createAccount function call
      const initCode = encodeFunctionData({
        abi: CoinbaseSmartWalletFactoryABI,
        functionName: 'createAccount',
        args: [owners, salt],
      });
      
      // const addOwnerCall = encodeFunctionData({
      //   abi: CoinbaseSmartWalletABI, // You'd need the ABI for the wallet itself
      //   functionName: 'addOwner',
      //   args: [newOwnerAddress],
      // });
      
      // // This would be part of a user operation sent to the wallet
      // const packedUserOp: PackedUserOperation = {
      //   sender: walletAddress as Address,
      //   // ... other fields ...
      //   callData: addOwnerCall,
      // };
      
      console.log('Encoded init code:', initCode);
      
      const paymasterVerificationGasLimit = BigInt(50_000_000);  // Increased from 21,000 (0x5208)
      const paymasterPostOpGasLimit = BigInt(80_000_000);        // Increased from 21,000 (0x5208)
      
      const packedUserOp: PackedUserOperation = {
        sender: '0x0000000000000000000000000000000000000000' as Address,
        nonce: BigInt(0),
        initCode: encodePacked(['address', 'bytes'], [factoryAddress, initCode]) as Hex,
        callData: '0x' as Hex,
        accountGasLimits: encodePacked(['uint128', 'uint128'], [BigInt(5_000_000), BigInt(10_000_000)]) as Hex,
        preVerificationGas: BigInt(200_000),
        gasFees: encodePacked(['uint128', 'uint128'], [BigInt(50_000_000_000), BigInt(20_000_000_000)]) as Hex,
        paymasterAndData: encodePacked(
          ['address', 'uint256', 'uint256', 'bytes'],
          [PaymasterAddress, paymasterVerificationGasLimit, paymasterPostOpGasLimit, '0x']
        ) as Hex,
        signature: '0x' as Hex,
      };
      
      console.log('PackedUserOp:', packedUserOp);
      
      // Check for undefined fields
      Object.entries(packedUserOp).forEach(([key, value]) => {
        if (value === undefined) {
          console.error(`PackedUserOp field ${key} is undefined`);
        }
      });
      
      const chainId = await publicClient.getChainId();
      console.log('ChainId:', chainId);
      
      // Log the packedUserOp for debugging
      console.log('EntryPointAddress:', EntryPointAddress);
      
      const userOpHash = getUserOperationHash(packedUserOp, EntryPointAddress, BigInt(chainId));
      
      console.log('UserOpHash:', userOpHash);
      
      // Sign the UserOpHash with WebAuthn
      const signResult = await signUserOpWithWebAuthn(userOpHash);
      if (!signResult) {
        console.error('Failed to sign UserOp with WebAuthn');
        return;
      }
      const { authData, signature } = signResult;
      
      // Encode the WebAuthn signature
      packedUserOp.signature = encodeAbiParameters(
        [{ type: 'bytes', name: 'authData' }, { type: 'bytes', name: 'signature' }],
        [bytesToHex(authData), bytesToHex(signature)]
      ) as Hex;
      
      // Add debugging steps here
      console.log('Paymaster address:', PaymasterAddress);
      const paymasterBalance = await publicClient.getBalance({ address: PaymasterAddress });
      console.log('Paymaster balance:', paymasterBalance);
      
      const entryPointCode = await publicClient.getBytecode({ address: EntryPointAddress });
      console.log('EntryPoint deployed:', entryPointCode !== '0x');
      
      const factoryCode = await publicClient.getBytecode({ address: factoryAddress });
      console.log('Factory deployed:', factoryCode !== '0x');
      
      console.log('InitCode:', packedUserOp.initCode);
      
      // Convert PackedUserOperation to RpcUserOperation
      const rpcUserOp = packedToRpcUserOp(packedUserOp);
      
      console.log('RPC UserOp:', JSON.stringify(rpcUserOp, null, 2));
      
      // Simulate the UserOperation before sending
      try {
        const simulationResult = await bundlerClient.request({
          method: 'eth_estimateUserOperationGas',
          params: [rpcUserOp, EntryPointAddress],
        });
        console.log('Simulation result:', simulationResult);
      } catch (error) {
        console.error('Simulation failed:', error);
        console.error(JSON.stringify(error, null, 2));
        // You might want to return here if the simulation fails
        // return;
      }
      
      // Send the UserOp to the bundler
      console.log('Sending UserOp to bundler:', JSON.stringify({
        method: 'eth_sendUserOperation',
        params: [rpcUserOp, EntryPointAddress],
      }, null, 2));
      
      const sentUserOpHash = await sendUserOpToBundler(rpcUserOp, EntryPointAddress);
      console.log('UserOperation hash:', sentUserOpHash);
      
      // Add more detailed error logging
      if (!sentUserOpHash) {
        console.error('Failed to send UserOp to bundler. Response:', sentUserOpHash);
        // You might want to throw an error here or handle it appropriately
        return;
      }
      
      // Wait for the UserOperation to be included in a transaction
      const txHash = await waitForUserOperationReceipt(sentUserOpHash);
      console.log('Transaction hash:', txHash);
      
      if (!txHash) {
        console.error('Failed to get transaction hash for UserOp:', sentUserOpHash);
        // Handle this error appropriately
        return;
      }
      
      // Get the created wallet address from the transaction receipt
      const receipt = await publicClient.getTransactionReceipt({ hash: txHash as `0x${string}` });
      const createAccountEvent = receipt.logs.find(
        log => log.address.toLowerCase() === CoinbaseSmartWalletFactory.toLowerCase()
      );
      
      if (createAccountEvent && createAccountEvent.topics[1]) {
        const newWalletAddress = `0x${createAccountEvent.topics[1].slice(-40)}`;
        console.log('New wallet address:', newWalletAddress);
        localStorage.setItem('walletAddress', newWalletAddress);
        setSmartWalletAddress(newWalletAddress);
        
        // Save this wallet address to your backend
        SocketService.emit('saveWalletAddress', { userId: userStore.user._id, walletAddress: newWalletAddress });
      } else {
        console.error('Failed to extract new wallet address from transaction logs');
      }
    } catch (error) {
      console.error('Failed to initialize wallet:', error);
    }
  };
  
  // Get count from Counter contract
  const getCount = async (contractInstance: any) => {
    try {
      const currentCount = await publicClient.readContract({
        address: contractInstance.address,
        abi: contractInstance.abi,
        functionName: 'getCount',
        args: [],
      }) as bigint;
      
      setCount(currentCount.toString());
    } catch (error) {
      console.error('Failed to get count', error);
    }
  };
  
  // Send UserOp signed by WebAuthn credential
  // const sendUserOp = async (functionName: 'increment' | 'decrement') => {
  //   const walletAddress = localStorage.getItem('walletAddress');
  //   if (!walletAddress) {
  //     console.error('Wallet not initialized');
  //     return;
  //   }
  //   try {
  //     const nonce = await publicClient.readContract({
  //       address: EntryPointAddress,
  //       abi: EntryPointABI,
  //       functionName: 'getNonce',
  //       args: [walletAddress, 0n],
  //     }) as bigint;
      
  //     const functionData = encodeFunctionData({
  //       abi: CounterABI,
  //       functionName,
  //       args: [],
  //     });
      
  //     const callGasLimit = BigInt(500_000);
  //     const verificationGasLimit = BigInt(50_000);
      
  //     const packedUserOp: PackedUserOperation = {
  //       sender: walletAddress as Address,
  //       nonce: BigInt(nonce),
  //       initCode: '0x' as Hex,
  //       callData: functionData as Hex,
  //       accountGasLimits: encodePacked(
  //         ['uint128', 'uint128'],
  //         [verificationGasLimit, callGasLimit]
  //       ) as Hex,
  //       preVerificationGas: BigInt(750_000),
  //       gasFees: encodePacked(
  //         ['uint128', 'uint128'],
  //         [BigInt(10_000_000_000), BigInt(10_000_000_000)]
  //       ) as Hex,
  //       paymasterAndData: '0x' as Hex,
  //       signature: '0x' as Hex,
  //     };
      
  //     const chainId = await publicClient.getChainId();
  //     const userOpHash = getUserOperationHash(packedUserOp, EntryPointAddress, BigInt(chainId));
      
  //     if (typeof userOpHash !== 'string') {
  //       console.error('Invalid userOpHash received from bundler');
  //       return;
  //     }
      
  //     // Sign the UserOpHash with WebAuthn
  //     const signResult = await signUserOpWithWebAuthn(userOpHash);
  //     if (!signResult) {
  //       console.error('Failed to sign UserOp with WebAuthn');
  //       return;
  //     }
  //     const { authData, signature } = signResult;
      
  //     // Encode the WebAuthn signature
  //     packedUserOp.signature = encodeAbiParameters(
  //       [{ type: 'bytes', name: 'authData' }, { type: 'bytes', name: 'signature' }],
  //       [bytesToHex(authData), bytesToHex(signature)]
  //     ) as Hex;
      
  //     // Convert PackedUserOperation to RpcUserOperation
  //     const rpcUserOp = packedToRpcUserOp(packedUserOp);
      
  //     // Send the UserOp to the bundler
  //     const sentUserOpHash = await bundlerClient.request({
  //       method: 'eth_sendUserOperation',
  //       params: [rpcUserOp, EntryPointAddress],
  //     });
        
  //     console.log('UserOperation hash:', sentUserOpHash);
      
  //     // Wait for the UserOperation to be included in a transaction
  //     const txHash = await waitForUserOperationReceipt(sentUserOpHash);
  //     console.log('Transaction hash:', txHash);
  //     // Update the count
  //     await getCount(contract);
  //   } catch (error) {
  //     console.error('Failed to send UserOp', error);
  //   }
  // };
  
  // Helper function to wait for UserOperation receipt
  const waitForUserOperationReceipt = async (userOpHash: Hex): Promise<Hex> => {
    return new Promise((resolve, reject) => {
      const checkInterval = setInterval(async () => {
        try {
          const receipt = await bundlerClient.request({
            method: 'eth_getUserOperationReceipt',
            params: [userOpHash],
          });
          if (receipt) {
            clearInterval(checkInterval);
            resolve(receipt.receipt.transactionHash);
          }
        } catch (error) {
          clearInterval(checkInterval);
          reject(error);
        }
      }, 5000); // Check every 5 seconds
    });
  };
  
  // const increment = () => sendUserOp('increment');
  // const decrement = () => sendUserOp('decrement');
  
  return (
    <div>
      <h1>Create Smart Wallet with X credentials and WebAuthn authorisation</h1>
      {/* {!smartWalletAddress ? (
        <button onClick={initiateXAuth}>Sign in with Xr</button>
      ) : (
        <p>Your smart wallet address: {smartWalletAddress}</p>
      )}
      <h1>Counter DApp with WebAuthn</h1> */}
      {!isWebAuthnSupported && (
        <p style={{ color: 'red' }}>
          WebAuthn is not supported in this browser. Please use a modern browser that supports WebAuthn.
        </p>
      )}
      {isWebAuthnSupported && (
        <>
        <Button onClick={simulateCreateAccount}>Simulate Create Account</Button>
        <Button onClick={initializeWallet}>Initialize Wallet</Button>
        {localStorage.getItem('walletAddress') && (
          <p>Wallet Address: {localStorage.getItem('walletAddress')}</p>
        )}
        <Button onClick={setupWebAuthnSession}>Setup WebAuthn Session</Button>
        {/* <Button onClick={increment}>Increment</Button>
        <Button onClick={decrement}>Decrement</Button> */}
        {count && <p>Current Count: {count}</p>}
        </>
      )}
      <PlaygroundContainer />
    </div>
  );
};

export default CounterApp;