// DiagnosticOverlay/styles.js

import styled from 'styled-components';

export const DiagnosticOverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.8);  // semi-transparent background
    color: white;
    overflow-y: scroll;  // in case the content is too long
    z-index: 2000;  // to ensure it's on top
    padding: 20px;
`;

export const SeatInfoContainer = styled.div`
    border: ${
        props => {
            if (props.isActiveUser) return '5px solid yellow';
            if (props.userId) return '1px solid magenta';
            return '1px solid grey';
        }
    };
    border-radius: 15px;
    margin: 5px;
    padding: 5px;
    min-width: 100px;
    font-size: 10px;
    line-height: 1;
    overflow-x: auto;
`;