// src/components/PlayerActions/index.js
import React from 'react';
import { useGameStore, useToastStore } from '../../hooks/useStores';
import Button from '../Button';

const PlayerActions = ({ isActivePlayer, windowScaleFactor, playerInfo, playerChips }) => {
  const gameStore = useGameStore();
  const toastStore = useToastStore(); // Use the custom hook
  
  const highestBet = gameStore.gameState.highestBet;
  
  // Handler function to validate and make a bet
  const handleBet = () => {
    const betAmount = document.getElementById('bet-amount').value;
    if (!betAmount || isNaN(betAmount) || Number(betAmount) <= 0) {
      toastStore.displayToast("Invalid bet amount"); // Use toastStore to display the message
      return;
    }
    gameStore.bet(betAmount);
  };
  
  // Handler function for going all-in
  const handleAllIn = () => {
    gameStore.bet(playerChips); // Bet all of the player's chips
  };
  
  return (
    isActivePlayer && (
      <div>
        {highestBet > playerInfo.totalRoundBet ? (
          <>
            <Button scale={windowScaleFactor} onClick={gameStore.fold}>Fold</Button>
            {playerChips >= highestBet ? (
              <>
                <Button scale={windowScaleFactor} onClick={gameStore.call}>Call</Button>
                <input type="number" min="0" id="raise-amount" placeholder="Raise Amount" />
                <Button scale={windowScaleFactor} onClick={() => gameStore.raise(document.getElementById('raise-amount').value)}>Raise</Button>
              </>
            ) : (
              <Button scale={windowScaleFactor} onClick={handleAllIn}>All-in</Button>
            )}
          </>
        ) : (
          <>
            {playerChips > highestBet || highestBet === 0 ? (
              <>
                <div>
                  <input type="number" min={highestBet > 0 ? highestBet : gameStore.bigBlind} id="bet-amount" placeholder="Bet Amount" />
                </div>
                <Button scale={windowScaleFactor} onClick={gameStore.fold}>Fold</Button>
                <Button scale={windowScaleFactor} onClick={gameStore.check}>Check</Button>
                <Button scale={windowScaleFactor} onClick={handleBet}>Bet</Button>
              </>
            ) : (
              // If the player doesn't have enough chips to meet the highest bet, offer an all-in button directly
              <>
                <Button scale={windowScaleFactor} onClick={handleAllIn}>All-in</Button>
              </>
            )}
          </>
        )}
      </div>
    )
  );
};

export default PlayerActions;