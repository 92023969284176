import styled from 'styled-components';

export const StyledDrawer = styled.div`
  //flex-basis: ${({ isNavOpen }) => (isNavOpen ? '250px' : '0px')};
  //flex-shrink: 0; // Prevents the chat bar from shrinking below its flex-basis value.
  overflow: hidden; // Keeps the content from spilling out during transition.
  //flex: ${({ deviceClass }) => (deviceClass === 'compact' ? '0 0 250px' : '0 0 250px')};
  //transform: ${({ isNavOpen }) => isNavOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: all 0.3s ease-in-out;
  //transition: left 0.3s ease, visibility 0.3s ease;
  //visibility: ${({ isNavOpen }) => (isNavOpen ? 'visible' : 'hidden')};
  height: 100%; 
  width: 100%;
  //position: ${({ deviceClass }) => (deviceClass === 'compact' ? 'absolute' : 'relative')};
  //left: ${({ isNavOpen }) => (isNavOpen ? '0px' : '-250px')};
  //z-index: 200;

  //left: ${({ isNavOpen, deviceClass }) => (isNavOpen && deviceClass === 'compact' ? '0' : isNavOpen ? '250px' : '-100%')};
  //top: 0;
  //bottom: 0;
  //max-width: 80dvw;
  //width: 250px; // Adjust width as needed
  background-color: ${({ deviceClass, theme }) => (deviceClass === 'compact' ? theme.background : 'transparent')};
  //background-color: #fff; // Or any color based on your theme
  //box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
  //backdrop-filter: blur(10px); // Blur the background behind the button
`;

export const DrawerHeader = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  font-weight: bold;
`;

export const DrawerContent = styled.div`
  padding: 10px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ThemeSwitcherContainer = styled.div`
  // Position & size
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 20px;
  cursor: pointer;
  
  // Internal layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  // Styling
  text-transform: uppercase;
  word-break: break-word;
  align-items: center;
  display: flex;
`;