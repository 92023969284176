// src/components/AppLayout/index.jsx
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useScale, useUserStore, useToastStore } from '../../hooks/useStores';
import { useDeviceClass } from '../../hooks/useDeviceClass';
import useWindowHeight from '../../hooks/useWindowHeight';
import NavigationDrawer from '../NavigationDrawer';
import Chat from '../Chat';
import MainLogoComponent from '../Logo';
import DiagnosticOverlay from '../DiagnosticOverlay';
import Toast from '../Toast';
import { AccountIconContainer, AccountIcon, ChatIconContainer, ChatIcon, AppContainer, LeftDrawer, CentralContentContainer, RightDrawer, Scrim } from './styles';

const AppLayout = observer(({ children }) => {
  const deviceClass = useDeviceClass();
  const toastStore = useToastStore();
  const userStore = useUserStore();
  const windowHeight = useWindowHeight();
  const { scaleFactor, containerRef, calculateScaleFactor } = useScale();

  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('default');
  const [textSize, setTextSize] = useState(20);
  const [showDiagnostic, setShowDiagnostic] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerTransitionFinished, setDrawerTransitionFinished] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatTransitionFinished, setChatTransitionFinished] = useState(false);

  const [drawerTimeoutId, setDrawerTimeoutId] = useState(null);
  const [chatTimeoutId, setChatTimeoutId] = useState(null);
  const transitionDuration = 500;

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    clearTimeout(drawerTimeoutId);
    const timeoutId = setTimeout(() => {
      setDrawerTransitionFinished(!drawerTransitionFinished);
      calculateScaleFactor(); // Update scale factor when drawer state changes
    }, transitionDuration);
    setDrawerTimeoutId(timeoutId);
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    clearTimeout(chatTimeoutId);
    const timeoutId = setTimeout(() => {
      setChatTransitionFinished(!chatTransitionFinished);
      calculateScaleFactor(); // Update scale factor when chat state changes
    }, transitionDuration);
    setChatTimeoutId(timeoutId);
  };

  const toggleDiagnostic = () => {
    setShowDiagnostic(prev => !prev);
  };

  const handleScrimClick = () => {
    setIsDrawerOpen(false);
    setIsChatOpen(false);
    calculateScaleFactor(); // Update scale factor when scrim is clicked
  };

  useEffect(() => {
    return () => {
      clearTimeout(drawerTimeoutId);
      clearTimeout(chatTimeoutId);
    };
  }, [drawerTimeoutId, chatTimeoutId]);

  useEffect(() => {
    let page = 'default';
    if (location.pathname === '/') {
      page = 'main';
    } else if (location.pathname.includes('/login')) {
      page = 'login';
    } else if (location.pathname.includes('/table/')) {
      page = 'table';
    } else if (location.pathname.includes('/playgroundSandbox')) {
      page = 'playgroundSandbox';
    } else if (location.pathname.includes('/playgroundBalance')) {
      page = 'playgroundBalance';
    } else if (location.pathname.includes('/playgroundCounter')) {
      page = 'playgroundCounter';
    } else if (location.pathname.includes('/playgroundSendToL1')) {
      page = 'playgroundSendToL1';
    } else if (location.pathname.includes('/network')) {
      page = 'network';
    } else if (location.pathname.includes('/auth-fail')) {
      page = 'auth-fail';
    } else {
      page = 'default';
    }
    setCurrentPage(page);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      const vw = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const newSize = Math.min(Math.min(20 + (vw / 100), 200), Math.min(20 + (vh / 100), 200));
      setTextSize(newSize);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    calculateScaleFactor();
  }, [isDrawerOpen, isChatOpen]); // Trigger scale calculation when drawer states change

  return (
    <AppContainer>
      <LeftDrawer isNavOpen={isDrawerOpen}>
        <NavigationDrawer onClose={toggleDrawer} toggleDiagnostic={toggleDiagnostic} />
      </LeftDrawer>
      <CentralContentContainer
        ref={containerRef}  // Attach the ref to the CentralContentContainer
        deviceClass={deviceClass}
        isNavOpen={isDrawerOpen}
        isChatOpen={isChatOpen}
      >
        {userStore.user?.username && (
          <>
            <AccountIconContainer onClick={toggleDrawer}>
              <AccountIcon />
            </AccountIconContainer>
            <ChatIconContainer onClick={toggleChat}>
              <ChatIcon />
            </ChatIconContainer>
          </>
        )}
        <MainLogoComponent
          scaleFactor={scaleFactor}  // Use the scaleFactor
          currentPage={currentPage}
          textSize={textSize}
          fillColor="transparent"
          outlineColor="#000"
          outlineWidth="2"
        />
        {children}
        {showDiagnostic && <DiagnosticOverlay toggleDiagnostic={toggleDiagnostic} />}
      </CentralContentContainer>
      <RightDrawer isChatOpen={isChatOpen}>
        <Chat onClose={toggleChat} deviceClass={deviceClass} />
      </RightDrawer>
      <Scrim isVisible={deviceClass === 'compact' && (isDrawerOpen || isChatOpen)} onClick={handleScrimClick} />
      {toastStore.showToast && (
        <Toast message={toastStore.toastMessage} duration={3000} onHide={toastStore.hideToast} />
      )}
    </AppContainer>
  );
});

export default AppLayout;