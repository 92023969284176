// src/contexts/GameStoreContext.jsx
import { createContext } from "react";
import gameStore from "../stores/gameStore";

export const GameStoreContext = createContext(gameStore);

export const GameStoreProvider = ({ children }) => {
  return (
    <GameStoreContext.Provider value={gameStore}>
      {children}
    </GameStoreContext.Provider>
  );
}