import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0px;      
  left: 0px;   
  height: 100%;
  width: 100%;  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  backdrop-filter: blur(5px);
  pointer-events: auto; /* Allow pointer events so the backdrop can detect clicks */
  z-index: 3000; /* Ensure this is below modal content but above everything else */
`;

export const LoginFormContainer = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80dvw; 
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  pointer-events: auto; /* Ensure pointer events are enabled for form interaction */
  z-index: 4000; /* Ensure this is above the backdrop */
`;

export const ModalContainer = styled.div`
  /* position */
  position: absolute;
  transform: translate(-50%, -50%);
  top: 66%;
  left: 50%;
  /* size */
  max-width: 500px;
  width: 80%;
  /* style */
  pointer-events: all;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 8px;
  padding: 20px;
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.textColor}; 
  z-index: 5000; /* Ensure close button is above all modal content */
  &:hover {
    color: ${({ theme }) => theme.highlightedTextColor};
  }
`;

export const InputField = styled.input`
  font-size: 4em;  // Making the text 4x larger (set in Global Styles)
  border-bottom: 2px solid ${({ theme }) => theme.textColor};
  margin-bottom: 20px;  // Space between the input fields and the button
  width: 100%;
  background-color: transparent;
  pointer-events: auto; /* Ensure input fields are interactive */
`;

export const SubmitButtonContainer = styled.div`
  position: absolute;
  top: 66%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 100%; // Ensure the button aligns with the form width
  justify-content: center;
  pointer-events: auto; /* Ensure the submit button is clickable */
`;