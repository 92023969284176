// src/views/PlaygroundSandbox/components/GetRequestID.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { ReadOnlyContractProps } from '@isc/types';
import { publicClient } from '@isc/client/clients';
import { OperationContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `getRequestID` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const GetRequestID: React.FC<ReadOnlyContractProps> = ({ contract }) => {
  const [output, setOutput] = useState<any>(null);

  /**
   * Calls the `getRequestID` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details. It retrieves the ID of the current ISC request.
   */
  const handleGetRequestID = async () => {
    try {
      
      const result = await publicClient.readContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'getRequestID',
        args: []
      });
      
      setOutput(result);
      console.log(`getRequestID result:`, result);
    } catch (error) {
      console.error(`Failed to call getRequestID`, error);
    }
  };
  
  return (
    <OperationContainer>
      <StyledHeading>Get Request ID</StyledHeading>
      <Button onClick={handleGetRequestID}>Call getRequestID</Button>
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
    </OperationContainer>
  );
};

export default GetRequestID;
