// src/styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* Reset margin, padding, and border to ensure consistency across browsers */
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: inherit; // Ensures box-sizing is consistent across elements
  }
  
  /* Set base attributes for the entire document */
  html {
    box-sizing: border-box; // This ensures padding and borders are included in the element's total width and height
    width: 100lvw; // don't make dynamic else the address bar can't be hidden
    height: 100lvh;
    -webkit-font-smoothing: antialiased; // Enhances text rendering in webkit browsers
    -moz-osx-font-smoothing: grayscale; // Enhances text rendering in Firefox for Mac
  }
  
  /* Set default styles for the body */
  body {
    font-family: 'Barlow', 'Roboto', sans-serif;
    font-size: 16px;
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.background};
    user-select: none;
    touch-action: manipulation;
    margin: 0;
    width: 100%; // don't make dynamic else the address bar can't be hidden
    height: 100%; // don't make dynamic else the address bar can't be hidden
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* The #root div is your application's main container (apply specific layout styles here) */
  #root {
    visibility: hidden; // Hide the Content Initially until loaded eg. "Flash of Unstyled Text" (FOUT).
    //display: flex;
    //flex-direction: column;
    //align-items: center; /* Center align items horizontally */
    // justify-content: flex-start; /* Align items to the start vertically */
    //justify-content: center; /* Align items to the start vertically */
    width: 100%; // don't make dynamic else the address bar can't be hidden
    height: 100%; // don't make dynamic else the address bar can't be hidden
    /* other styles as needed */
  }
  
  /* Set default styles for common elements */
  button {
    font-family: inherit;
    font-size: inherit;
    line-height: normal;
    text-transform: none;
    cursor: pointer;
    // Add more styles as needed
  }
  
  /* Custom styles for input fields and autocomplete in WebKit */
  input,
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  input:-webkit-autofill:first-line,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:first-line,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:first-line {
    //font-size: 3.25rem !important;
    //font-size: 64px !important;
    //font-size: 4em;
    font-family: 'Barlow', 'Roboto', sans-serif;
    //font-size: inherit;
    color: ${({ theme }) => theme.textColor};
    -webkit-text-fill-color: ${({ theme }) => theme.textColor};
    background: transparent;
    background-color: ${({ theme }) => theme.background};
    -webkit-box-shadow: 0 0 0 1000px ${({ theme }) => theme.background} inset;
    box-shadow: none; // Removes default browser styling
    outline: none; // Removes the outline on focus by default, can be customized
    transition: all 5000s ease-in-out 0s; // This here is important to use
  }
  
  a {
    color: inherit; // Makes anchor tags inherit the text color from their parent
    text-decoration: none; // Removes underline from links
  }
  
  img {
    max-width: 100%; // Ensures images are not wider than their container
    height: auto; // Keeps the original aspect ratio
  }
`; 

export default GlobalStyle;