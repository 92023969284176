// src/contexts/ToastStoreContext.tsx
import React, { createContext, ReactNode } from 'react';
import toastStore from '../stores/toastStore';

export const ToastStoreContext = createContext(toastStore);

interface ToastStoreProviderProps {
  children: ReactNode;
}

export const ToastStoreProvider = ({ children }: ToastStoreProviderProps) => (
  <ToastStoreContext.Provider value={toastStore}>
    {children}
  </ToastStoreContext.Provider>
);