// src/views/PlaygroundSandbox/components/GetSenderAccount.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { ReadOnlyContractProps } from '@isc/types';
import { ISCAgentID } from '@isc/types';
import { publicClient } from '@isc/client/clients';
import { OperationContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `getSenderAccount` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const GetSenderAccount: React.FC<ReadOnlyContractProps> = ({ contract }) => {
  const [output, setOutput] = useState<ISCAgentID | null>(null);

  /**
   * Calls the `getSenderAccount` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details. It retrieves the AgentID of the account that 
   * sent the current ISC request.
   */
  const handleGetSenderAccount = async () => {
    try {
      
      const result = await publicClient.readContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'getSenderAccount',
        args: [],
      });
      
      setOutput(result as ISCAgentID);
      console.log(`getSenderAccount result:`, result);
    } catch (error) {
      console.error(`Failed to call getSenderAccount`, error);
    }
  };
  
  return (
    <OperationContainer>
      <StyledHeading>Get Sender Account</StyledHeading>
      <Button onClick={handleGetSenderAccount}>Call getSenderAccount</Button>
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
    </OperationContainer>
  );
};

export default GetSenderAccount;