// src/contexts/UserStoreContext.jsx
import { createContext } from "react";
import userStore from "../stores/userStore"; 

export const UserStoreContext = createContext(userStore);  

export const UserStoreProvider = ({ children }) => {
  //console.log("userStore in StoreProvider", userStore);
  return (
    <UserStoreContext.Provider value={userStore}>  
      {children}
    </UserStoreContext.Provider>
  );
}