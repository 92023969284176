// src/components/PlaygroundContainer/styles.ts
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 10px;
  text-align: center;
  
  h3 {
    margin: 0;
    padding: 0 20px;
  }
`;

export const PlaygroundBar = styled.div`
  position: relative;
  width: 100%;
  color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;