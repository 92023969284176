// src/components/OutlinedTextField/index.tsx
import React from 'react';
import { OutlinedTextFieldContainer, InputWrapper, OutlinedInput, OutlinedLabel, SupportingText, ErrorText } from './styles';

interface OutlinedTextFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  supportingText?: string; // Optional supporting text
  error?: string | null; // Optional error message
}

const OutlinedTextField: React.FC<OutlinedTextFieldProps> = ({ label, value, onChange, supportingText, error }) => {
  return (
    <OutlinedTextFieldContainer>
      <InputWrapper>
        <OutlinedInput
          type="text"
          value={value}
          onChange={onChange}
          placeholder=" " // Important for placeholder-shown selector to work
          hasError={!!error} // Pass error state to the styled component
        />
        <OutlinedLabel>{label}</OutlinedLabel>
      </InputWrapper>
      {error ? (
        <ErrorText>{error}</ErrorText> // Render error text if there's an error
      ) : (
        supportingText && <SupportingText>{supportingText}</SupportingText>
      )}
    </OutlinedTextFieldContainer>
  );
};

export default OutlinedTextField;
