// src/views/PlaygroundSandbox/components/TriggerEvent.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { WritableContractProps } from '@isc/types';
import { chips } from '@isc/client/chipsChain';
import { OperationContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `triggerEvent` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const TriggerEvent: React.FC<WritableContractProps> = ({ contract, account, walletClient }) => {
  const [output, setOutput] = useState<any>(null);
  const [eventString, setEventString] = useState<string>('');

  /**
   * Calls the `triggerEvent` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details. It triggers an event in the contract with the specified event string.
   */
  const handleTriggerEvent = async () => {
    try {
      // Check if account and walletClient are defined before using them
      if (!account || !walletClient) {
        throw new Error("Account or wallet client is missing.");
      }  
      
      const result = await walletClient.writeContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'triggerEvent',
        args: [eventString],
        account,
        chain: chips, // Add the chain parameter
      });
      
      setOutput(result);
      console.log(`triggerEvent result:`, result);
    } catch (error) {
      console.error(`Failed to call triggerEvent`, error);
    }
  };
  
  return (
    <OperationContainer>
      <StyledHeading>Trigger Event</StyledHeading>
      <label>
        Event String:
        <input type="text" value={eventString} onChange={(e) => setEventString(e.target.value)} />
      </label>
      <Button onClick={handleTriggerEvent}>Call triggerEvent</Button>
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
    </OperationContainer>
  );
};

export default TriggerEvent;
