import styled from 'styled-components';

export const PlaygroundContent = styled.div`
  user-select: text;
  padding: 20px;
  z-index: 50;
`;

export const SandboxContainer = styled.div`
  position: absolute;
  top: 15%;
  left: 0%;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
`;

export const OperationsList = styled.div`
  user-select: text;
  padding: 2%;
  display: flex;
  flex-direction: column;
  width: '450px';
  margin-right: '20px';
`;

export const OperationContainer = styled.div`
  padding: 2%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledHeading = styled.h2`
  margin-bottom: 20px; // Set bottom margin to 10px
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px; // Adjust the gap between items as needed
  width: 100%;
  margin-bottom: 20px; // Set bottom margin to 10px
`;

export const FlexButtonGroup = styled.div`
  display: flex;
  //flex-wrap: wrap;
  flex-direction: column;
  gap: 0px;
  //gap: 10px;
  margin-top: 0px;
  //margin-top: 20px;
  margin-bottom: 20px;
`;

export const ThemedInput = styled.input<{ theme: any }>`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.textColor};
  ::placeholder {
    color: ${({ theme }) => theme.neutral}; /* Faint placeholder text */
  }
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary}; /* Highlight border on focus */
  }
`;