import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ThemeSwitcherContainer = styled.div`
  // Position & size
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 20px;
  cursor: pointer;
  
  // Internal layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  // Styling
  text-transform: uppercase;
  word-break: break-word;
  align-items: center;
  display: flex;
`;

export const MessageContainer = styled(motion.div)`
  // Position & size
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60px;
  width: 80vw;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  
  // Styling
  display: flex;
  flex-direction: column;
  align-items: center;
  
  // Add a class for text you want aligned differently
  .left-aligned-text {
    text-align: left;
    width: 100%; // Ensure it spans the full width of the container
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 66%; // Example position from the top
  left: 50%; // Example position from the left
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column; // Stack buttons vertically
  gap: 10px; // Space between buttons
`;

export const IpButtonContainer = styled.div`
  position: absolute;
  top: 66%; 
  width: 100%;
  display: flex;
  flex-direction: row; 
  justify-content: center;
  gap: 10px; // Space between buttons
`;