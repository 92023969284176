// src/components/Pot/index.jsx

import React from 'react';
import { observer } from "mobx-react-lite";
import { useGameStore } from '../../hooks/useStores';
import { motion } from 'framer-motion';
import { PotContainer, PotBold, Circle } from './styles';

const Pot = observer(({ scaleFactor }) => {
  const gameStore = useGameStore();
  
  if (!gameStore?.gameState?.totalPot || gameStore.gameState.totalPot <= 0) {
    return null; // Don't render if totalPot is not greater than 0
  }
  
  const circleVariants = {
    hidden: { scale: 0 },
    visible: { 
      scale: 1,  // Use scale: 1 here to avoid doubling
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2
      } 
    },
  };
  
  const potBoldVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.5 // You can adjust the duration as needed
      }
    },
  };
  
  return (
    <PotContainer>
      <motion.div 
        initial="hidden" 
        animate="visible" 
        variants={circleVariants}>
        <Circle scaleFactor={scaleFactor} />
        <motion.span 
          variants={potBoldVariants}
          initial="hidden"
          animate="visible">
          <PotBold>{gameStore?.gameState?.totalPot}</PotBold>
        </motion.span>
      </motion.div>
    </PotContainer>
  )
})

export default Pot;