// src/components/NavigationDrawer/index.js
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTableStore, useGameStore, useUserStore } from '../../hooks/useStores';
import { useDeviceClass } from '../../hooks/useDeviceClass';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import ThemeSwitcher from '../ThemeSwitcher';
import Button from '../Button';
import { CloseIcon } from '../../styles/icons'; 
import { convertHexToDecimal } from '../../utils/utils';
import { StyledDrawer, DrawerHeader, DrawerContent, CloseButton, ThemeSwitcherContainer } from './styles';

// Token mapping
const tokenNames = {
  "0x08e924e2cff14a701e808cb30720b207e3df1600c8585b912e503ef1ae27a8ae230100000000": "Aurum",
  // Add other tokens here
};

//const NavigationDrawer = observer(({ isOpen, onClose, toggleDiagnostic, scaleFactor, designWidth, designHeight }) => {
const NavigationDrawer = observer(({ isNavOpen, onClose, toggleDiagnostic }) => {
  const navigate = useNavigate(); 
  const deviceClass = useDeviceClass();
  const userStore = useUserStore();
  
  useEffect(() => {
    // Fetch user data including native tokens on mount or when user changes
    if (userStore.user.username) {
      userStore.fetchUserData(); // Implement this in your store
    }
  }, [userStore.user.username]);
  
  // Helper function to navigate to the current table
  const goToCurrentTable = () => {
    if (userStore.user.currentTable) {
      navigate(`/table/${userStore.user.currentTable}`);
    }
  };
  
  const logout = () => {
    userStore.logout();
    navigate('/login');
  };
  
  return (
    <StyledDrawer isNavOpen={isNavOpen} deviceClass={deviceClass}>
      <CloseButton onClick={onClose} >
        <CloseIcon />
      </CloseButton>
      <DrawerHeader>Menu</DrawerHeader>
      <DrawerContent>
        <div style={{ whiteSpace: 'nowrap' }}>
          <div>User:</div>
          <div style={{ fontSize: '3rem' }}>
            {userStore.user.username ? userStore.user.username : 'Not Signed In'}
          </div>
        </div>
        {/* <div style={{ whiteSpace: 'nowrap' }}>UserID: {userStore.user._id ? userStore.user._id : 'Not Signed In'}</div> */}
        {/* <div style={{ whiteSpace: 'nowrap' }}> */}
        {/* <div>Chips:</div> */}
          {/* <div style={{ fontSize: '3rem' }}> */}
            {/* {userStore.user.chips ? userStore.user.chips : 'No Chips'} */}
          {/* </div> */}
        {/* </div> */}
        <div style={{ whiteSpace: 'nowrap' }}>
          <div>Native Tokens:</div>
          <div style={{ fontSize: '3rem' }}>
            {userStore.user.nativeTokens && userStore.user.nativeTokens.length > 0 ? (
              userStore.user.nativeTokens
                .filter(token => tokenNames[token.tokenId] && convertHexToDecimal(token.available) > 0)
                .map((token, idx) => (
                  <div key={idx}>
                    <div>{tokenNames[token.tokenId]}:</div>
                    <div>Available: {convertHexToDecimal(token.available)}</div>
                  </div>
                ))
            ) : (
              <div>No Tokens</div>
            )}
          </div>
        </div>
        {/* <div style={{ whiteSpace: 'nowrap' }}>clientInfo: {userStore.clientInfo ? userStore.clientInfo.ip : 'No clientInfo'}</div> */}
        {/* <div style={{ whiteSpace: 'nowrap' }}>Current Table ID: {tableStore.tableData ? tableStore.tableData._id : 'No table selected'}</div> */}
        {/* <div style={{ whiteSpace: 'nowrap' }}>Current Game ID: {gameStore.gameState ? gameStore.gameState._id : 'No game in progress'}</div> */}
        <div>
          {userStore.user.currentTable ? (
            <div>
              <div>Seated at:</div>
              <div style={{ cursor: 'pointer', fontSize: '3rem' }} onClick={goToCurrentTable}>
                Table {userStore.user.currentTable}
              </div>
            </div>
          ) : (
            'Not seated at any table'
          )}
        </div>
        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <Button onClick={logout}>Logout</Button>
        </div>
        {/* <div>
          <Button onClick={toggleDiagnostic}>Toggle Diagnostic Data</Button>
        </div> */}
        <ThemeSwitcherContainer>
          <ThemeSwitcher />
        </ThemeSwitcherContainer>
      </DrawerContent>
    </StyledDrawer>
  );
});

export default NavigationDrawer;