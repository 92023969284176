// src/components/PlayerBackground/index.jsx
import React from 'react';
import { observer } from "mobx-react-lite";
import { motion } from 'framer-motion';
import { PlayerBackgroundCircle } from './styles';

const PlayerBackground = observer(({ 
    seat, 
    seatIndex, 
    playerPosition,
    user, 
    player, 
    activePlayerSeat, 
    scaleFactor,
  }) => {
  
  if (!player) {
    return (
      <div>
        <span>Issue rendering player</span>
      </div>
    )
  }
  
  const isOccupied = !!user; 
  const isActivePlayer = (activePlayerSeat && player.seat === activePlayerSeat) ? true : false;
  
  // Check if seatIndex is valid, if not, return null or a placeholder
  if (seatIndex < 0 || seatIndex > 5 || seatIndex === undefined) {
    console.error(`Invalid seatIndex: ${seatIndex}`);
    return null; // or a placeholder component
  }
  
  //console.log(`Rendering PlayerBackground for seat ${seat.seatId}, isOccupied: ${isOccupied}, isActivePlayer: ${isActivePlayer}`);
  
  return (
    <motion.div
      initial={{ x: playerPosition.x, y: playerPosition.y }} 
      animate={{ 
        x: playerPosition.x,
        y: playerPosition.y,
      }}
      transition={{ type: "spring", stiffness: 100, damping: 10 }}
      style={{
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 1, 
      }}
    >
      {/* Render PlayerCircle for the taken seat */}
      <PlayerBackgroundCircle 
        id={`playerBackgroundCircle-${seat.seatId}`} 
        scaleFactor={scaleFactor} 
        isActive={isActivePlayer} 
        isOccupied={isOccupied}
      />
    </motion.div>
  );
});

export default PlayerBackground;