import styled from 'styled-components';

// Container for the switch
export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled.label<{ theme: any }>`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SwitchInput = styled.input.attrs({ type: 'checkbox' })<{ theme: any; checked: boolean }>`
  appearance: none;
  width: 52px;
  height: 32px;
  background-color: ${({ theme, checked }) =>
    checked ? theme.neutral : theme.background};
  border-radius: 16px;
  position: relative;
  outline: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  border: 2px solid ${({ theme }) => theme.neutral};

  &:before {
    content: '';
    position: absolute;
    width: ${({ checked }) => (checked ? '24px' : '16px')};
    height: ${({ checked }) => (checked ? '24px' : '16px')};
    background-color: ${({ theme, checked }) => 
      checked ? theme.background : theme.neutral}; // Change color based on checked state
    border-radius: 50%;
    top: 50%;
    left: ${({ checked }) => (checked ? 'calc(100% - 26px)' : '4px')};
    transform: translateY(-50%);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::after {
    content: '${({ checked }) => (checked ? 'check' /* or '\e5ca' for the Unicode glyph */ : '')}';
    font-family: 'Material Symbols Outlined';
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 20;
    font-size: 16px;
    color: ${({ theme }) => theme.neutral};
    position: absolute;
    left: ${({ checked }) => (checked ? 'calc(100% - 26px)' : '4px')};
    top: 50%;
    transform: translateY(-50%);
    width: ${({ checked }) => (checked ? '24px' : '16px')};
    height: ${({ checked }) => (checked ? '24px' : '16px')};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }

  &:hover {
    cursor: pointer
  }
`;

export const SwitchText = styled.span<{ theme: any }>`
  margin-left: 10px;
  color: ${({ theme }) => theme.textColor};
  font-size: 16px;
`;