// src/Main.jsx
import './styles/fonts.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { ToastStoreProvider } from './contexts/ToastStoreContext'
import { UserStoreProvider } from './contexts/UserStoreContext'
import { TableStoreProvider } from './contexts/TableStoreContext';
import { GameStoreProvider } from './contexts/GameStoreContext';
import { ScaleProvider } from './contexts/ScaleContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { config } from './lib/isc/client/wagmiConfig';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalMessageHandler from './components/GlobalMessageHandler';
import AuthWrapper from './components/AuthWrapper';
import App from './App';
import GlobalStyle from './styles/GlobalStyle';
import SVGFilter from './styles/SVGFilter';

// Import & initialize LogRocket with your app ID
import LogRocket from 'logrocket';
LogRocket.init('0z7ham/chips');

const root = ReactDOM.createRoot(document.getElementById('root'));

// Function to show the root element
const showContent = () => {
  document.getElementById('root').style.visibility = 'visible';
};

const queryClient = new QueryClient()

// This is the entry point of your React application where you set up the root component and wrap it with various context providers.
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <GlobalStyle />
      <SVGFilter />
      <ToastStoreProvider>
        <UserStoreProvider>
          <TableStoreProvider> 
            <GameStoreProvider>
              <ScaleProvider>
                <BrowserRouter> 
                  <ErrorBoundary>
                    <GlobalMessageHandler>
                      <AuthWrapper>
                        <WagmiProvider config={config}>
                          <QueryClientProvider client={queryClient}>
                            <App />
                          </QueryClientProvider>
                        </WagmiProvider>
                      </AuthWrapper>
                    </GlobalMessageHandler>
                  </ErrorBoundary>
                </BrowserRouter>
              </ScaleProvider>
            </GameStoreProvider>
          </TableStoreProvider>
        </UserStoreProvider>
      </ToastStoreProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// Function to avoid "Flash of Unstyled Text" (FOUT).
const fontLoadPromises = [
  // Google Fonts
  document.fonts.load('1em Roboto'),
  document.fonts.load('1em Lobster'),
  document.fonts.load('1em Barlow'),
  document.fonts.load('1em Moirai One'),
  document.fonts.load('1em Material Symbols Outlined'),
  // Custom Fonts (specify the same way they are defined in your @font-face)
  document.fonts.load('1em Akira Outline'),
  document.fonts.load('1em Akira Super Bold'),
];

Promise.all(fontLoadPromises).then(() => {
  showContent();
}).catch(error => {
  console.error('An error occurred while loading fonts:', error);
  // Consider a fallback here, e.g., showing the content anyway after a timeout
  setTimeout(showContent, 1000); // Fallback after a short delay
});
