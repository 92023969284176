import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useGameStore } from '../../hooks/useStores';
import { StyledChat, ChatHeader, MessageArea, MessageInput, CloseButton, CloseIcon } from './styles'; // Your styled component

const Chat = observer(({ onClose, deviceClass }) => {
  const [messages, setMessages] = useState([]);
  const gameStore = useGameStore(); // Assuming gameStore will handle chat data
  
  useEffect(() => {
    // TODO: Fetch initial chat messages from the store or server
  }, []);
  
  const sendMessage = (message) => {
    // TODO: Implement sending message logic
  };
  
  return (
    <StyledChat deviceClass={deviceClass}>
      <ChatHeader>
        Chat 
        <CloseButton onClick={onClose} >
          <CloseIcon />
        </CloseButton>
      </ChatHeader>
      <MessageArea>
        {/* Messages will be displayed here. */}
      </MessageArea>
      <MessageInput type="text" placeholder="Type a message..." />
    </StyledChat>
  );
});

export default Chat;