import { css } from 'styled-components';
import { getShadowByDp, getShadowByLevel } from '../styles/elevation';

// This hook now accepts either 'level' or 'dp' to determine the shadow style
export const useElevation = ({ level = 0, dp }) => {
  // Use dp value if provided, otherwise use level
  const shadowStyle = dp !== undefined ? getShadowByDp(dp) : getShadowByLevel(level);
  
  //console.log(`useElevation: level=${level}, dp=${dp}, shadowStyle='${shadowStyle}'`);
  
  // return css`
  //   box-shadow: ${shadowStyle};
  // `;
  
  // Directly return the shadow style string
  //return `box-shadow: ${shadowStyle};`;
  
  // Directly return the shadow style string
  return shadowStyle;
};