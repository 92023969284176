// src/contexts/TableStoreContext.jsx
import React, { createContext, ReactNode } from "react";
import tableStore from "../stores/tableStore"; // Ensure you have tableStore defined in your stores
import { TableStore } from "../stores/tableStore"; // Import the TableStore type

export const TableStoreContext = createContext<TableStore>(tableStore);

interface TableStoreProviderProps {
  children: ReactNode;
}

export const TableStoreProvider = ({ children }: TableStoreProviderProps) => {
  return (
    <TableStoreContext.Provider value={tableStore}>
      {children}
    </TableStoreContext.Provider>
  );
};