// src/views/PlaygroundBalance/index.tsx
import React, { useState, useEffect } from 'react';
import Button from '../../components/ButtonWithMargins';
import { publicClient, walletClient } from '@isc/client/clients';
import { GetBalanceABI } from './GetBalanceABI'; 
import PlaygroundContainer from '../../components/PlaygroundContainer';
import { SelectableText } from './styles';

// Define the contract object with its address and ABI
const contract = {
  address: '0x7b13b97c8d6ccf6df66042a1dc073b3d2f9a48a3' as `0x${string}`,
  abi: GetBalanceABI,
};

const BalanceChecker: React.FC = () => {
  // State variables to hold the connected account, agent ID, and various balances
  const [account, setAccount] = useState<string | null>(null);
  const [agentID, setAgentID] = useState<string | null>(null);
  const [baseBalance, setBaseBalance] = useState<string | null>(null);
  const [nativeTokenBalance, setNativeTokenBalance] = useState<string | null>(null);
  const [nftBalance, setNftBalance] = useState<string | null>(null);
  
  // useEffect to trigger balance fetching and event subscription when an account is connected
  useEffect(() => {
    if (account) {
      subscribeToEvents();
    }
  }, [account]);
  
  // Function to connect to MetaMask and retrieve the user's account address
  const connectMetaMask = async () => {
    try {
      const addresses = await walletClient.getAddresses();
      setAccount(addresses[0] as `0x${string}`); // Cast to the correct type
    } catch (error) {
      console.error('Failed to connect wallet', error);
    }
  };
  
  // Function to fetch the Agent ID
  const fetchAgentID = async () => {
    try {
      if (account) {
        await walletClient.writeContract({
          address: contract.address,
          abi: contract.abi,
          functionName: 'getAgentID',
          args: [],
          account: account as `0x${string}`, // Cast to the correct type
        });
        console.log('Agent ID fetch triggered');
      }
    } catch (error) {
      console.error('Failed to fetch Agent ID', error);
    }
  };
  
  // Function to send the transaction to get base token balance
  const fetchBaseBalance = async () => {
    try {
      if (account) {
        await walletClient.writeContract({
          address: contract.address,
          abi: contract.abi,
          functionName: 'getBalanceBaseTokens',
          args: [],
          account: account as `0x${string}`, // Cast to the correct type
        });
        console.log('Base balance fetch triggered');
      }
    } catch (error) {
      console.error('Failed to fetch base balance', error);
    }
  };
  
  // Function to send the transaction to get native token balance
  const fetchNativeTokenBalance = async (nativeTokenID: string) => {
    try {
      if (account) {
        await walletClient.writeContract({
          address: contract.address,
          abi: contract.abi,
          functionName: 'getBalanceNativeTokens',
          args: [nativeTokenID as `0x${string}`], // Cast nativeTokenID to the correct type
          account: account as `0x${string}`, // Cast to the correct type
        });
        console.log('Native token balance fetch triggered');
      }
    } catch (error) {
      console.error('Failed to fetch native token balance', error);
    }
  };
  
  // Function to send the transaction to get NFT balance
  const fetchNFTBalance = async () => {
    try {
      if (account) {
        await walletClient.writeContract({
          address: contract.address,
          abi: contract.abi,
          functionName: 'getBalanceNFTs',
          args: [],
          account: account as `0x${string}`, // Cast to the correct type
        });
        console.log('NFT balance fetch triggered');
      }
    } catch (error) {
      console.error('Failed to fetch NFT balance', error);
    }
  };
  
  // Function to subscribe to contract events and update state when events are detected
  const subscribeToEvents = () => {
    publicClient.watchContractEvent({
      address: contract.address,
      abi: contract.abi,
      eventName: 'GotAgentID', // Event name to watch for Agent ID
      onLogs: (logs) => {
        logs.forEach((log) => {
          const agentIDString = log.args.agentID !== undefined ? log.args.agentID.toString() : '';
          setAgentID(agentIDString); // Update the Agent ID state with the event data
        });
      },
      onError: (error) => {
        console.error('Error watching contract event:', error);
      },
    }); 
    
    publicClient.watchContractEvent({
      address: contract.address,
      abi: contract.abi,
      eventName: 'GotBaseBalance', // Event name to watch for
      onLogs: (logs) => {
        logs.forEach((log) => {
          const balanceString = log.args.baseBalance !== undefined ? log.args.baseBalance.toString() : '0';
          setBaseBalance(balanceString); // Update the base balance state with the event data
        });
      },
      onError: (error) => {
        console.error('Error watching contract event:', error);
      },
    });
    
    publicClient.watchContractEvent({
      address: contract.address,
      abi: contract.abi,
      eventName: 'GotNativeTokenBalance', // Event name to watch for
      onLogs: (logs) => {
        logs.forEach((log) => {
          const nativeTokenBalanceString = log.args.nativeTokenBalance !== undefined ? log.args.nativeTokenBalance.toString() : '0';
          setNativeTokenBalance(nativeTokenBalanceString); // Update the native token balance state with the event data
        });
      },
      onError: (error) => {
        console.error('Error watching contract event:', error);
      },
    });
    
    publicClient.watchContractEvent({
      address: contract.address,
      abi: contract.abi,
      eventName: 'GotNFTIDs', // Event name to watch for NFTs
      onLogs: (logs) => {
        logs.forEach((log) => {
          const nftBalanceString = log.args.nftBalance !== undefined ? log.args.nftBalance.toString() : '0';
          setNftBalance(nftBalanceString); // Update the NFT balance state with the event data
        });
      },
      onError: (error) => {
        console.error('Error watching contract event:', error);
      },
    });
  };
  
  return (
    <SelectableText>
      <h1>Balance Checker DApp</h1>
      <Button onClick={connectMetaMask}>Connect to MetaMask</Button>
      {account && (
        <>
          <p>Connected account: {account}</p>
          <p>Agent ID: {agentID !== null ? agentID : 'Click button below to find your Agent ID'}</p>
          <Button onClick={fetchAgentID} style={{ display: 'block', marginTop: '10px' }}>Get Agent ID</Button>
          <p>Base Token Balance: {baseBalance !== null ? baseBalance : 'Click button below to find your IOTA balance'}</p>
          <Button onClick={fetchBaseBalance} style={{ display: 'block', marginTop: '10px' }}>Get Base Token Balance</Button>
          <p>Native Token Balance: {nativeTokenBalance !== null ? nativeTokenBalance : 'Click button below to find your native token balance'}</p>
          <Button onClick={() => fetchNativeTokenBalance('')} style={{ display: 'block', marginTop: '10px' }}>Get Native Token Balance</Button>
          <p>NFT Balance: {nftBalance !== null ? nftBalance : 'Click button below to find your NFT balance'}</p>
          <Button onClick={fetchNFTBalance} style={{ display: 'block', marginTop: '10px' }}>Get NFT Balance</Button>
        </>
      )}
      <PlaygroundContainer />
    </SelectableText>
  );
};

export default BalanceChecker;