// src/styles/SVGFilter.js
import React from 'react';

const SVGFilter = () => (
  <svg style={{ position: 'absolute', width: '0', height: '0' }}>
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
        <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
      </filter>
    </defs>
  </svg>
);

export default SVGFilter;


// https://css-tricks.com/gooey-effect/
// <filter id="goo">
// <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
// <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
// <feBlend in="SourceGraphic" in2="goo" />
// <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
// </filter> 
