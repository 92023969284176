// src/components/AuthWrapper/index.jsx
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useUserStore, useToastStore } from '../../hooks/useStores';
import SocketService from '../../services/SocketService';

const AuthWrapper = observer(({ children }) => {
  const userStore = useUserStore();
  const toastStore = useToastStore();
  const navigate = useNavigate();
  
  // Debug flag
  const DEBUG = false; // Set this to false in production
  
  // Custom logging function that respects the debug flag
  const log = (...args) => {
    if (DEBUG) {
      console.log(...args);
    }
  };
  
  // States for sign-in
  const [isInitialized, setIsInitialized] = useState(false);
  const [tokenProcessed, setTokenProcessed] = useState(false);
  
  useEffect(() => {
    // Setup listeners
    SocketService.addListener('clientInfo', userStore.setClientInfo);
    SocketService.addListener('userUpdate', userStore.handleUserUpdate);
    
    // Authentication logic...
    log(`${userStore.isLoadingToken ? "Process to load token started" : ""}`);
    let token = new URLSearchParams(window.location.search).get('token');
    
    if (token) {
      // If there's a token in the URL...
      log("URL token found:", token);
      sessionStorage.setItem('authToken', token);
      if (!tokenProcessed) {
        userStore.verifyToken(); // Assuming this is now a synchronous call or does not return a promise
        setTokenProcessed(true);
        setIsInitialized(true); // This should trigger a re-render
        log("Redirecting after token found in URL...");
        //navigate('/'); // This might not be necessary if you're just updating state
      }
    // Check session storage for an existing token...
    } else if (!isInitialized) {
      log("No URL token found. Checking session storage...");
      token = sessionStorage.getItem('authToken');
      if (token && !tokenProcessed) {
        userStore.verifyToken();
        setTokenProcessed(true);
        setIsInitialized(true);
        log("Redirecting after token found in session storage...");
      } else if (!token) {
        log("No token found in session storage.");
        setIsInitialized(true);
        userStore.setIsLoadingToken(false);
        if (!userStore.isLoadingToken) {
          log("No longer checking token");
          log("Redirecting to login...");
        } else {
          log("error - still loading token");
          log("Redirecting to login...");
        }
      }
    }
    
    return () => {
      // Cleanup listeners
      SocketService.removeListener('clientInfo', userStore.setClientInfo);
      SocketService.removeListener('userUpdate', userStore.handleUserUpdate);
    };
  }, [userStore, toastStore, tokenProcessed, isInitialized, navigate]);
  
  log(`Rendering with isInitialized: ${isInitialized}, isLoadingToken: ${userStore.isLoadingToken}`);
  
  if (!isInitialized || userStore.isLoadingToken) {
    log(`isInitialized: ${isInitialized}, isLoadingToken: ${userStore.isLoadingToken}`);
    // This will show until isInitialized becomes true and isLoadingToken becomes false
    return <div>Verifying token...</div>; 
  }
  
  // This will render once isInitialized is true and isLoadingToken is false
  return children; // Render children if user is authenticated
});

export default AuthWrapper;