// themes/darkTheme.js

export const darkTheme = {
  name: 'dark',
  
  // Base colors
  base:'rgb(0,0,0)', // Canvas base
  
  background: 'rgb(30,30,30)', // Dark background
  overlayBackground:'rgba(30,30,30, 0.3)',
  
  neutral: 'rgb(128,128,128)', // Darker text for readability
  textColor: 'rgb(128,128,128)', // Light text
  
  highlightedTextColor: 'rgb(225,225,225)', // Light text
  highlight: 'rgb(225,225,225)',
  
  baseInverted: 'rgb(255,255,255)', // Inverted base for canvas 
  
  // Primary action colors
  primary: '#BB86FC', // Purple, for primary buttons and highlights
  primaryVariant: '#3700B3', // Darker purple, for hovered or active states
  
  // Secondary action colors
  secondary: '#03DAC6', // Teal, for secondary buttons and elements
  secondaryVariant: '#018786', // Darker teal
  
  // Additional colors
  error: '#CF6679', // Red, for error states or messages
  warning: '#FFC107', // Amber, for warning messages
  info: '#2196F3', // Blue, for informational messages
  success: '#4CAF50', // Green, for success messages
  
  // Borders and lines
  border: '#333333', // Slightly lighter than the background for subtle borders
  
  // Others
  buttonColor: '#FFFFFF', // For text on buttons
  tableHeaderBackground: '#2C2C2C', // For table headers or similar elements
};