// src/components/DealerIndicator/index.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { observer } from "mobx-react-lite";
import { DealerCircle } from './styles';

const DealerIndicator = observer(({ position }) => {
  
  return (
    <motion.div
      initial={{ x: position.x, y: position.y }}
      animate={{ x: position.x, y: position.y }}
      transition={{ type: "spring", stiffness: 100, damping: 10 }}
      style={{
        position: 'absolute',
        zIndex: 50,
      }}
    >
      <DealerCircle />
    </motion.div>
  );
});

export default DealerIndicator;