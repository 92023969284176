// StyledSvgLogo.js
import styled from 'styled-components';

export const LogoContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top || 'auto'};
  left: ${({ left }) => left || 'auto'};
  transform: translate(-50%, -50%);
  width: ${({ width }) => width || '10%'};
  max-width: 1000px; // Set maximum width
  aspect-ratio: 3 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
  transition: top 0.5s ease, left 0.5s ease, transform 0.5s ease; // Smoothly adjust position
`;

export const StyledSvgLogo = styled.svg`
  width: 100%;
  height: 100%;
  
  text {
    stroke: ${({ theme, isTextHovered }) => isTextHovered ? theme.highlightedTextColor : theme.textColor};
    stroke-width: ${({ type }) => type === 'solid' ? '0' : '3'};
    /* stroke-width: ${({ type, isTextHovered }) => isTextHovered ? '0' 
    :type === 'solid' ? '0' 
    : '2'}; */
    // stroke-alignment: inside;
    font-family: 'Akira Super Bold', sans-serif;
    font-size: ${({ textSize }) => textSize};
    fill: ${({ theme, type, isTextHovered }) => 
      isTextHovered ? theme.highlightedTextColor : (type === 'solid' ? theme.textColor : 'transparent')};
    transition: all 0.5s ease; // Smooth transition for fill color
  }
`;

export const ClickableOverlay = styled.rect`
  fill: transparent; // Make the rect transparent
  cursor: pointer;
  width: 100%; // Cover the entire SVG width
  height: 100%; // Cover the entire SVG height
`;