import React from "react";
import { observer } from "mobx-react-lite";
import { CardsContainer, CardContainer, CardBack, CardValue, CardSuit } from './styles';

const suitPaths = {
  Hearts: '/assets/cards/heart.svg',
  Diamonds: '/assets/cards/diamond.svg',
  Spades: '/assets/cards/spade.svg',
  Clubs: '/assets/cards/club.svg'
};

const cardValues = {
  Ace: 'A',
  King: 'K',
  Queen: 'Q',
  Jack: 'J',
  Ten: '10',
  Nine: '9',
  Eight: '8',
  Seven: '7',
  Six: '6',
  Five: '5',
  Four: '4',
  Three: '3',
  Two: '2'
};

const PlayerCards = observer(({ cards, windowScaleFactor  }) => {
  return (
    <CardsContainer $windowScaleFactor={windowScaleFactor}>
      {cards && cards.map((card, index) => {
        const suit = card.suit ? card.suit : null;
        const value = card.value ? card.value : null;
        const suitPath = suitPaths[suit];
        const cardValue = cardValues[value];
        
        return (
          <CardContainer $windowScaleFactor={windowScaleFactor} key={index}>
            <CardBack></CardBack>
            {value ? <CardValue $windowScaleFactor={windowScaleFactor}>{cardValue}</CardValue> : <div >No Value</div>}
            {suit ? <CardSuit $suitPath={suitPath} $windowScaleFactor={windowScaleFactor}/> : <div >No Suit</div>}
          </CardContainer>
        );
      })}
    </CardsContainer>
  );
});

export default PlayerCards;