// ThemeSwitcher.jsx
import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { LightBulbButton, LightModeIcon, DarkModeIcon } from './styles';
//import MoonIcon from './MoonIcon';
// import SunIcon from './SunIcon';

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  
  return (
    <LightBulbButton onClick={toggleTheme}>
      {theme.name === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
    </LightBulbButton>
  );
};

export default ThemeSwitcher;