// src/views/PublicLandingPage/index.jsx
import { observer } from 'mobx-react';
import { useUserStore } from '../../hooks/useStores';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthModal from '../../components/AuthModal';
import ThemeSwitcher from '../../components/ThemeSwitcher';
import Button from '../../components/Button';
import { useDeviceClass } from '../../hooks/useDeviceClass'; // Import useDeviceClass hook
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeSwitcherContainer, ButtonContainer, IpButtonContainer, MessageContainer } from './styles';

const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);
      
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);
  
  return <span>{currentText}</span>;
};

//export default Typewriter;

const PublicLandingPage = observer(() => {
  const userStore = useUserStore();
  const navigate = useNavigate();
  
  // Scaling
  const deviceClass = useDeviceClass(); // Use the hook to get the device class
  
  // Display
  const [showAuthModal, setShowAuthModal] = useState(false); // State to control AuthModal visibility
  const [showIpMessage, setShowIpMessage] = useState(false);
  const [showButtons, setShowButtons] = useState(true); // Initially true to show the buttons
  const [pendingAction, setPendingAction] = useState(null);
  const [textSize, setTextSize] = useState(20); // Initial text size
  
  // Add this inside your PublicLandingPage component, before the return statement
  const handleConnectClick = () => {
    if (userStore.userAcknowledged) {
      console.log("Proceeding with connection...");
      setShowAuthModal(true);
      setShowButtons(false); // Hide the buttons
    } else {
      // Show the IP-based message for user acknowledgement
      console.log("Please acknowledge the IP-based message first.");
      setPendingAction('connect');
      setShowIpMessage(true);
      setShowButtons(false); // Hide the buttons
    }
  };
  
  const handleXSignInClick = () => {
    if (userStore.userAcknowledged) {
      signInWithX();
      setShowButtons(false); // Hide the buttons
    } else {
      // Optionally, show some message or directly show the IpBasedMessage component
      console.log("Please acknowledge the IP-based message first.");
      setPendingAction('X');
      setShowIpMessage(true);
      setShowButtons(false); // Hide the buttons
    }
  };
  
  // const handleCloseAuthModal = () => {
  //   console.log("Closing modal...");
  //   setShowAuthModal(false); // Function to close the auth modal
  //   console.log("Modal state set to close");
  // }; 

  // Function to handle the closing of AuthModal and showing buttons again
  const handleCloseAuthModal = () => {
    console.log("Closing modal...");
    setShowAuthModal(false);
    setShowButtons(true); // Make sure to show the buttons again
  };
  
  // When passing onClose to AuthModal, use this handleCloseAuthModal
  // <AuthModal onClose={handleCloseAuthModal} isOpen={showAuthModal} />
  
  const ipMessageVariants = {
    hidden: {
      //scale: 1.1, // Start slightly larger
      //filter: 'blur(4px)', // Start with a blur
      opacity: 0, // Start fully transparent
    },
    visible: {
      //scale: 1, // Animate to normal size
      //filter: 'blur(0px)', // Clear the blur
      opacity: 1, // Animate to fully opaque
      transition: {
        duration: 0,
        ease: "easeInOut"
      }
    },
    exit: { 
      //scale: 1.1, 
      //filter: 'blur(4px)', 
      opacity: 0, 
      transition: { 
        duration: 1 
      } 
    }
  };
  
  const fadeinVariants = {
    hidden: {
      opacity: 0, // Start fully transparent
    },
    visible: {
      opacity: 1, // Animate to fully opaque
      transition: {
        duration: .5,
        ease: "easeInOut",
        delay: 2 // 1-second delay before the animation starts
      }
    },
    exit: { 
      opacity: 0, 
      transition: { 
        duration: 1,
        delay: 0 // 1-second delay before the exit animation starts 
      } 
    }
  };
  
  const IpBasedMessage = observer(() => {
    const userStore = useUserStore(); // Hook to access the userStore
    
    // Log values
    //console.log("IpBasedMessage - userAcknowledged:", userStore.userAcknowledged, "clientInfo:", userStore.clientInfo);
    
    const handleAcknowledgement = () => {
      userStore.setUserAcknowledgement(true);
      setShowIpMessage(false); // Hide the message
      
      // Check pending action and proceed
      if (pendingAction === 'connect') {
        setShowAuthModal(true); // Proceed with showing the auth modal
      } else if (pendingAction === 'X') {
        signInWithX(); // Proceed with X sign-in
      }
      
      setPendingAction(null); // Reset pending action
    };
    
    const handleCancellation = () => {
      setShowIpMessage(false); // Start the exit animation
      // Delay showing the button container until the exit animation completes
      setTimeout(() => {
        setShowButtons(true);
      }, 1000); // This timeout should match the duration of your exit animation
    };
    
    if (showIpMessage) {
      return (
        <>
          <motion.div
            initial="hidden" 
            animate="visible" 
            exit="exit"
            variants={ipMessageVariants}
            style={{ 
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
          }}
          >
            <MessageContainer>
              <div className="left-aligned-text">
                <Typewriter 
                  text={`Please be mindful of the local laws in ${userStore.clientInfo.country}. Activities like using cryptocurrencies and placing bets online may not be legal or could be age-restricted. It's your responsibility to ensure it's okay to access and use our platform.`} 
                  delay={7} 
                />
              </div>
            </MessageContainer>
          </motion.div>
          <motion.div
            initial="hidden" 
            animate="visible" 
            exit="exit"
            variants={fadeinVariants}
            style={{ 
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <IpButtonContainer>
              <Button onClick={handleAcknowledgement}>Proceed</Button>
              <Button onClick={handleCancellation}>Cancel</Button>
            </IpButtonContainer>
          </motion.div>
        </>
      );
    }
    
    return null;
  });
  
  useEffect(() => {
    if (userStore.clientInfo) {
      console.log("IP address is now available:", userStore.clientInfo);
      // You can set state here to trigger specific actions in response to the IP being set
    }
  }, [userStore.clientInfo]); // Depend on `clientInfo` to re-run this effect
  
  // Scale main logo based on viewport width
  useEffect(() => {
    const handleResize = () => {
      // Define the scaling logic based on viewport width (vw)
      const vw = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      // Example scaling: adjust text size based on viewport width, capped at 40
      //const newSize = Math.min(20 + (vw / 100), 40); // Scales with vw, up to a max of 40
      // Optional: Adjust based on vh as well, and choose the smaller of the two if needed
      const newSize = Math.min(Math.min(20 + (vw / 10), 800), Math.min(20 + (vh / 10), 800));
      
      setTextSize(newSize);
    };
    
    // Set initial size on component mount
    handleResize();
    
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array means this effect runs once on mount
  
  // Function to handle X sign-in
  const signInWithX = async () => {
    console.log("Sign in with X button clicked");
    try {
      const xAuthUrl = 'https://api.chips.ooo/x/auth'; // Replace with your server URL
      window.location.href = xAuthUrl; // Redirect the user to X authentication
    } catch (error) {
      // Handle errors
      console.error('Error signing in with X:', error); 
    }
  };
  
  // Use state to track if the message has been logged
  const [hasLoggedMessage, setHasLoggedMessage] = useState(false);
  
  // Log the message only once on initial render
  useEffect(() => {
    if (!hasLoggedMessage) {
      console.log("Navigated to PUBLIC landing page for sign-in");
      setHasLoggedMessage(true); // Set the flag to true after logging
    }
  }, [hasLoggedMessage]);
  
  // After the initial render, React executes the useEffect hooks
  useEffect(() => {
    if (userStore.user.username) {
      console.log("Signed in user: " + userStore.user.username + ". Navigating back.");
      navigate(-1); // Navigate back to the previous page
    } else {
      console.log(userStore.user);
      console.log("No user signed in. Please sign in.");
    }
  }, [userStore.user.username, navigate]);
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape' && showAuthModal) {
        setShowAuthModal(false);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showAuthModal]); // Listen for Escape key press to close AuthModal
  
  useEffect(() => {
    console.log("Current device size:", deviceClass);
  }, [deviceClass]);
  
  // useEffect(() => {
  //   const handleAuthResult = async () => {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const error = urlParams.get('error');
      
  //     if (error) {
  //       console.error('Authentication error:', error);
  //       // Handle the error (e.g., show an error message to the user)
  //     } else {
  //       try {
  //         const response = await fetch('/x/auth-success');
  //         const contentType = response.headers.get("content-type");
  //         if (contentType && contentType.indexOf("application/json") !== -1) {
  //           if (response.ok) {
  //             const data = await response.json();
  //             console.log('Authentication successful:', data);
  //             userStore.setUser(data);
  //             navigate('/');
  //           } else {
  //             throw new Error('Authentication failed');
  //           }
  //         } else {
  //           // If the response is not JSON, log the text content for debugging
  //           const text = await response.text();
  //           console.error('Unexpected response type:', contentType);
  //           console.error('Response text:', text);
  //           throw new Error('Unexpected response type from server');
  //         }
  //       } catch (error) {
  //         console.error('Error checking authentication status:', error);
  //         // Handle the error (e.g., show an error message to the user)
  //       }
  //     }
  //   };
  
  //   handleAuthResult();
  // }, [userStore, navigate]);
  // Log values
  //console.log("PublicLandingPage - userAcknowledged:", userStore.userAcknowledged, "clientInfo:", userStore.clientInfo);
  
  if (userStore.isLoadingToken) {
    return <div>Loading...</div>;  // Display a loading message or spinner
  }
  
  return (
    <>
      <AnimatePresence>
        {showIpMessage && 
          <IpBasedMessage 
        />}
      </AnimatePresence>
      <AnimatePresence>
        {showAuthModal && (
          <AuthModal
            isOpen={showAuthModal}
            onClose={handleCloseAuthModal}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!showIpMessage && !showAuthModal && showButtons && ( // Only show buttons if neither modal/message is active
          <>
            <ThemeSwitcherContainer>
              <ThemeSwitcher />
            </ThemeSwitcherContainer>
            
            <ButtonContainer>
              {/* <Button onClick={handleConnectClick}>CONNECT</Button> */}
              <Button onClick={handleXSignInClick}>CONNECT WITH X</Button>
            </ButtonContainer>
          </>
        )}
      </AnimatePresence>
    </>
  );
});

export default PublicLandingPage;