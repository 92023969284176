// src/components/Button/styles.js
import styled from 'styled-components';

interface StyledButtonProps {
  scale: number; // Define the type of the scale prop
}

export const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
  background-color: transparent;
  backdrop-filter: blur(10px); // Blur the background behind the button
  padding: ${props => 10 * props.scale}px ${props => 24 * props.scale}px;
  border: 2px solid ${({ theme }) => theme.textColor || 'red'}; // Use border instead of outline
  border-radius: 15px;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  transition: all 300ms ease;
  //margin: ${props => 10 * props.scale}px;
  line-height: 20px;

  &:hover {
    color: ${({ theme }) => theme.background};
    border-color: ${({ theme }) => theme.highlight}; // Adjust border color on hover
    background-color: ${({ theme }) => theme.highlight};
  }
`;

// Border Instead of Outline: The border property is used to replace outline. 
// This ensures that the button's background color extends all the way to the border's edge.
// Unlike borders, outlines do not take up space as they are drawn outside of an element's box. 
// This characteristic can sometimes lead to rendering artifacts such as the gap 

// export const StyledButton = styled.button`
//   cursor: pointer; 
//   color: ${({ theme }) => theme.textColor};
//   background-color: transparent;
//   backdrop-filter: blur(10px); // Blur the background behind the button
//   padding: ${props => 10 * props.scale}px ${props => 20 * props.scale}px; 
//   outline: 2px solid ${({ theme }) => theme.textColor || 'red'};
//   border-radius: 15px;
//   border-width: 0;
//   letter-spacing: normal;
//   word-spacing: normal;
//   text-indent: 0px;
//   text-shadow: none;
//   display: inline-block;
//   text-align: center;
//   transition: all 300ms ease;
//   margin: ${props => 10 * props.scale}px;
//   &:hover {
//     color: ${({ theme }) => theme.background};
//     outline: 2px solid ${({ theme }) => theme.highlight};
//     background-color: ${({ theme }) => theme.highlight};
//   }
// `;