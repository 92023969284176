// src/components/SeatAndPlayer/styles.js
import styled from 'styled-components';

export const CoinSymbol = styled.div`
  color: ${({ theme }) => theme.base || 'red'}; // Or any color you prefer
`;

export const CurrentBetContainer = styled.div`
  position: absolute;
  z-index: 5; // Keep your existing z-index for layering
  left: ${({ left }) => left || '50%'};
  top: ${({ top }) => top || '50%'};
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  width: 50px; // Example size, adjust as needed
  height: 50px; // Equal to width for a circle
  border-radius: 50%; // Make it circular
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.textColor};
  transition: all 300ms ease; // Smooth transition for any changes
  visibility: visible; // Ensure it's always visible or adjust based on state
`;

export const CurrentBetFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; // Take full width of its parent
`;

export const CurrentBetAmount = styled.div`
  color: ${({ theme }) => theme.base || 'red'}; 
`;