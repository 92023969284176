// src/services/SocketService.js
import io, { Socket } from 'socket.io-client';

// Determine the server URL based on the environment variable or fallback to localhost
const SOCKET_SERVER = import.meta.env.VITE_SOCKET_SERVER || 'http://localhost:5000';

type Listener = {
  event: string;
  callback: (...args: any[]) => void;
};

class SocketService {
  private socket: Socket | null = null;
  private listeners: Listener[] = [];  // Initialize the listeners array
  
  constructor() {
    this.socket = null; // Initialize socket to null
    this.listeners = [];  // Initialize the listeners array
    
    this.connect(); // Connect to the default namespace
    
    // Add beforeunload event listener
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }
  
  private handleBeforeUnload = (event: Event) => {
    if (this.socket) {
      this.socket.disconnect();
    }
  };
  
  connect(namespace: string = '') {
    if (this.socket) {
      this.close();  // Ensure previous connection is closed before connecting again
    }
    
    this.socket = io(`${SOCKET_SERVER}${namespace}`);
    
    return this.socket;
  }
  
  // The method to add a callback for the socket's connect event
  onConnect(callback: () => void): void {
    this.socket?.on('connect', callback);
  }
  
  // To add a listener to the socket
  addListener(event: string, callback: (...args: any[]) => void): void {
    this.socket?.on(event, callback);
    this.listeners.push({ event, callback }); // Save the listener so it can be removed later
  }
  
  // To remove a listener from the socket
  removeListener(event: string, callback: (...args: any[]) => void): void {
    this.socket?.off(event, callback);
    const index = this.listeners.findIndex(listener => listener.event === event && listener.callback === callback);
    if (index > -1) {
      this.listeners.splice(index, 1);
    }
  }
  
  setToken(token: string): void {
    if (token && this.socket) {
      this.socket.io.opts.query = { token: 'JWT ' + token };
      
      // Reconnect with the new token
      this.socket.connect();
    }
  }
  
  // To send events to the server
  emit(event: string, data: any, callback?: (...args: any[]) => void): void {
    this.socket?.emit(event, data, callback);
  }
  
  // To close the socket connection and remove all listeners
  close(): void {
    // Remove the beforeunload event listener when the socket is closed
    window.removeEventListener("beforeunload", this.handleBeforeUnload);

    this.listeners.forEach(listener => {
      this.socket?.off(listener.event, listener.callback);
    });
    this.socket?.close();
    this.socket = null;
  }
  
  // This method will be used to connect to different namespaces and get a socket instance
  getSocketForNamespace(namespace = '') {
    return this.connect(namespace);
  }
}

export default new SocketService();