import React, { createContext, useContext, useState, useLayoutEffect, useRef } from 'react';

export const ScaleContext = createContext({});

export const ScaleProvider = ({ children }) => {
  const containerRef = useRef(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [orientation, setOrientation] = useState("landscape");
  const [tableSize, setTableSize] = useState(0);

  const calculateScaleFactor = () => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      const newOrientation = width > height ? "landscape" : "portrait";
      const designWidth = newOrientation === "landscape" ? 1000 : 500;
      const designHeight = newOrientation === "landscape" ? 500 : 1000;
      const scaleX = width / designWidth;
      const scaleY = height / designHeight;
      const newScaleFactor = Math.min(scaleX, scaleY);
      
      setScaleFactor(newScaleFactor);
      setOrientation(newOrientation);
      setTableSize(Math.min(width, height));
    } else {
      requestAnimationFrame(calculateScaleFactor);
    }
  };

  useLayoutEffect(() => {
    requestAnimationFrame(calculateScaleFactor);
    window.addEventListener('resize', calculateScaleFactor);

    return () => {
      window.removeEventListener('resize', calculateScaleFactor);
    };
  }, [orientation]);

  return (
    <ScaleContext.Provider value={{ scaleFactor, orientation, tableSize, containerRef, calculateScaleFactor }}>
      {children}
    </ScaleContext.Provider>
  );
};

export const useScale = () => useContext(ScaleContext);