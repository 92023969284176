// src/lib/isc/services/clients.ts
import type { PublicClient, WalletClient } from "viem";
import { createWalletClient, createPublicClient, custom, http } from "viem";
import { chips } from "viem/chains"
import { createBundlerClient} from 'viem/account-abstraction'

export const publicClient: PublicClient = createPublicClient({
  chain: chips, 
  transport: http(),
});

console.log('Attempting to create walletClient');
export const walletClient: WalletClient | null = typeof window !== 'undefined' && window.ethereum ?  
  createWalletClient({
    //chain: polygonMumbai,
    chain: chips, // Use the custom Chips Network chain
    transport: custom(window.ethereum),
  })
  : (() => {
    console.warn("Ethereum provider not found. Please install MetaMask.");
    return null;
  }
)();

export function isEthereumProviderAvailable(): boolean {
  return typeof window !== 'undefined' && window.ethereum !== undefined;
}

export const bundlerClient = createBundlerClient({
  chain: chips, 
  transport: http('https://bundler.chips.ooo/rpc')
});