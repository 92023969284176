import { observer } from 'mobx-react-lite';
import { useScale } from '../../hooks/useStores';
import { Line } from './styles';

const GameOverlay = observer(() => {
  const { scaleFactor, orientation } = useScale();

  return (
    <>
      <Line
        id="top-left"
        style={{
          left: `${20 * scaleFactor}px`,
          right: orientation === 'landscape' ? `75%` : `${20 * scaleFactor}px`,
          top: `${75 * scaleFactor}px`,
          zIndex: `100`
        }}
      />
      <Line
        id="top-right"
        style={{
          left: orientation === 'landscape' ? `55%` : `${20 * scaleFactor}px`,
          right: `${20 * scaleFactor}px`,
          top: `${75 * scaleFactor}px`,
          zIndex: `100`
        }}
      />
      <Line
        id="bottom-left"
        style={{
          left: `${20 * scaleFactor}px`,
          right: orientation === 'landscape' ? `75%` : `${20 * scaleFactor}px`,
          bottom: `${75 * scaleFactor}px`,
        }}
      />
      <Line
        id="bottom-right"
        style={{
          left: orientation === 'landscape' ? `55%` : `${20 * scaleFactor}px`,
          right: `${20 * scaleFactor}px`,
          bottom: `${75 * scaleFactor}px`,
        }}
      />
    </>
  );
});

export default GameOverlay;