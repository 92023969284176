// src/components/BetChip/styles.js
import styled from 'styled-components';

export const Bet = styled.div`
  z-index: 5;
  width: 50px; // Example size, adjust as needed
  height: 50px; // Equal to width for a circle
  border-radius: 50%; // Make it circular
  //transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.textColor};
  transition: all 300ms ease; // Smooth transition for any changes
  visibility: visible; // Ensure it's always visible or adjust based on state
`;