import styled from 'styled-components';

export const StyledChat = styled.div`
  //flex-basis: ${({ isChatOpen }) => (isChatOpen ? '250px' : '0px')};
  //flex-shrink: 0; // Prevents the chat bar from shrinking below its flex-basis value.
  overflow: hidden; // Keeps the content from spilling out during transition.
  //transition: flex-basis 0.5s ease, visibility 0.5s ease;
  transition: all 0.3s ease-in-out;
  //visibility: ${({ isChatOpen }) => (isChatOpen ? 'visible' : 'hidden')};
  //position: ${({ deviceClass }) => (deviceClass === 'compact' ? 'absolute' : 'relative')};
  height: 100%;
  width: 150%;
  //right: 0;
  //z-index: 150;
  /* Conditional padding */
  //padding: ${({ isChatOpen }) => (isChatOpen ? '10px' : '0')};
  padding: 10px;
  /* Adjust border to not take up space when not open */
  //border: ${({ isChatOpen }) => (isChatOpen ? '1px solid #ccc' : '0')};
  //border: 1px solid #ccc;
  /* Common styles */
  //color: ${({ theme }) => theme.textColor || 'red'};
  //background-color: transparent;
  background-color: ${({ deviceClass, theme }) => (deviceClass === 'compact' ? theme.background : 'transparent')};
  //backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
`;

export const ChatHeader = styled.div`
  padding: 0px;
  // background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  font-size: 20px;
  font-weight: bold;
`;

export const MessageArea = styled.div`
  padding: 16px;
  overflow-y: auto;
  flex-grow: 1;
`;

export const MessageInput = styled.input`
  border: none;
  padding: 0px;
  border-top: 1px solid #ddd;
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const CloseIcon = styled.span`
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
  font-size: 2.5rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.textColor}; 
  transition: all 300ms ease-in-out;
  cursor: pointer; // Makes it clear it's clickable
  &::before {
    content: 'close';
  }
  &:hover {
    color: ${({ theme }) => theme.highlightedTextColor || 'red'};
    font-variation-settings:
      'FILL' 0,
      'wght' 500,
      'GRAD' 0,
      'opsz' 24;
  }
`;