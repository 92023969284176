// src/views/PlaygroundSendBase/index.jsx
import React, { useState } from 'react';
import Button from '../../components/ButtonWithMargins';
import PlaygroundContainer from '../../components/PlaygroundContainer';
import { walletClient, publicClient } from '@isc/client/clients';
import { L1AssetsABI } from './L1AssetsABI';
import { encodeAbiParameters } from 'viem';
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from './styles';

// Define the contract object with its address and ABI
const contract = {
  address: '0xD5A5505A18d914166063E29f98C9a4594245a61b' as `0x${string}`,
  abi: L1AssetsABI,
};

/**
 * Component that handles calling the `withdraw` function from the L1Assets contract.
 * 
 * @param contract The L1Assets contract object with address and ABI.
 * @param account The connected user's account address.
 */
const SendToL1: React.FC = () => {
  const [output, setOutput] = useState<any>(null);
  const [recipientAddress, setRecipientAddress] = useState<string>('');
  const [account, setAccount] = useState<string | null>(null);
  const [gasEstimate, setGasEstimate] = useState<bigint | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  
  // Function to connect to MetaMask and retrieve the user's account address
  const connectMetaMask = async () => {
    try {
      const addresses = await walletClient.getAddresses();
      setAccount(addresses[0]);
    } catch (error) {
      console.error('Failed to connect wallet', error);
    }
  };
  
  const formatErrorMessage = (error: any) => {
    if (error?.message) {
      // Extract the 'Details' part of the message
      const detailsStart = error.message.indexOf('Details:');
      const detailsEnd = error.message.indexOf('Version:', detailsStart);
      const details = error.message.substring(detailsStart, detailsEnd).trim();
      
      return (
        <div style={{ color: 'red', marginTop: '10px' }}>
          <strong>Error:</strong>
          <p style={{ whiteSpace: 'pre-wrap' }}>{details}</p>
        </div>
      );
    }
    return <p style={{ color: 'red' }}>An unexpected error occurred.</p>;
  };
  
  const withdrawAssets = async () => {
    try {
      // Reset the output and error messages
      setOutput(null);
      setErrorMessage(null);
      
      // Encode the L1Address struct as a tuple
      const encodedAddress = encodeAbiParameters(
        [{ name: "data", type: "bytes" }],
        [recipientAddress as `0x${string}`]
      );
      
          // Estimate gas
      const gas = await publicClient.estimateContractGas({
        account: account as `0x${string}`,
        address: contract.address,
        abi: contract.abi,
        functionName: 'withdraw',
        args: [{ data: encodedAddress }],
      });
      
      setGasEstimate(gas);
      console.log('Estimated gas:', gas);
      
      // If gas estimation fails, set an error message and prevent the transaction
      if (!gas) {
        setErrorMessage("Gas estimation failed. Please check the input parameters.");
        return;
      }
      
      // Proceed with sending the transaction
      const result = await walletClient.writeContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'withdraw',
        args: [{ data: encodedAddress }],
        account: account as `0x${string}`,
        gas: gasEstimate || undefined, // Use estimated gas if available
      });
      
      setOutput(result);
      console.log('Withdrawal successful', result);
    } catch (error) {
      const typedError = error as Error; // Cast 'error' to 'Error'
      console.error('Transaction failed', typedError);
      setErrorMessage(typedError.message);
    }
  };
  
  return (
    <OperationContainer>
      <StyledHeading>Send Base</StyledHeading>
      <Button onClick={connectMetaMask}>Connect to MetaMask</Button>
      {account && (
        <FlexContainer>
          <OutlinedTextField
            label="Recipient Address"
            value={recipientAddress}
            onChange={(e) => setRecipientAddress(e.target.value)}
            supportingText="* Required"
          />
          <Button onClick={withdrawAssets}>Withdraw Assets</Button>
          {gasEstimate !== null && <p>Estimated Gas: {gasEstimate.toString()}</p>}
          {errorMessage && formatErrorMessage({ message: errorMessage })}
        </FlexContainer>
      )}
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
      <PlaygroundContainer />
    </OperationContainer>
  );
};

export default SendToL1;