// Shadows for each elevation based on display pixels
export const elevationShadows = {
  0: 'none',
  1: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  2: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  3: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  4: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  6: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
  8: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  12: '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
  16: '0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)',
  24: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)'
};

// Function to get shadow style by dp value directly
export const getShadowByDp = (dp) => {
  const shadow = elevationShadows[dp] || elevationShadows[0];
  //console.log(`getShadowByDp: dp=${dp}, shadow='${shadow}'`);
  return shadow;
};

// Function to get shadow based on Material Design 3 levels
export const getShadowByLevel = (level) => {
  const levelToDpMap = {
    0: 0,
    1: 1,
    2: 3,
    3: 6,
    4: 8,
    5: 12,
  };
  
  const dp = levelToDpMap[level];
  const shadow = elevationShadows[dp] || elevationShadows[0];
  //console.log(`getShadowByLevel: level=${level}, dp=${dp}, shadow='${shadow}'`);
  return shadow;
};
