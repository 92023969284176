// src/views/PlaygroundSandbox/components/GetAllowanceTo.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { ReadOnlyContractProps, ISCAssets } from '@isc/types';
import { publicClient } from '@isc/client/clients';
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `getAllowanceFrom` function from the ISC contract.
 *
 * @param contract The ISC contract object with address and ABI.
 */
const GetAllowanceFrom: React.FC<ReadOnlyContractProps> = ({ contract }) => {
  const [output, setOutput] = useState<ISCAssets | null>(null);
  const [fromAddress, setFromAddress] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  /**
   * Calls the `getAllowanceFrom` function from the ISC contract.
   *
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details to retrieve the allowance information from a specific address.
   */
  const handleGetAllowanceFrom = async () => {
    try {
      setError(null); // Clear previous errors
      const result = await publicClient.readContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'getAllowanceFrom',
        args: [fromAddress],
      });

      setOutput(result as ISCAssets);
      console.log(`getAllowanceFrom result:`, result);
    } catch (error: any) {
      console.error(`Failed to call getAllowanceFrom`, error);
      setError('Invalid address. Please enter a valid address.');
    }
  };

  const renderOutput = (output: ISCAssets) => {
    const baseTokens = output.baseTokens ? output.baseTokens.toString() : '0';
    const nativeTokens = output.nativeTokens.length > 0 ? output.nativeTokens.map(token => `ID: ${token.ID}, Amount: ${token.amount.toString()}`).join(', ') : 'None';
    const nfts = output.nfts.length > 0 ? output.nfts.join(', ') : 'None';

    return (
      <div>
        <h3>Output:</h3>
        <p><strong>Base Tokens:</strong> {baseTokens}</p>
        <p><strong>Native Tokens:</strong> {nativeTokens}</p>
        <p><strong>NFTs:</strong> {nfts}</p>
      </div>
    );
  };

  return (
    <OperationContainer>
      <StyledHeading>Get Allowance From</StyledHeading>
      <FlexContainer>
        <OutlinedTextField
          label="From Address (address)"
          value={fromAddress}
          onChange={(e) => setFromAddress(e.target.value)}
          supportingText="* Required"
          error={error}
        />
      </FlexContainer>
      <Button onClick={handleGetAllowanceFrom}>Call Get Allowance From</Button>
      {output && renderOutput(output)}
    </OperationContainer>
  );
};

export default GetAllowanceFrom;