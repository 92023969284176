// utils/utils.js
export const convertHexToDecimal = (hexValue) => {
  //return /^0x/.test(hexValue) ? parseInt(hexValue, 16).toString() : hexValue;
  return /^0x/.test(hexValue) ? parseInt(hexValue, 16) : hexValue;
};

export const formatBalance = (balance) => {
  return (convertHexToDecimal(balance) / 1_000_000).toFixed(6);
};

export const formatKey = (key) => {
  // Split the key at each uppercase letter or underscore
  const words = key.split(/(?=[A-Z])|_/).map(word => {
    // Capitalize the first letter of each word and make the rest lowercase
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  // Join the words with a space
  return words.join(' ');
};