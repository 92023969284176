// src/views/PrivateLandingPage/index.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react'; 
import { useUserStore, useTableStore } from '../../hooks/useStores';
import { useDeviceClass } from '../../hooks/useDeviceClass'; 
import SocketService from '../../services/SocketService';
import Button from '../../components/ButtonWithMargins';
import PlaygroundContainer from '../../components/PlaygroundContainer'; // Updated import path
import { PrivateLandingContainer, Table } from './styles';
import { ITable } from '../../types/table';

// Helper function to count occupied seats, defined outside the component
const countOccupiedSeats = (table: ITable): number => {
  return table.seats.reduce((count, seat) => count + (seat.status === 'occupied' ? 1 : 0), 0);
};

const PrivateLandingPage = observer(() => {
  const deviceClass = useDeviceClass(); // Use the hook to get the device class
  const userStore = useUserStore();
  const tableStore = useTableStore();
  const navigate = useNavigate();
  
  //console.log("User Landed on main Private Landing page")
  
  // Define visibility for expanded or greater device classes
  const showExtraColumns = ['expanded', 'large', 'extraLarge'].includes(deviceClass);
  
  // Handle the creation of a new table - startNewTable method will lead to setSeatedAtTable being called
  const handleNewTable = () => {
    console.log("Request to start new table");
    tableStore.startNewTable(); 
  };
  
  // Navigate to table/game (draggable circles)
  const handleGoToTable = (table: ITable) => {
    console.log("Go to table: ", table);
    navigate(`/table/${table.tableNumber}`);
  };
  
  // Effect for navigating when seatedAtTable changes
  useEffect(() => {
    if (tableStore.seatedAtTable) {
      console.log(`User navigating to: /table/${tableStore.seatedAtTable}`);
      navigate(`/table/${tableStore.seatedAtTable}`);
    }
  }, [tableStore.seatedAtTable, navigate]);
  
  // Setup and cleanup of socket listeners
  useEffect(() => {
    // Setup listeners when the component mounts
    SocketService.addListener('tableIndex', tableStore.handleTableIndex);
    SocketService.addListener('newTable', tableStore.handleNewTable);
    
    // Fetch all table data here to populate Header and TableList
    if (userStore.user.username) {
      tableStore.fetchAllTables();
    }
    
    // Cleanup listeners when the component unmounts
    return () => {
      SocketService.removeListener('tableIndex', tableStore.handleTableIndex);
      SocketService.removeListener('newTable', tableStore.handleNewTable);
    };
  }, [userStore.user.username, tableStore]); // Ensure dependencies are correct to avoid unnecessary re-registrations
  
  return (
    <PrivateLandingContainer>
      <Table>
        <Button onClick={handleNewTable}>Sit at new table</Button>
        <h2>Tables</h2>
        <table>
          <thead>
            <tr>
              {showExtraColumns && <th>Table ID</th>}
              <th>Table</th>
              <th>Seats</th>
              {showExtraColumns && <th>Current Game ID</th>}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableStore.tableIndex && tableStore.tableIndex.map((table: ITable, index: number) => (
              <tr key={index}>
                {showExtraColumns && <td>{table._id}</td>}
                <td>{table.tableNumber}</td>
                <td>{countOccupiedSeats(table)} / {table.seats.length}</td>
                {showExtraColumns && <td>{table.tableCurrentGame ? table.tableCurrentGame : "None"}</td>}
                <td>
                  <Button onClick={() => handleGoToTable(table)}>Go to table</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
      <PlaygroundContainer />
    </PrivateLandingContainer>
  );
});

export default PrivateLandingPage;