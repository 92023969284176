// src/views/PlaygroundSandbox/components/CallView.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { ReadOnlyContractProps, ISCHname, ISCDict } from '@isc/types';
import { publicClient } from '@isc/client/clients';
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `callView` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const CallView: React.FC<ReadOnlyContractProps> = ({ contract }) => {
  const [output, setOutput] = useState<any>(null);
  const [contractHname, setContractHname] = useState<string>('');
  const [entryPoint, setEntryPoint] = useState<string>('');

  /**
   * Calls the `callView` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details. It allows calling a specific view entry point
   * of a contract with specified parameters.
   */
  const handleCallView = async () => {
    try {
      const emptyParams = {
        items: [
          {
            key: new Uint8Array(),
            value: new Uint8Array(),
          },
        ],
      };
  
      const result = await publicClient.readContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'callView',
        args: [contractHname, entryPoint, emptyParams] as const, // Mark args as readonly
      });
  
      setOutput(result);
      console.log(`callView result:`, result);
    } catch (error) {
      console.error(`Failed to call view`, error);
    }
  };

  return (
    <OperationContainer>
      <StyledHeading>Call View</StyledHeading>
      <FlexContainer>
        <OutlinedTextField
          label="Contract Hname (ISCHname)"
          value={contractHname}
          onChange={(e) => setContractHname(e.target.value)}
          supportingText="* Required"
        />
        <OutlinedTextField
          label="Entry Point (ISCHname)"
          value={entryPoint}
          onChange={(e) => setEntryPoint(e.target.value)}
          supportingText="* Required"
        />
      </FlexContainer>
      <Button onClick={handleCallView}>Call View</Button>
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
    </OperationContainer>
  );
};

export default CallView;