import styled from 'styled-components';

export const LightBulbButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  //color: ${({ theme }) => theme.color};
`;

export const LightModeIcon = styled.span`
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
  font-size: 2.5rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.textColor}; 
  transition: all 300ms ease-in-out;
  &::before {
    content: 'light_mode';
  }
  &:hover {
    color: ${({ theme }) => theme.highlightedTextColor || 'red'};
    font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 24;
  }
`;

export const DarkModeIcon = styled.span`
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
  font-size: 2.5rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.textColor}; 
  transition: all 300ms ease-in-out;
  &::before {
    content: 'dark_mode';
  }
  &:hover {
    color: ${({ theme }) => theme.highlightedTextColor || 'red'};
    font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 24;
  }
`;