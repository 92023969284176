// src/views/PlaygroundSandbox/components/Send.tsx
import React, { useState } from 'react';
import { WritableContractProps, L1Address, ISCAssets } from '@isc/types';
import { chips } from '@isc/client/chipsChain';
import Button from '@components/ButtonWithMargins';
import Switch from '@components/Switch';
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `send` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const Send: React.FC<WritableContractProps> = ({ contract, account, walletClient }) => {
  const [output, setOutput] = useState<any>(null);
  const [targetAddress, setTargetAddress] = useState<string>('');
  const [baseTokens, setBaseTokens] = useState<string>('');
  const [adjustStorage, setAdjustStorage] = useState<boolean>(false);
  
  /**
   * Calls the `send` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details. It sends specified assets from the caller's 
   * L2 account to a specified L1 address.
   */
  const handleSend = async () => {
    try {
      const iscAssets: ISCAssets = {
        baseTokens: typeof baseTokens === 'string' ? BigInt(baseTokens) : baseTokens,
        nativeTokens: [], // Assuming no native tokens are involved
        nfts: [], // Assuming no NFTs are involved
      };
      
      // Check if account and walletClient are defined before using them
      if (!account || !walletClient) {
        throw new Error("Account or wallet client is missing.");
      }
      
      const result = await walletClient.writeContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'send',
        args: [targetAddress, iscAssets, adjustStorage] as const,
        account,
        chain: chips, // Add the chain parameter
      });
      
      setOutput(result);
      console.log(`send result:`, result);
    } catch (error) {
      console.error(`Failed to call send`, error);
    }
  };
  
  return (
    <OperationContainer>
      <StyledHeading>Send</StyledHeading>
      <FlexContainer>
        <OutlinedTextField
          label="Target Address (L1Address)"
          value={targetAddress}
          onChange={(e) => setTargetAddress(e.target.value)}
          supportingText="* Required"
        />
        <OutlinedTextField
          label="Base Tokens (uint64)"
          value={baseTokens}
          onChange={(e) => setBaseTokens(e.target.value)}
          supportingText="* Required"
        />
        <Switch
          label="Adjust Minimum Storage Deposit"
          checked={adjustStorage}
          onChange={(e) => setAdjustStorage(e.target.checked)}
        />
      </FlexContainer>
      <Button onClick={handleSend}>Call Send</Button>
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
    </OperationContainer>
  );
};

export default Send;
