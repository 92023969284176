// styledTableComponents.js
import styled from 'styled-components';

const TopRightText = styled.div`
    cursor: pointer; 
    color: ${({ theme }) => theme.highlightedTextColor};
    position: absolute;
    top: ${({ $scaleFactor }) => ((75 - 2) / 2 - 15) * $scaleFactor}px;
    right: ${({ $scaleFactor, $orientation }) => $orientation === 'landscape' ? 120 * $scaleFactor : 55 * $scaleFactor}px;
    font-size: ${({ $scaleFactor }) => 30 * $scaleFactor}px;
`;

const Line = styled.div`
    position: absolute;
    height: 2px;
    background-color: ${({ theme }) => theme.highlightedTextColor };
    transition: all 0.5s ease-in-out;
    z-Index: 100;
`;

export { TopRightText, Line };