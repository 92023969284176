// src/views/PlaygroundSandbox/index.tsx
import React, { useState, useEffect } from 'react';
import Button from '@components/Button';
import { walletClient } from '@isc/client/clients';
import { ISCSandboxABI } from '@isc/contracts/ISCSandboxABI';
import GetBaseTokenProperties from './components/GetBaseTokenProperties';
import GetRequestID from './components/GetRequestID';
import GetSenderAccount from './components/GetSenderAccount';
import TriggerEvent from './components/TriggerEvent';
import GetEntropy from './components/GetEntropy';
import Allow from './components/Allow';
import TakeAllowedFunds from './components/TakeAllowedFunds';
import GetAllowanceFrom from './components/GetAllowanceFrom';
import GetAllowanceTo from './components/GetAllowanceTo';
//import GetAllowanceToEthers from './components/GetAllowanceTo_Ethers';
//import GetAllowanceToWeb3 from './components/GetAllowanceTo_Web3';
import Send from './components/Send';
import Call from './components/Call';
import CallView from './components/CallView';
import PlaygroundContainer from '@components/PlaygroundContainer';
import { PlaygroundContent, SandboxContainer, OperationsList, OperationContainer, FlexButtonGroup } from './styles';

// Define the contract object with its address and ABI
const contract = {
  address: '0x1074000000000000000000000000000000000000' as `0x${string}`,
  abi: ISCSandboxABI,
};

const ISCSandboxManager: React.FC = () => {
  const [account, setAccount] = useState<`0x${string}` | null>(null);
  const [selectedOperation, setSelectedOperation] = useState<string | null>(null);
  
  // Function to connect to MetaMask and retrieve the user's account address
  const connectMetaMask = async () => {
    try {
      const addresses = await walletClient.getAddresses();
      setAccount(addresses[0] as `0x${string}`);
    } catch (error) {
      console.error('Failed to connect wallet', error);
    }
  };
  
  const renderOperation = () => {
    if (!account) {
      return <div>Please connect your account to proceed.</div>;
    }

    switch (selectedOperation) {
      case 'allow':
        return <Allow contract={contract} account={account} walletClient={walletClient} />;
      case 'call':
        return <Call contract={contract} account={account} walletClient={walletClient} />;
      case 'send':
        return <Send contract={contract} account={account} walletClient={walletClient} />;
      case 'takeAllowedFunds':
        return <TakeAllowedFunds contract={contract} account={account} walletClient={walletClient} />;
      case 'triggerEvent':
        return <TriggerEvent contract={contract} account={account} walletClient={walletClient} />;
      case 'callView':
        return <CallView contract={contract} />;
      case 'getAllowanceFrom':
        return <GetAllowanceFrom contract={contract} />;
      case 'getAllowanceTo':
        return <GetAllowanceTo contract={contract} account={account} />;
      // case 'getAllowanceToWeb3':
      //   return <GetAllowanceToWeb3 contract={contract} />;
      // case 'getAllowanceToEthers':
      //   return <GetAllowanceToEthers contract={contract} />;
      case 'getBaseTokenProperties':
        return <GetBaseTokenProperties contract={contract} />;
      case 'getRequestID':
        return <GetRequestID contract={contract} />;
      case 'getSenderAccount':
        return <GetSenderAccount contract={contract} />;
      case 'getEntropy':
        return <GetEntropy contract={contract} />;
      default:
        return <div>Select an operation</div>;
    }
  };
  
  return (
    <PlaygroundContent>
      <SandboxContainer>
        <h1>ISCSandbox Manager</h1>
        <Button onClick={connectMetaMask}>Connect to MetaMask</Button>
        {account && (
          <>
            <p>Connected account: {account}</p>
            <div style={{ display: 'flex' }}>
              <OperationsList>
                <h2>Operations</h2>
                <FlexButtonGroup>
                  <Button onClick={() => setSelectedOperation('allow')}>Allow</Button>
                  <Button onClick={() => setSelectedOperation('call')}>Call</Button>
                  {/* registerERC20NativeToken */}
                  <Button onClick={() => setSelectedOperation('send')}>Send</Button>
                  <Button onClick={() => setSelectedOperation('takeAllowedFunds')}>Take Allowed Funds</Button>
                  <Button onClick={() => setSelectedOperation('triggerEvent')}>Trigger Event</Button>
                  <Button onClick={() => setSelectedOperation('callView')}>Call View</Button>
                  {/* erc20NativeTokensAddress */}
                  {/* erc20NativeTokensFoundrySerialNumber */}
                  {/* erc721NFTCollectionAddress */}
                  {/* getAllowance */}
                  <Button onClick={() => setSelectedOperation('getAllowanceFrom')}>Get Allowance From</Button>
                  <Button onClick={() => setSelectedOperation('getAllowanceTo')}>Get Allowance To</Button>
                  {/* <Button onClick={() => setSelectedOperation('getAllowanceToWeb3')}>Web3 - Get Allowance To</Button> */}
                  {/* <Button onClick={() => setSelectedOperation('getAllowanceToEthers')}>Ethers - Get Allowance To</Button> */}
                  <Button onClick={() => setSelectedOperation('getBaseTokenProperties')}>Get Base Token Properties</Button>
                  {/* getChainID */}
                  {/* getChainOwnerID */}
                  <Button onClick={() => setSelectedOperation('getEntropy')}>Get Entropy</Button>
                  {/* getIRC27NFTData */}
                  {/* getIRC27TokenURI */}
                  {/* getNativeTokenID */}
                  {/* getNativeTokenScheme */}
                  {/* getNFTData */}
                  <Button onClick={() => setSelectedOperation('getRequestID')}>Get Request ID</Button>
                  <Button onClick={() => setSelectedOperation('getSenderAccount')}>Get Sender Account</Button>
                  {/* getTimestampUnixSeconds */}
                  </FlexButtonGroup>
              </OperationsList>
              {/* <OperationContainer style={{ flex: 1 }}> */}
              {renderOperation()}
              {/* </OperationContainer> */}
            </div>
          </>
        )}
      </SandboxContainer>
      <PlaygroundContainer />
    </PlaygroundContent>
  );
};

export default ISCSandboxManager;