// src/components/Button/index.js
import React from 'react';
import { StyledButton } from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  scale?: number;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ children, scale = 1, onClick, ...rest }) => {
  return (
    <StyledButton scale={scale} onClick={onClick} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;