// src/components/Logo/index.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite'; // Make sure to import observer
import { useScale } from '../../hooks/useStores';
import { LogoContainer, StyledSvgLogo, ClickableOverlay } from './styles';

const MainLogoComponent = observer(({ currentPage }) => {
  const navigate = useNavigate(); 
  const { scaleFactor, orientation } = useScale(); // Use orientation from context
  const [isTextHovered, setIsTextHovered] = useState(false);
  const logoRef = useRef(null);  // Reference to the logo container
  const [calculatedFontSize, setCalculatedFontSize] = useState('16px'); // Default font size
  
  useEffect(() => {
    if (logoRef.current) {
      const { width } = logoRef.current.getBoundingClientRect(); // Get actual width of the element
      const fontSizeFactor = 0.15; // Font size as a percentage of container's width
      const newFontSize = Math.max(16, Math.min(200, width * fontSizeFactor)); // Clamp between 16px and 200px
      setCalculatedFontSize(`${newFontSize}px`); // Set calculated font size
    }
  }, [scaleFactor, orientation, currentPage, logoRef.current]); // Recalculate when scaleFactor changes or element is rendered
  
  // Determine style based on currentPage
  let style = {
    'main': { top: '20%', left: '50%', width: '100%', type: 'outline' },
    'loading': { top: '40%', left: '50%', width: '100%', type: 'outline' },
    'login': { top: '33%', left: '50%', width: '100%', type: 'outline' },
    'auth-fail': { top: '50%', left: '50%', width: '100%', type: 'outline' },
    'table': orientation === 'portrait'
    ? { top: `${50 * scaleFactor}px`, left: '50%', width: `${300 * scaleFactor}px`, type: 'solid' }
    : { top: `${50 * scaleFactor}px`, left: '80%', width: `${300 * scaleFactor}px`, type: 'solid' },
    'playgroundSandbox': { top: '10%', left: '50%', width: '100%', type: 'outline' },
    'playgroundBalance': { top: '20%', left: '50%', width: '100%', type: 'outline' },
    'playgroundCounter': { top: '20%', left: '50%', width: '100%', type: 'outline' },
    'playgroundSendToL1': { top: '20%', left: '50%', width: '100%', type: 'outline' },
    'network': { top: '20%', left: '50%', width: '100%', type: 'outline' }
  }[currentPage] || { top: '50%', left: '50%', width: '100%', type: 'outline' }; // Default style

  // // Calculate the fontSize based on the width of the logo container
  // const fontSizeFactor = 0.15; // Font size is 20% of container's width
  // const widthPercentage = parseInt(style.width, 10); // Extract the numerical part of the width
  // const calculatedFontSize = widthPercentage * fontSizeFactor; // This calculation now makes sense

  // Function to handle click on the logo
  const handleClick = () => {
    console.log('Moving to main page');
    navigate('/');
  };

  // // Font boundaries
  // const fontSizeMin = 16; // Minimum font size in px
  // const fontSizeMax = 200; // Maximum font size in px
  
  // // Calculate fontSize with boundaries
  // const limitedFontSize = `clamp(${fontSizeMin}px, ${calculatedFontSize}vw, ${fontSizeMax}px)`;  
  
  return (
    <LogoContainer ref={logoRef} style={style}>
      <StyledSvgLogo 
        textSize={calculatedFontSize} 
        type={style.type}
        isTextHovered={isTextHovered}
      >
        <text 
          x="50%" 
          y="50%" 
          dominant-baseline="middle" 
          text-anchor="middle"
          onMouseEnter={() => setIsTextHovered(true)}
          onMouseLeave={() => setIsTextHovered(false)}
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        >
          CHIPS
        </text>
        {/* Transparent overlay for click events */}
        {/* <ClickableOverlay onClick={handleClick} /> */}
        {/* <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="40" fill="yellow" stroke="black" stroke-width="10" />
        </svg> */}
      </StyledSvgLogo>
    </LogoContainer>
  );
});

export default MainLogoComponent;