import styled from 'styled-components';

export const CardsContainer = styled.div`
    height: ${({ $windowScaleFactor }) => 100 * $windowScaleFactor}px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition: transform 300ms ease 0s, opacity 300ms ease 0s;
    opacity: 1;
`;

export const CardContainer = styled.div`
    position: relative;
    width: ${({ $windowScaleFactor }) => 50 * $windowScaleFactor}px; 
    height: ${({ $windowScaleFactor }) => 80 * $windowScaleFactor}px; 
    margin: ${({ $windowScaleFactor }) => 5 * $windowScaleFactor}px; 
`;

export const CardBack = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.background}; /* or any color you want for your card */
    border-width: 0;
    border-radius: 5px; /* Adjust for desired rounding */
    outline: 2px solid ${({ theme }) => theme.textColor || 'red'};
    z-index: 15;
`;

export const CardValue = styled.div`
    font-family: 'roboto';
    font-weight: 700;
    position: absolute;
    top: 2%; /* Adjust as necessary */
    left: 12%;
    width: 100%;
    z-index: 20;
    font-size: ${({ $windowScaleFactor }) => 30 * $windowScaleFactor}px; /* Adjust as necessary */
    color: ${({ theme }) => theme.highlightedTextColor};
`;

export const CardSuit = styled.div`
    position: absolute;
    top: 45%;
    left: 30%;
    width: ${({ $windowScaleFactor }) => 30 * $windowScaleFactor}px;
    height: ${({ $windowScaleFactor }) => 30 * $windowScaleFactor}px;
    z-index: 25;
    background-image: url(${props => props.$suitPath});
    background-size: contain;  // Changed from cover to contain
    background-repeat: no-repeat;   // This ensures that the image doesn't repeat if it doesn't fill the space
    background-position: center;    // Centers the image in the div
`;