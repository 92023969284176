// src/components/Toast/styles.js
import styled from 'styled-components';

// Define styled components outside of your component
export const ToastContainer = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: ${({ isError }) => isError ? 'red' : 'rgba(0, 0, 0, 0.5)'};
  color: white;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  z-index: 1000;
`;