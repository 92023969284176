// src/components/GlobalMessageHandler/index.jsx
import React, { useEffect } from 'react';
import { useToastStore } from '../../hooks/useStores';
import SocketService from '../../services/SocketService';

const GlobalMessageHandler = ({ children }) => {
  const toastStore = useToastStore();
  
  useEffect(() => {
    // Setup listeners
    SocketService.addListener('toast', toastStore.handleSocketMessage);
    SocketService.addListener('error', toastStore.handleSocketError);
    
    // Cleanup function to remove listeners
    return () => {
      SocketService.removeListener('toast', toastStore.handleSocketMessage);
      SocketService.removeListener('error', toastStore.handleSocketError);
    };
  }, [toastStore]);
  
  return <>{children}</>;
};

export default GlobalMessageHandler;