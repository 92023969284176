// src/views/PlaygroundBalance/GetBalanceABI.ts
export const GetBalanceABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "agentID",
        type: "bytes",
      },
    ],
    name: "GotAgentID",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "baseBalance",
        type: "uint64",
      },
    ],
    name: "GotBaseBalance",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "nftBalance",
        type: "uint256",
      },
    ],
    name: "GotNFTIDs",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "nativeTokenBalance",
        type: "uint256",
      },
    ],
    name: "GotNativeTokenBalance",
    type: "event",
  },
  {
    inputs: [],
    name: "getAgentID",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getBalanceBaseTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getBalanceNFTs",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "nativeTokenID",
        type: "bytes",
      },
    ],
    name: "getBalanceNativeTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const; // Ensure the ABI is treated as deeply immutable