// src/components/Table/index.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useScale, useTableStore, useGameStore, useUserStore, useToastStore  } from '../../hooks/useStores';
import SocketService from '../../services/SocketService';
import Pot from '../../components/Pot';
import PlayerBackground from '../../components/PlayerBackground';
import BetBackground from '../../components/BetBackground';
import SeatAndPlayer from '../../components/SeatAndPlayer';
import DealerIndicator from '../../components/DealerIndicator';
import PlayerCards from '../../components/card/PlayerCards';
import { calculatePlayerPositions } from '../../utils/layoutHelpers';
import { PlayerActions, Button, GameOverlay, DiagnosticOverlay } from '../../components';
import { GameContainer, TableContainer, GooContainer, CenteringContainer,  
            BottomRight, PlayerCardsWrapper, TableCardsWrapper } from './styles';

const Table = observer(() => {
  // Stores & contexts
  const { scaleFactor, orientation, tableSize } = useScale();  // Use the hook to get the scaleFactor
  const tableStore = useTableStore();
  const gameStore = useGameStore();
  const userStore = useUserStore();
  const toastStore = useToastStore();
  const { tableNumber } = useParams();
  
  // Navigation
  const navigate = useNavigate();
  
  // Display
  const [showDiagnostic, setShowDiagnostic] = useState(false);
  
  // Game Variables
  const tableCards = gameStore.gameState && gameStore.gameState.tableCardsRevealed ? gameStore.gameState.tableCardsRevealed : [];
  const activePlayerSeat = gameStore.gameState.activePlayerSeat ? gameStore.gameState.activePlayerSeat : null;
  const bigBlind = gameStore.gameState.bigBlind ? gameStore.gameState.bigBlind : null;
  const rawPlayerCount = gameStore.tableData ? gameStore.tableData.seats.length : 0;  // Ensure gameStore.tableData is not null before accessing its properties
  const playerCount = Math.max(rawPlayerCount, 6); // Ensure at least 6 seats
  
  // Scaling variables
  const gameMainRef = useRef(null); // Add this ref near your existing refs
  const initialPositions = new Array(playerCount).fill().map(() => ({ left: '0px', top: '0px' }));
  const [playerPositions, setPlayerPositions] = useState(initialPositions);
  const tableRadius = 170; 
  
  // Toggles
  const toggleDiagnostic = () => {setShowDiagnostic(prev => !prev)};
  
  // Player location
  const findUserSeatIndex = () => {
    if(tableStore.tableData) {
      for(let i = 0; i < tableStore.tableData.seats.length; i++) {
        //console.log(`Checking seat index ${i}:`, gameStore.tableData.seats[i]);
        if(tableStore.tableData.seats[i].userId && tableStore.tableData.seats[i].userId._id === userStore.userId) {
          //console.log(`Found user at seat index ${i}`);
          return i;
        }
      }
    }
    // console.log(`User not found in any seats`);
    return -1;
  };
  
  const userIsSeated = findUserSeatIndex() !== -1;
  
  const rearrangeSeats = (seats, index) => {
    if(index >= 0 && index < seats.length) {
      let newSeats = [...seats];
      for(let i = 0; i < seats.length; i++) {
        newSeats[i] = seats[(index + i) % seats.length];
      }
      return newSeats;
    }
    return seats;
  };
  
  // Methods
  const handleStartNewGame = () => {
    const tableId = tableStore.tableData._id;
    //console.log('Sending newgame event to GameStore from App to be started at table id:', tableId);
    gameStore.startNewGame(tableId); 
  };
  
  const handleTakeSeatClick = (seat) => {
    console.log('User', userStore.userId, 'taking a seat...', );
    const tableId = tableStore.tableData._id;
    console.log('table id:', tableId); 
    const selectedSeat = seat.seatId
    console.log('seat id:', selectedSeat);
    SocketService.emit('takeSeat', { tableId, seatId: selectedSeat, userId: userStore.userId });
  }
  
  const handleLeaveTable = () => {
    // Emit leaveTable event to the server with table ID and user ID
    SocketService.emit('leaveTable', { tableId: tableStore.tableData._id, userId: userStore.userId });
    
    // Show toast notification and navigate to lobby
    toastStore.displayToast('You have left the table.');
    //navigate('/lobby'); // Redirect the user to the lobby or another appropriate path
  };
  
  ////////////////// Dealer position /////////////////////////
  
  const [dealerPosition, setDealerPosition] = useState({ x: 0, y: 0 });
  
  const calculateDealerPosition = () => {
    const userSeatIndex = findUserSeatIndex(); // Index of the user in the original seating arrangement
    const dealerSeatNumber = tableStore.tableData.dealer;
    
    // Rearrange seats to find the new index of the dealer after the seats are rearranged
    const rearrangedSeats = rearrangeSeats(tableStore.tableData.seats, userSeatIndex);
    
    // Use the original seat at the dealer position to find its new index in the rearranged array.
    const dealerOriginalSeat = tableStore.tableData.seats[dealerSeatNumber -1];
    const dealerIndexInRearranged = rearrangedSeats.findIndex(seat => seat === dealerOriginalSeat);
    
    // Helper function to convert degrees to radians
    const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;
    
    // Define how much you want to rotate the dealer indicator around the table center from the dealer's position
    const rotationDegrees = 25; 
    const rotationRadians = degreesToRadians(rotationDegrees);
    const startAngle = Math.PI / 2; // Start from the bottom
    
    // Assuming you have the total number of players to calculate the angle step per player
    const anglePerSeat = (2 * Math.PI) / playerCount; // Angle step per player
    
    // Calculate the adjusted angle for the dealer indicator
    const dealerAngle = anglePerSeat * dealerIndexInRearranged + rotationRadians + startAngle;
    
    // Calculate adjusted X and Y positions using the scaled radius
    const adjustedDealerX = tableRadius * Math.cos(dealerAngle) * scaleFactor;
    const adjustedDealerY = tableRadius * Math.sin(dealerAngle) * scaleFactor;
    
    return {x: adjustedDealerX, y: adjustedDealerY};
  };
  
  /////////////////////////////////////////////////////////////
  
  if (userStore.isLoadingToken) {
    return <div>Verifying token...</div>;
  } else if (!userStore.user.username) {
    navigate("/login", { replace: true });
    return null;
  }
  
  // Simplified method to get a player for a given seat
  const getPlayerForSeat = (seat) => {
    if (!seat.userId || !gameStore.gameState || !Array.isArray(gameStore.gameState.players)) {
      return null;
    }
    return gameStore.gameState.players.find(player => player.user === seat.userId._id);
  };
  
  // Effect to update player and dealer positions based on scale factor and table data
  useEffect(() => {
    // Update player positions based on the current scale factor
    const newPlayerPositions = calculatePlayerPositions({
      tableRadius: tableRadius, 
      playerCount: playerCount, 
      scaleFactor: scaleFactor, 
    });
    setPlayerPositions(newPlayerPositions);
    
    // Update dealer position if table data is available and conditions are met
    if (tableStore.tableData ) {
      const dealerPos = calculateDealerPosition(); 
      setDealerPosition(dealerPos);
    }
  }, [scaleFactor, tableStore.tableData]);
  
  // Setup and cleanup of socket listeners
  useEffect(() => {   
    // Add the event listener for table data
    SocketService.addListener('tableData', tableStore.handleTableData);
    SocketService.addListener('gameState', gameStore.handleGamestate);
    SocketService.addListener('countdown', gameStore.handleCountdown); 
    SocketService.addListener('gameEnd', gameStore.handleGameEnd);
    
    // Fetch table data and join table room
    if (tableNumber) {
      tableStore.joinTable(tableNumber);  // Call fetchAllTables when the component mounts
    }
    
    return () => {
      // Remove the event listener when the component unmounts
      SocketService.removeListener('tableData', tableStore.handleTableData);
      SocketService.removeListener('gameState', gameStore.handleGamestate);
      SocketService.removeListener('countdown', gameStore.handleCountdown); 
      SocketService.removeListener('gameEnd', gameStore.handleGameEnd);
    };
  }, []);
  
  return (   
    // Main container wrapping table, overlays, cards and buttons.
    <GameContainer ref={gameMainRef} >
      {/* Table container contains the seats and pots split between two child containers */}
      {/* The second container shows the fluid movement of tokens between players */}
      <TableContainer key={orientation} size={tableSize} orientation={orientation}>
        <CenteringContainer>
          {/* Render the DealerIndicator here, outside the map function */}
          {dealerPosition.x !== 0 && dealerPosition.y !== 0 && (
            <DealerIndicator position={dealerPosition} />
          )}
          {tableStore.tableData && rearrangeSeats(tableStore.tableData.seats, findUserSeatIndex()).map((seat, index) => {
            const gamePlayer = getPlayerForSeat(seat);
            return (
              <SeatAndPlayer 
                key={seat.seatId} // Unique key for React list rendering optimization
                handleTakeSeatClick={handleTakeSeatClick} 
                seat={seat}
                seatId={seat.seatId}  
                seatIndex={index} 
                playerPosition={playerPositions[index]}  
                user={seat.userId}     
                isSignedInPlayer={seat.userId === userStore.userId} // pass a boolean value indicating whether this player is the current user    
                player={gamePlayer? gamePlayer : 'None'} 
                activePlayerSeat={activePlayerSeat}
                bigBlind={bigBlind} 
                countdown={gameStore.countdown}
                countdownPercentage={gameStore.countdownPercentage}
                cards={seat.userId && seat.userId._id === userStore.userId ? gameStore.playerCardsDealt : gamePlayer? gamePlayer.cardsRevealed : []}
                scaleFactor={scaleFactor}
                tableSize={tableSize}
                isUserSeated={userIsSeated}
              />
            );
          })}
        </CenteringContainer>
        <GooContainer>
          <Pot scaleFactor={scaleFactor}/>
          <CenteringContainer>
            {tableStore.tableData && rearrangeSeats(tableStore.tableData.seats, findUserSeatIndex()).map((seat, index) => {
              const gamePlayer = getPlayerForSeat(seat);
              return (
                <PlayerBackground 
                  key={`player-background-${seat.seatId}`} // Modified key to include component type
                  seat={seat} 
                  seatIndex={index} 
                  playerPosition={playerPositions[index]}  
                  user={seat.userId}     
                  player={gamePlayer? gamePlayer : 'None'} 
                  activePlayerSeat={activePlayerSeat} 
                  scaleFactor={scaleFactor}
                />
              );
            })}
          </CenteringContainer>
          <CenteringContainer>
            {tableStore.tableData && rearrangeSeats(tableStore.tableData.seats, findUserSeatIndex()).map((seat, index) => {
              const gamePlayer = getPlayerForSeat(seat);
              return (
                <BetBackground 
                  key={`bet-background-${seat.seatId}`} // Modified key to include component type 
                  seatIndex={index} 
                  playerPosition={playerPositions[index]}  
                  user={seat.userId}     
                  player={gamePlayer? gamePlayer : 'None'} 
                  scaleFactor={scaleFactor}
                  tableSize={tableSize}
                />
              );
            })}
          </CenteringContainer>
        </GooContainer>
      </TableContainer>
      <GameOverlay scaleFactor={scaleFactor}/>
      {gameStore.gameState && gameStore.gameState.players.map((player) => {
        if(player.user === userStore.user._id) {
          return (
            <PlayerCardsWrapper orientation={orientation} key={player}>
              <PlayerCards cards={player.cardsDealt} windowScaleFactor={scaleFactor}/>
            </PlayerCardsWrapper>
          );
        }
      })}
      <TableCardsWrapper orientation={orientation}> 
        <PlayerCards cards={gameStore.gameState.tableCardsRevealed} windowScaleFactor={scaleFactor}/>
      </TableCardsWrapper>
      {showDiagnostic && (
        <DiagnosticOverlay toggleDiagnostic={toggleDiagnostic} />
      )}
      <BottomRight $gameStore={tableStore} $windowScaleFactor={scaleFactor}>
        {/* Buttons displayed if user is seated at the table and table is inactive */}
        {tableStore.tableData && 
        !tableStore.tableData.tableCurrentGame &&
        tableStore.tableData.seats.some(seat => seat.userId && seat.userId._id === userStore.userId) && (
          <div>
            <Button onClick={handleStartNewGame}>Start new game at this table</Button>
            {/* Add leave table button next to start new game button */}
            <Button onClick={handleLeaveTable}>Leave Table</Button>
          </div>
        )}                
        {tableStore.tableData && tableStore.tableData.seats.map((seat) => {
            if(seat.userId && seat.userId._id === userStore.userId) {
              const playerInfo = gameStore.gameState.players.find(p => p.user === seat.userId._id);
              let isActivePlayer = (activePlayerSeat && seat.seatId === activePlayerSeat) ? true : false;
              return (
                <PlayerActions
                  key={seat.seatId}
                  isActivePlayer={isActivePlayer}
                  windowScaleFactor={scaleFactor}
                  playerInfo={playerInfo}
                  playerChips={playerInfo ? playerInfo.chips : 0}
                />
              );
            }
            return null;
          })
        }
      </BottomRight>
    </GameContainer>
  );
});

export default Table;