import { makeAutoObservable, action, runInAction } from 'mobx';
import SocketService from '../services/SocketService';
import userStore from './userStore';
import tableStore from './tableStore';
import toastStore from './toastStore';

class GameStore {
  // state variables ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  gameState = { players: [] }; // This will hold the current game object
  countdown = null;
  countdownPercentage = null;
  tableCards = []; 
  playerCardsDealt = []; 
  debug = false; // Debug flag
  
  constructor() {
    makeAutoObservable(this);
  }
  
  log(...args) { // Logging method
    if (this.debug) {
      console.log(...args);
    }
  }
  
  error(...args) { // Error logging method
    if (this.debug) {
      console.error(...args);
    }
  }
  
  // Actions ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  check = () => {
    this.log("Player checking");
    this.log("userId before sending:", userStore.userId);
    this.log("checking", tableStore.tableData._id, this.gameState._id, userStore.userId, 'check');
    SocketService.emit('playerAction', {
      tableId: tableStore.tableData._id, 
      gameId: this.gameState._id, 
      userId: userStore.userId, 
      action: 'check'
    });
  }
  
  call = () => {
    this.log("Player calling");
    this.log("calling", tableStore.tableData._id, this.gameState._id, userStore.userId, 'call');
    SocketService.emit('playerAction', {
      tableId: tableStore.tableData._id, 
      gameId: this.gameState._id, 
      userId: userStore.userId, 
      action: 'call'
    });
  }
  
  bet = (amount) => {
    this.log("Player betting", amount);
    SocketService.emit('playerAction', {
      tableId: tableStore.tableData._id, 
      gameId: this.gameState._id, 
      userId: userStore.userId, 
      action: 'bet',
      amount: amount,
    });
  }
  
  raise = (amount) => {
    this.log("Player raising", amount);
    this.log("playerAction", tableStore.tableData._id, this.gameState._id, userStore.userId, 'raise', amount);
    SocketService.emit('playerAction', {
      tableId: tableStore.tableData._id, 
      gameId: this.gameState._id, 
      userId: userStore.userId, 
      action: 'raise',
      amount: amount,
    });
  }
  
  fold = () => {
    this.log("Player folding");
    SocketService.emit('playerAction', {
      tableId: tableStore.tableData._id, 
      gameId: this.gameState._id, 
      userId: userStore.userId, 
      action: 'fold'
    });
  }
  
  startNewGame = ( tableId ) => {
    //this.log('New game request sent to the server from gameStore by', userStore.userId, 'to be started at table ', tableId);
    // Include the user's ID when emitting the 'newgame' event
    SocketService.emit('newgame', { tableId, userId: userStore.userId });
    this.tableCards = [];
  };
  
  get playerList() {
    // compute the player list from the players state
    if(this.players) {
      return this.players.map(player => player.username);  // assuming 'username' is the property in User schema
    } else {
      return [];  // return an empty array if players is undefined
    }
  }
  
  // Socket event handlers /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  handleGamestate = (game) => {
    this.log("recieved something from server")
    runInAction(() => {
      if (game) {
        this.gameState = game;
        
        // Log the entire game state for debugging
        this.log("Received gameState:", game);
      }
    });
  }
  
  handleGameEnd = action(() => {
    // Reset the game state
    this.gameState = { players: [] };
    
    // Reset other variables if needed
    tableStore.tableData.tableCurrentGame = null;
    
    // Fetch updated table data from the server
    tableStore.fetchAllTables();
    
    // Show a toast message to inform the user
    toastStore.displayToast("Game has ended. Waiting for a new game to start...");
  });
  
  handleMessage = action((data) => {
    if (typeof data === 'string') {
      toastStore.displayToast(data);
    } else if (data && typeof data === 'object') {
      if (data.success) {
        this.log(data.message);
        toastStore.displayToast(data.message);
      } else if (data.error) {
        this.error(data.error);
        toastStore.displayToast(data.error);
      }
    }
  });
  
  handleUpdateGames = (games) => {
    const sortedData = games.sort((a,b) => a.table.tableNumber - b.table.tableNumber); // Sort by table number
    const activeGames = sortedData.filter(game => game.isActive);
    const inactiveGames = sortedData.filter(game => !game.isActive);
    this.setGames(activeGames);
    this.setInactiveGames(inactiveGames); // Set inactive games
  }
  
  handleSeatTaken = (data) => {
    runInAction(() => {
      this.tableData = data.table;
    });
  }
  
  handlePlayerTurn = (player) => {
    this.activePlayer = player;
  };
  
  handleCountdown = (countdown, countdownPercentage) => {
    this.countdown = countdown;  // This will update the countdown observable every second
    this.countdownPercentage = countdownPercentage;
  }
  
  handleDeleteGame = (gameId) => {
    SocketService.emit('deletegame', gameId);
  }
  
  setGames = (games) => {
    this.games = games;
  }
  
  setInactiveGames = (inactiveGames) => {
    this.inactiveGames = inactiveGames;
  }
}

const gameStore = new GameStore();
export default gameStore;