// src/views/PlaygroundSandbox/components/TakeAllowedFunds.tsx
import React, { useState } from 'react';
import { WritableContractProps } from '@isc/types';
import { L1Address, ISCAssets } from '@isc/types';
import { chips } from '@isc/client/chipsChain';
import Button from '@components/ButtonWithMargins';
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from '../styles';

/**
 * Component that handles calling the `takeAllowedFunds` function from the ISC contract.
 * 
 * @param contract The ISC contract object with address and ABI.
 * @param account The connected user's account address.
 * @param walletClient The wallet client for contract interaction.
 */
const TakeAllowedFunds: React.FC<WritableContractProps> = ({ contract, account, walletClient }) => {
  const [output, setOutput] = useState<any>(null);
  const [target, setTarget] = useState<string>('');
  const [baseTokens, setBaseTokens] = useState<string>('');

  /**
   * Calls the `takeAllowedFunds` function from the ISC contract.
   * 
   * This function interacts with the contract using the provided wallet client,
   * account, and contract details. It assumes that the user has already authorized
   * the caller to take funds.
   */
  const handleTakeAllowedFunds = async () => {
    try {
      const iscAssets: ISCAssets = {
        baseTokens: typeof baseTokens === 'string' ? BigInt(baseTokens) : baseTokens,
        nativeTokens: [], // Assuming no native tokens are involved
        nfts: [], // Assuming no NFTs are involved
      };
      
      // Check if account and walletClient are defined before using them
      if (!account || !walletClient) {
        throw new Error("Account or wallet client is missing.");
      }
      
      const result = await walletClient.writeContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'takeAllowedFunds',
        args: [target, iscAssets] as const,
        account,
        chain: chips, // Add the chain parameter
      });
      
      setOutput(result);
      console.log(`takeAllowedFunds result:`, result);
    } catch (error) {
      console.error(`Failed to call takeAllowedFunds`, error);
    }
  };
  
  return (
    <OperationContainer>
      <StyledHeading>Take Allowed Funds</StyledHeading>
      <FlexContainer>
        <OutlinedTextField
          label="Target (address)"
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          supportingText="* Required"
        />
        <OutlinedTextField
          label="Base Tokens (uint64)"
          value={baseTokens}
          onChange={(e) => setBaseTokens(e.target.value)}
          supportingText="* Required"
        />
      </FlexContainer>
      <Button onClick={handleTakeAllowedFunds}>Call Take Allowed Funds</Button>
      {output && (
        <div>
          <h3>Output:</h3>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </div>
      )}
    </OperationContainer>
  );
};

export default TakeAllowedFunds;