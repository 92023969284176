// src/components/PlaygroundContainer/index.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../ButtonWithMargins'; // Adjust the import path as needed
import { Container, PlaygroundBar } from './styles';

const PlaygroundContainer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <h3>Smart Contract Playground</h3>
      <PlaygroundBar>
        <Button onClick={() => navigate('/network')}>Network Switcher</Button>
        <Button onClick={() => navigate('/playgroundCounter')}>Counter</Button>
        <Button onClick={() => navigate('/playgroundBalance')}>Balance</Button>
        <Button onClick={() => navigate('/playgroundSandbox')}>Sandbox</Button>
        <Button onClick={() => navigate('/playgroundSendToL1')}>Send to L1</Button>
      </PlaygroundBar>
    </Container>
  );
};

export default PlaygroundContainer;