// src/components/SeatAndPlayer/styles.js

import styled, { css, keyframes } from 'styled-components';

// Keyframes for animations
const dropInAnimation = keyframes`
  0% { transform: translateY(-50px); }
  100% { transform: translateY(0px); }
`;

// Styled Components

export const ActiveOrFoldedContainer = styled.div`
  transition: z-index 300ms ease 300ms;
  opacity: ${({ active, folded }) => (!active || folded) ? '0.5' : '1'};
  position: absolute;
  z-index: 30;
`;

export const SeatNumberCircle = styled.span`
  position: absolute;
  top: ${({ scaleFactor }) => `calc(-30px * ${scaleFactor || 1})`};
  align-items: center;
  //background: rgb(255, 83, 91);
  background: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.5);
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
`;

export const SeatIDText = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  text-align: center;
`;

export const PlayerName = styled.div`
  position: absolute;
  display: flex;
  align-items: center; // Has no effect when display: block (default)
  justify-content: center;
  top: ${({ scaleFactor }) => `calc(-22px * ${scaleFactor || 1})`};
  left: 0px;
  height: 20px;
  width: 80px;
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  z-index: 35;
`;
export const PlayerAction = styled.div`
  align-self: flex-end;
  left: 0px;
  position: absolute;
  right: 0px;
  ${deviceClass => deviceClass === 'compact' ? css`bottom: 2px;` : css`bottom: 4px;`}
`;

export const PlayerChips = styled.div`
  position: absolute;
  align-items: center;
  display: flex;
  flex-direction: row;
  top: ${({ scaleFactor }) => `calc(0px * ${scaleFactor || 1})`};
  height: 28px;
  justify-content: flex-start;
  left: 0%;
  min-width: 100%;
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  z-index: 40;

  //background: none;
  //border-radius: 50%;
  //bottom: auto;
  //box-shadow: rgba(0, 0, 0, 0.5) 0px 7px 10px -3px;
  //color: rgb(255, 255, 255);
  //cursor: default;
`;

export const Text = styled.span`
  //color: rgb(0, 0, 0);
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 1px;
  padding-right: 2px;
  text-align: center;
`;

export const TextLarge = styled(Text)`
  font-size: 16px;
`; 

////////////

export const ContainerTimerAction = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  text-transform: uppercase;
  z-index: 40;
  height: 26px;
  width: 80px;
  top: ${({ scaleFactor }) => `calc(19px * ${scaleFactor || 1})`};
  left: 0%;
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  transition: opacity 300ms ease 0ms, max-height 300ms ease 0ms, min-width 300ms ease, height 300ms ease;
  //opacity: 1;
  //max-height: 36px;
  //background: rgb(0, 201, 183);
  // border-radius: 4px 4px 6px 6px;
  //box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 6px -5px;
  //color: rgb(255, 255, 255);
  //cursor: default;
  //font-weight: 700;
  //font-size: 12px;
  //min-width: 100%;
  //overflow: hidden;
`;

export const TimerFullwidth = styled.div`
  opacity: '1';
  transition: 'opacity 600ms ease 0s';
  align-self: flex-end;
  left: 0px;
  position: absolute;
  right: 0px;
  ${deviceClass => deviceClass === 'compact' ? css`bottom: 2px;` : css`bottom: 4px;`}
`;

export const TimerTimeBar = styled.div`
  align-items: center;
  //color: rgb(255, 255, 255);
  display: flex;
  font-size: 10px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
`;

export const TimerTime = styled.div`
  height: 12px;
  flex: 0 0 auto;
  margin-right: 6px;
  min-width: 12px;
  text-align: right;
`;

export const TimerBarContainer = styled.div`
  backface-visibility: hidden;
  transform: translate3d(0px, 0px, 0px);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 6px;
  flex: 1 1 0px;
  overflow: hidden;
  ${deviceClass => deviceClass === 'compact' ? css`height: 4px; width: 56px;` : css`height: 6px; width: 77px;`}
`;

export const TimerBar = styled.div`
  position: relative;
  right: 100%;
  transform: translate(100%);
  transition: transform 1s linear 0s;
  width: 100%;
  border-radius: ${deviceClass => deviceClass === 'compact' ? '4px' : '6px'};
  height: ${deviceClass => deviceClass === 'compact' ? '4px' : '6px'};
  background-color: rgb(255, 255, 255);
`;

export const CurrentBetContainer = styled.div`
  position: absolute;
  transition: bottom 300ms ease 0s, left 300ms ease 0s, transform 300ms ease 0s;
  visibility: visible;
  will-change: opacity;
  z-index: 201;
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  display: flex;
`;

export const CoinSymbol = styled.div`
  color: ${({ theme }) => theme.background || 'red'}; // Or any color you prefer
`;

export const CurrentBetAmount = styled.div`
  color: ${({ theme }) => theme.background || 'red'}; // Or any color you prefer
`;


export const Seat = styled.div`
  position: absolute;
  z-index: 15;
  width: ${({ isOccupied }) => isOccupied ? '100px' : '87px'};
  height: ${({ isOccupied }) => isOccupied ? '100px' : '87px'};
  //background-color: ${({ isOccupied, theme }) => isOccupied ? theme.playerBackground : theme.emptySeatBackground};
  background-color: ${({ isOccupied, theme }) => isOccupied ? theme.background : theme.background};
  box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.5);
  box-shadow: ${({ theme }) => `0px 0px 0px 1px ${theme.textColor}`};
  //border: ${({ isOccupied, theme }) => isOccupied ? 'none' : `1px solid ${theme.textColor}`};
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: ${({ isOccupied }) => isOccupied ? 'default' : 'pointer'};
  pointer-events: ${({ isOccupied }) => isOccupied ? 'none' : 'auto'};
  
  &:hover {
    width: '100px';
    height: '100px';
  }
  
  /* ${({ isOccupied }) => !isOccupied && css`
    &:hover {
      background-color: ${({ theme }) => theme.emptySeatHoverBackground};
    }
  `} */
`;


// Styled component for the 'Take Seat' button
export const TakeSeatButton = styled.button`
  color: ${({ theme }) => theme.textColor || 'red'};
  background-color: ${({ theme }) => theme.background || 'red'};
  position: absolute;
  z-index: 35; /* Ensure it's above other elements */
  pointer-events: auto; // Allow the button to be clickable
  width: 100%; // Take full width of the parent
  height: 100%; // Take full height of the parent
  border-radius: 50%; // Maintain circular shape if desired
  display: flex; // Use flexbox for centering
  flex-direction: column; // Stack text lines
  justify-content: center; // Center content vertically
  align-items: center; // Center content horizontally
  text-align: center; // Center text lines
  padding: 0; // Remove padding to allow text to take up space as needed
  font-size: 0.9rem; // Adjust font size as needed
  line-height: 1.2; // Adjust line height for better readability
  white-space: normal; // Override any parent settings that might prevent wrapping
`;