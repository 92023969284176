// src/styles.js
import styled, { keyframes } from 'styled-components';

// Define the drawer width here to use in multiple styled components
const LEFT_DRAWER_WIDTH = '500px';
const RIGHT_DRAWER_WIDTH = '500px';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const AppContainer = styled.div`
  position: absolute;
  overflow: hidden;
  //height: ${({ height }) => height}px; // Dynamic height
  height: 100dvh; // dynamic
  width: 100dvw; // dynamic
  display: flex;
  flex-direction: row;
  transition: transform 0.3s ease-in-out;
`;

export const LeftDrawer = styled.div`
  width: ${LEFT_DRAWER_WIDTH};
  position: absolute;
  left: ${({ isNavOpen }) => (isNavOpen ? '0' : `-${LEFT_DRAWER_WIDTH}`)};
  transition: left 0.3s ease-in-out;
  z-index: 200;  // Ensure it overlays other content as needed
  height: 100%;  // Adjust height as necessary
`;

export const CentralContentContainer = styled.div`
  position: relative;
  flex-grow: 1; // Fills remaining space
  height: 100%; 
  max-height: 100%;
  // Apply margin adjustments unless the device is compact
  ${({ isNavOpen, isChatOpen, deviceClass }) => deviceClass !== 'compact' && `
    margin-left: ${isNavOpen ? LEFT_DRAWER_WIDTH : '0px'};
    margin-right: ${isChatOpen ? RIGHT_DRAWER_WIDTH : '0px'};
  `}

  ${({ deviceClass }) => deviceClass === 'compact' && `
    width: 100%; // Keep width full on compact devices
    margin-left: 0; // No margin adjustment on compact devices
    margin-right: 0; // No margin adjustment on compact devices
  `}
  //${({ deviceClass }) => deviceClass === 'compact' && `width: 100dvw`}
  transition: all 0.3s ease-in-out; // Smooth transitions for any changes
  
  /* Position content in the middle */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 0;
`;

export const RightDrawer = styled.div`
  width: ${RIGHT_DRAWER_WIDTH};
  position: absolute;
  right: ${({ isChatOpen }) => (isChatOpen ? '0' : `-${RIGHT_DRAWER_WIDTH}`)};
  transition: right 0.3s ease-in-out;
  z-index: 150;  // Ensure it overlays other content as needed
  height: 100%;  // Adjust height as necessary
`;

export const AccountIconContainer = styled.div`
  position: absolute;
  top: 20px; // Adjust as needed for your layout
  left: 20px; // Adjust as needed for your layout
  z-index: 55; // Ensure it's above other elements
`;

export const ChatIconContainer = styled.div`
  position: absolute;
  top: 20px; // Adjust as needed for your layout
  right: 20px; // Adjust as needed for your layout
  z-index: 55; // Ensure it's above other elements
`;

// Base Icon Component
const IconBase = styled.span`
  font-family: 'Material Symbols Outlined';
  font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24;
  font-size: 2.5rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.textColor};
  transition: all 300ms ease-in-out;
  cursor: pointer; // Makes it clear it's clickable
  &:hover {
    color: ${({ theme }) => theme.highlightedTextColor || 'red'};
    font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 24;
  }
`;

// Specific Icon Components
export const AccountIcon = styled(IconBase)`
  &::before {
    content: 'account_circle';
  }
`;

export const ChatIcon = styled(IconBase)`
  &::before {
    content: 'chat';
  }
`;



export const Scrim = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 100; // Ensure it overlays other content
  transition: opacity 0.3s ease, backdrop-filter 0.3s ease; // Smooth transitions
  backdrop-filter: ${({ isVisible }) => isVisible ? 'blur(10px)' : 'blur(0px)'};
  opacity: ${({ isVisible }) => isVisible ? 1 : 0};
  pointer-events: ${({ isVisible }) => isVisible ? 'auto' : 'none'}; // Ensures that when not visible, it doesn't interfere with clicks
`;