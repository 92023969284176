// src/components/DiagnosticOverlay/index.jsx
import { useTableStore, useGameStore, useUserStore  } from '../../hooks/useStores';
import PlayerCards from '../card/PlayerCards';
import { DiagnosticOverlayContainer, SeatInfoContainer } from './styles';
import Button from '../Button';

const DiagnosticOverlay = ({ toggleDiagnostic }) => {
  const tableStore = useTableStore();
  const gameStore = useGameStore();
  const userStore = useUserStore();
  
  return (
    <DiagnosticOverlayContainer>
      <Button onClick={toggleDiagnostic} style={{ position: 'absolute', top: '10px', right: '50px' }}>Close</Button>
      <h3>Diagnostic Data</h3>
      <p>Logged-in User <strong> {userStore.user.username} </strong></p>
      <p> ({userStore.userId})</p>
      <p> ({userStore.user._id})</p>
      <p><strong>Player Cards Dealt:</strong></p>
      <PlayerCards cards={gameStore.playerCardsDealt} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {gameStore.tableData && gameStore.tableData.seats.map((seat) => (
          <SeatInfoContainer 
            key={seat.seatId} 
            isActiveUser={seat.userId && seat.userId._id === userStore.userId}
            userId={seat.userId}
          >
            <p><strong>Seat Number:</strong> {seat.seatId ? seat.seatId : "No seat ID"}</p>
            <p><strong>Player:</strong> {seat.userId && seat.userId.username ? seat.userId.username : "No username"}</p>
            <p><strong>Seat User ID:</strong> {seat.userId ? seat.userId._id : "No User ID for this seat"}</p>
            <p><strong>Player Cards Dealt:</strong> {JSON.stringify(gameStore.playerCardsDealt)}</p>
            <div>
              <strong>Table Data (for this seat):</strong> 
              {JSON.stringify(seat).split(',').map((segment, index, array) => 
                <span key={index}>
                  {segment}
                  {index !== array.length - 1 && <><br /></>}
                </span>
              )}
            </div>
          </SeatInfoContainer>
        ))}
      </div>
      <p>{JSON.stringify(gameStore.tableCardsRevealed)}</p>
      <p>
        {/* <pre>{JSON.stringify(gameStore, null, 2)}</pre> */}
        <pre>{JSON.stringify(gameStore)}</pre>
      </p>
      <p>
        {/* <pre>{JSON.stringify(gameStore, null, 2)}</pre> */}
        <pre>{JSON.stringify(tableStore)}</pre>
      </p>
    </DiagnosticOverlayContainer>
  );
}

export default DiagnosticOverlay;