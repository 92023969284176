// src/components/SeatAndPlayer/index.jsx

import React from 'react';
import { motion } from 'framer-motion';
import { useElevation } from '../../hooks/useElevation';
import { useAnimatedNumber } from '../../hooks/useAnimatedNumber';
import { useDeviceClass } from '../../hooks/useDeviceClass';
import { observer } from "mobx-react-lite";
import PlayerCards from '../card/PlayerCards';
import { ActiveOrFoldedContainer, 
          PlayerAction,
          PlayerName, 
          PlayerChips, 
          TakeSeatButton, 
          ContainerTimerAction, 
          TimerFullwidth, 
          TimerTimeBar, 
          TimerTime, 
          TimerBarContainer, 
          TimerBar,
          CurrentBetContainer,
          CoinSymbol,
          CurrentBetAmount,
          Seat,
          SeatNumberCircle,
          TextLarge,
          SeatIDText } from './styles';

const SeatAndPlayer = observer(({ 
    handleTakeSeatClick, 
    seat,
    seatId, 
    seatIndex, 
    playerPosition,
    user, 
    isSignedInPlayer,
    player, 
    activePlayerSeat, 
    countdown, 
    countdownPercentage, 
    cards, 
    scaleFactor,
    isUserSeated
  }) => {
  
  const deviceClass = useDeviceClass();
  const isOccupied = !!user; // Convert user presence to a boolean
  
  // Use the useElevation hook to get the elevation style for elevation level 5
  //const elevationStyle = useElevation({ level: 5 });
  // const elevationStyle = useElevation({ dp: 24 });
  // const elevationStyle = useElevation({ level: 5, dp: undefined });
  // const elevationStyle = useElevation({ level: 5 }); // Or { dp: 24 }
  
  // Use the useElevation hook to calculate elevation based on active state
  const playerElevation = useElevation({ level: player.active ? 5 : 0 });
  
  // Use containerCenter prop directly to calculate betPosition
  const betPosition = {
    left: `${parseInt(playerPosition.x, 10) / 2}px`,
    top: `${parseInt(playerPosition.y, 10) / 2}px`,
  };
  
  if (!player) {
    return (
      <div className={`player-area player-${seatId}`}>
        <span>Issue rendering player</span>
      </div>
    )
  }
  
  const animatedRoundBet = useAnimatedNumber(player.totalRoundBet || 0, 500);
  
  // Conditional rendering based on animatedRoundBet
  const shouldShowBet = animatedRoundBet > 0;
  
  // Render the CurrentBetContainer only if shouldShowBet is true
  const renderCurrentBetContainer = () => {
    if (shouldShowBet) {
      return (
        <CurrentBetContainer left={betPosition.left} top={betPosition.top} scaleFactor={scaleFactor}>
          <CoinSymbol>$</CoinSymbol>
          <CurrentBetAmount>{animatedRoundBet}</CurrentBetAmount>
        </CurrentBetContainer>
      );
    }
    return null;
  };
  
  const isActivePlayer = (activePlayerSeat && player.seat === activePlayerSeat) ? true : false;
  
  // Check if seatIndex is valid, if not, return null or a placeholder
  if (seatIndex < 0 || seatIndex > 5 || seatIndex === undefined) {
      console.error(`Invalid seatIndex: ${seatIndex}`);
      return null; // or a placeholder component
  }
  
  return (
    <>
      {/*  Logic for a taken seat */}
      <motion.div
        initial={{ x: playerPosition.x, y: playerPosition.y }} // Component's initial position 
        animate={{ 
          x: playerPosition.x, 
          y: playerPosition.y,
        }}
        transition={{ type: "spring", stiffness: 100, damping: 10 }}
        style={{
          position: 'absolute',
          transform: `translate(-50%, -50%)`,
          zIndex: 15,
        }}
      >
        <Seat
          id={`SeatCircle-${seat.seatId}`} 
          scaleFactor={scaleFactor} 
          isOccupied={isOccupied}
          isSignedInPlayer={isSignedInPlayer}
          elevation={playerElevation}
        >
          {isOccupied ? (
            <>
              <ActiveOrFoldedContainer active={player.active} folded={player.folded}>
                {deviceClass === 'compact' && (
                  // <SeatNumberCircle2 scaleFactor={scaleFactor}>
                  <SeatNumberCircle>
                    <SeatIDText>{seatId}</SeatIDText>
                  </SeatNumberCircle>
                )}
                {deviceClass !== 'compact' && (
                  //<PlayerName scaleFactor={scaleFactor}> 
                  <PlayerName> 
                    {user.username ? user.username : 'player'} 
                  </PlayerName>
                )}
                {/* <PlayerChips scaleFactor={scaleFactor}> */}
                <PlayerChips >
                  <TextLarge>{user.chips}</TextLarge>
                </PlayerChips>
                {/* <ContainerTimerAction scaleFactor={scaleFactor}> */}
                <ContainerTimerAction >
                  {!isActivePlayer && (
                    <PlayerAction
                      key="playerAction"
                      style={{
                        opacity: '1',
                        transition: 'opacity 600ms ease 0s'
                      }}
                    >
                      <div style={{ textAlign: 'center' }}>
                        {player.action ? player.action : ''}
                      </div>
                    </PlayerAction>
                  )}
                  {isActivePlayer && (
                    <TimerFullwidth deviceClass={deviceClass}>
                      <TimerTimeBar>
                        <TimerTime>{countdown !== null ? countdown : ''}</TimerTime>
                        <TimerBarContainer deviceClass={deviceClass}>
                          <TimerBar 
                            deviceClass={deviceClass}
                            style={{ transform: `translateX(${countdownPercentage}%)` }}
                          />
                        </TimerBarContainer>
                      </TimerTimeBar>
                    </TimerFullwidth>
                  )}
                </ContainerTimerAction>
                {/* <PlayerCards
                  cards={cards}
                  playerIndex={seatId}
                /> */}
              </ActiveOrFoldedContainer>
            </>
          ):(
            // Only show the "TakeSeatButton" if no user is seated anywhere on the table
            !isUserSeated && (
              // <TakeSeatButton scaleFactor={scaleFactor} onClick={() => handleTakeSeatClick(seat)}>
              <TakeSeatButton onClick={() => handleTakeSeatClick(seat)}>
                Take seat
              </TakeSeatButton>
            )
          )}
        </Seat>
      </motion.div>
      {renderCurrentBetContainer()}
    </>
  )
})

export default SeatAndPlayer;