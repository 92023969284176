// src/components/Pot/styles.js

import styled from 'styled-components';

export const PotContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  z-index: 50;
`;

export const Circle = styled.div`
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  background-color: ${({ theme }) => theme.textColor};
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  transform-origin: center;
  --scale-factor: ${({ scaleFactor }) => scaleFactor || 1};
`;

export const PotBold = styled.span`
  position: absolute;
  z-index: 2;
  color: rgb(255, 255, 255);
  transform: translate(-50%, -50%) scale(${({ scaleFactor }) => scaleFactor || 1});
  transform-origin: center;
  --scale-factor: ${({ scaleFactor }) => scaleFactor || 1};
  font-weight: 700;
`;

