// src/views/PlaygroundSandbox/components/GetAllowanceTo.tsx
import React, { useState } from 'react';
import Button from '@components/ButtonWithMargins';
import { ReadOnlyContractProps } from '@isc/types';
import OutlinedTextField from '@components/OutlinedTextField';
import { OperationContainer, FlexContainer, StyledHeading } from '../styles';
import { encodeFunctionData, decodeFunctionResult } from 'viem';
import { publicClient } from '@isc/client/clients';
import { ISCAssets, NativeToken, NFTID } from '@isc/types/ISCTypes';

const GetAllowanceToViem: React.FC<ReadOnlyContractProps> = ({ contract, account }) => {
  const [output, setOutput] = useState<ISCAssets | null>(null);
  const [toAddress, setToAddress] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleGetAllowanceTo = async () => {
    if (!contract || !account || !account.startsWith('0x')) {
      setError('Invalid account address. Please ensure it starts with 0x.');
      return;
    }
    
    try {
      setError(null); // Clear previous errors
      
      // Encode the function call data
      const data = encodeFunctionData({
        abi: contract.abi,
        functionName: 'getAllowanceTo',
        args: [toAddress],
      });
      
      // Use the `call` function to interact with the contract
      const rawResult = await publicClient.call({
        to: contract.address,
        data,
        account,
      });
      
      // Check if rawResult.data is defined
      if (!rawResult.data) {
        throw new Error("Call result data is undefined.");
      }
      
     // Decode the function result
      const decodedResult = decodeFunctionResult({
        abi: contract.abi,
        functionName: 'getAllowanceTo',
        data: rawResult.data,
      }) as ISCAssets;;
      
      //console.log('Decoded result:', decodedResult);
      
      // Ensure the structure is correct before accessing it
      if (typeof decodedResult !== 'object' || decodedResult === null) {
        throw new Error('Unexpected structure in decoded result');
      }
      
      const baseTokens = decodedResult.baseTokens as bigint;
      const decodedNativeTokens = decodedResult.nativeTokens as NativeToken[]; // Directly using NativeToken type
      const decodedNFTs = decodedResult.nfts as NFTID[];
      
      //console.log('Base Tokens:', baseTokens);
      //console.log('Native Tokens:', decodedNativeTokens);
      //console.log('NFTs:', decodedNFTs);
      
      // Map decoded native tokens to the NativeToken type
      const nativeTokens: NativeToken[] = decodedNativeTokens.map(token => ({
        ID: { data: token.ID.data }, // No need to re-define the structure
        amount: token.amount,
      }));
      
      // Use the NFTID type to structure NFTs correctly
      const nfts: NFTID[] = decodedNFTs;
      
      // Update the state
      const iscAssets: ISCAssets = {
        baseTokens,
        nativeTokens,
        nfts,
      };
      
      setOutput(iscAssets);
    } catch (error: any) {
      console.error(`Failed to call getAllowanceTo`, error);
      setError('Failed to call the contract. Please check the input addresses and ABI.');
    }
  };
  
  return (
    <OperationContainer>
      <StyledHeading>Get Allowance To</StyledHeading>
      <FlexContainer>
        <OutlinedTextField
          label="To Address (address)"
          value={toAddress}
          onChange={(e) => setToAddress(e.target.value)}
          supportingText="* Required (0xD5A5505A18d914166063E29f98C9a4594245a61b)"
          error={error}
        />
      </FlexContainer>
      <Button onClick={handleGetAllowanceTo}>Call Get Allowance To</Button>
      {output && (
        <div>
          <h3>Call Contract Output:</h3>
          <p><strong>Base Tokens:</strong> {output.baseTokens.toString()}</p>
          <p><strong>Native Tokens:</strong> {output.nativeTokens.length > 0
            ? output.nativeTokens.map(token => `ID: ${token.ID}, Amount: ${token.amount.toString()}`).join(', ')
            : 'None'}</p>
          <p><strong>NFTs:</strong> {output.nfts.length > 0 ? output.nfts.join(', ') : 'None'}</p>
        </div>
      )}
    </OperationContainer>
  );
};

export default GetAllowanceToViem;
